<script setup lang="ts">
import { destroyCheckLists, getCheckListRow, patchCheckListRows, postCheckListRows } from '@/services/api-check-lists';
import { CheckListResource, CheckListRowResource } from '@/types/check-list';
import { GroupResource } from '@/types/group';
import { usePage } from '@inertiajs/vue3';
import { computed, inject, ref } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import CheckListRow from '@/components/Models/CheckLists/CheckListRow.vue';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId, tooShortOrLong } from '@/util/globals';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import { getNow } from '@/util/timeFunctions';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import { eventTypesKey } from '@/provide/keys';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import VButton from '@/components/Inputs/VButton.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import SectionConnectedToEventTypeSubHeader from '@/components/Config/EventTypes/SectionConnectedToEventTypeSubHeader.vue';
import { getRoute, openRoute } from '@/util/route';
import { getGroupById } from '@/util/group-helpers';
import { formatQueryString } from '@/util/query-helpers';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';

type Props = {
  canEdit: boolean;
  isTemplate: boolean;
  isDisplay: boolean;
  group?: GroupResource;
  parentModel?: string;
  parentModelId?: number;
  currentGroupId?: number;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'edit': [];
  'deleted': void;
}>();

const toast = useToast();
const { assertCertain } = useCertaintyModal();
const { eventTypes, fetch: fetchEventTypes } = inject(eventTypesKey, {
  eventTypes: computed(() => []),
  fetch: (force?: boolean = false) => {},
});

fetchEventTypes();

const checkList = defineModel<CheckListResource>();

const markingAsDone = ref(false);
const open = ref(false);
const editMode = ref(props.isTemplate);

const updateRowOrder = async ({ item, order }: { item: CheckListRowResource; order: number }) => {
  await patchCheckListRows(item.id, { order });
  toast.success('Updated');
};

const removeRow = async (rowId: number) => {
  if (!checkList.value) return;

  const index = getIndexFromArrayBasedOnId(rowId, checkList.value.rows);
  if (index > -1) {
    checkList.value.rows.splice(index, 1);
  }
};

const fetchCheckListRow = async (id: number) => {
  if (!checkList.value) return;

  const { data } = await getCheckListRow(id);

  checkList.value.rows = exchangeValuesOfObject(data, checkList.value.rows);
};

const newRowTitle = ref('');
const loadingNewRow = ref(false);

const addRow = async () => {
  if (!checkList.value || loadingNewRow.value) return;

  if (tooShortOrLong(newRowTitle.value, 'Title')) return;

  loadingNewRow.value = true;

  let order = 100;
  if (checkList.value.rows.length > 0) {
    const item = checkList.value.rows[checkList.value.rows.length - 1];
    if (item.order) {
      order = item.order + 100;
    }
  }

  const { data } = await postCheckListRows(newRowTitle.value, checkList.value.id, order);

  newRowTitle.value = '';

  checkList.value.rows.push(data);

  toast.success('Added');

  loadingNewRow.value = false;
};

const markCheckListAsDone = async () => {
  if (props.isTemplate || !checkList.value) return;

  if (markingAsDone.value) return;

  const certain = await assertCertain(
    'Mark as done',
    'Are you sure that all inputs has been added to this check list? After locking it, no changes can be done.'
  );
  if (!certain) return;

  markingAsDone.value = true;

  await axios.patch(`/api/check-lists/${checkList.value.id}/mark-as-done`);

  markingAsDone.value = false;

  toast.success('Check List marked as done');

  checkList.value.locked = true;
  checkList.value.locked_date = getNow();
  checkList.value.locked_id = usePage().props.auth.user.id;
  checkList.value.lockedBy = usePage().props.auth.user.name;
};

const connectedGroups = computed(() => {
  if (!props.isTemplate || !checkList.value) return '';

  if (props.group && props.group.childGroups) {
    if (checkList.value.groups.length === 0) return 'N/A';
    return props.group.childGroups
      .filter((g) => checkList.value?.groups?.includes(g.id))
      .map((group) => group.name)
      .join(', ');
  }

  return '';
});

const unmarkAsDone = async () => {
  if (!checkList.value) return;

  const certain = await assertCertain(
    'Unmark as done',
    'Are you sure that you want to unmark this check list as done?'
  );

  if (!certain) return;

  downloading.value = true;
  await axios.patch(`/api/check-lists/${checkList.value.id}`, {
    title: checkList.value.title,
    description: checkList.value.description,
    start: checkList.value.start,
    locked: false,
  });
  toast.success('Check List Opened');

  checkList.value.locked = false;
  checkList.value.locked_date = null;
  checkList.value.locked_id = null;
  checkList.value.lockedBy = null;

  downloading.value = false;
};

const downloading = ref(false);

const download = async () => {
  if (downloading.value || !checkList.value) return;

  downloading.value = true;

  try {
    const response = await axios.post('/api/check-lists/event-report', {
      downloadableCheckLists: [checkList.value.id],
    });
    if (response.status === 204) {
      toast.warning('There are no data in the requested check list.');
      downloading.value = false;
      return;
    }
    if (response.status === 205) {
      toast.warning('Can only download check lists for events.');
      downloading.value = false;
      return;
    }
    await downloadFile(response.data.url, response.data.name);
    downloading.value = false;
  } catch (e) {
    downloading.value = false;
    toast.warning('Something went wrong, please try again later');
    throw e;
  }
};

const actions = computed(() => {
  const array = [];

  if (!props.isTemplate && checkList?.value?.rows?.length && !checkList?.value?.locked && !props.isDisplay) {
    array.push({
      title: 'Mark Complete',
      icon: 'fa-check-square-o',
      type: 'primary',
      action: () => {
        markCheckListAsDone();
      },
    });
  }

  if (!props.isTemplate && checkList.value) {
    const items = [];
    if (!checkList.value.locked && props.canEdit) {
      items.push({
        title: 'Edit Check List',
        preIcon: 'fa-pencil fa-regular',
        action: (close) => {
          emit('edit');
          close();
        },
      });
    }
    items.push({
      title: 'Download',
      preIcon: 'fa-download fa-regular',
      action: (close) => {
        download();
        close();
      },
    });

    if (checkList.value && checkList.value.locked && props.canEdit) {
      items.push({
        title: 'Un-lock Check List',
        preIcon: 'fa-lock-open fa-regular',
        action: (close) => {
          unmarkAsDone();
          close();
        },
      });
    }

    if (props.parentModel === 'Group' && props.parentModelId) {
      items.push({
        title: 'Edit Template',
        preIcon: 'fa-cog fa-regular',
        action: async () => {
          const group = await getGroupById(props.parentModelId);
          if (checkList.value && checkList.value.parent_id) {
            openRoute(
              getRoute('groups.administrator', group.slug) +
                '?open=' +
                formatQueryString('CheckList', checkList.value.parent_id) +
                '#check-lists'
            );
          } else {
            openRoute(getRoute('groups.administrator', group.slug) + '#check-lists');
          }
        },
      });
    }
    if (props.canEdit) {
      items.push({
        type: 'divider',
      });
      items.push({
        title: 'Delete',
        preIcon: 'fa-trash fa-regular',
        type: 'warning',
        action: async (close) => {
          close();
          if (!checkList.value.id) return;
          const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
            'Delete Check List',
            `Are you sure that you want to delete ${checkList.value.title}?`
          );
          if (!deleteIt) return;
          await destroyCheckLists(checkList.value.id);
          toast.success('Check List Deleted');
          emit('deleted');
        },
      });
    }
    if (props.isTemplate || (props.canEdit && !checkList.value.locked)) {
      array.push({
        title: editMode.value ? 'Done' : 'Edit',
        icon: editMode.value ? 'fa-circle-check fa-regular' : 'fa-pen fa-regular',
        type: props.isTemplate ? 'primary' : null,
        action: () => {
          editMode.value = !editMode.value;
        },
        loading: downloading.value,
        buttonDropdown: items,
      });
    } else {
      array.push({
        icon: 'fa-chevron-down',
        loading: downloading.value,
        dropdown: items,
      });
    }
  }
  if (props.isTemplate) {
    array.push({
      title: 'Edit',
      icon: 'fa-pen fa-regular',
      action: () => {
        emit('edit');
      },
      buttonDropdown: [
        {
          title: 'Delete',
          preIcon: 'fa-trash fa-regular',
          action: async (close) => {
            close();
            if (!checkList.value.id) return;
            const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
              'Delete Check List',
              `Are you sure that you want to delete ${checkList.value.title}?`
            );
            if (!deleteIt) return;
            await destroyCheckLists(checkList.value.id);
            toast.success(' Deleted');
            emit('deleted');
          },
        },
      ],
    });
    // array.push({
    //   title: editMode.value ? 'Done' : 'Edit',
    //   icon: editMode.value ? 'fa-circle-check fa-regular' : 'fa-pen fa-regular',
    //   action: () => {
    //     editMode.value = !editMode.value;
    //   },
    // });
  }

  return array;
});

const connectetedEventTypes = computed(() => {
  if (!checkList.value) return [];

  return eventTypes.value.filter((ev) => ev.pivot.check_lists.some((cl) => cl.id === checkList.value?.id));
});

const concatAllEvenTypeNames = computed(() => {
  if (!connectetedEventTypes.value) return '';

  return connectetedEventTypes.value.map((ev) => ev.name).join(', ');
});
</script>

<template>
  <ContentContainer
    :key="currentGroupId"
    v-if="checkList"
    :title="checkList.title"
    :just-content-without-header="isDisplay"
    :actions="actions"
    pre-icon="fa-list-check"
    :with-second-border="false"
    :with-border="false"
    :extra-height="isTemplate || isDisplay ? 0 : 70"
    @open="open = $event">
    <template #title>
      <div v-if="checkList">
        <div class="flex items-center gap-edge-1/2">
          <h3>
            {{ checkList.title }}
          </h3>
          <i
            v-if="checkList.locked"
            class="fa fa-fw fa-check-square-o"
            :title="
              'Check list has been marked as done by ' + checkList.lockedBy + ' at ' + checkList.locked_date + '.'
            " />
        </div>

        <DisplayBadge
          v-if="!open && !checkList.locked && !isTemplate"
          color="pending"
          class="w-[80px]"
          text="Pending" />

        <div class="flex gap-[64px]">
          <div
            v-if="checkList.start"
            class="!sub-title">
            {{ checkList.start }}
          </div>
          <div v-if="!isTemplate">
            <div
              v-if="checkList.locked && checkList.locked_date && checkList.lockedBy"
              class="!sub-title text-soft">
              Done by {{ checkList?.lockedBy }} at {{ checkList.locked_date }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #underHeader>
      <div
        v-if="open"
        class="flex flex-col gap-edge-1/2"
        :class="open ? 'pl-[42px]' : ''">
        <div
          v-if="checkList?.description"
          class="text-soft">
          {{ checkList.description }}
        </div>

        <div
          v-if="isTemplate && group && group.childGroups.length > 0"
          class="flex items-center gap-edge-1/4">
          <h5 class="text-soft">Shared with:</h5>
          <span class="text-sm">
            {{ connectedGroups }}
          </span>
        </div>

        <div
          v-if="isTemplate && group"
          class="-ml-[45px] -mt-edge-1/2">
          <SectionConnectedToEventTypeSubHeader
            model="Group"
            :model-id="group.id"
            pre-icon="fa-tag"
            :title-of-item="checkList.title"
            :id-of-item="checkList.id"
            type-of-item="CheckList" />
        </div>
      </div>
    </template>
    <template #content>
      <VTable
        v-if="checkList"
        v-model="checkList.rows"
        edge-to-edge
        bordered-table
        :softer-background-header="true"
        sticky-header
        :can-drag="editMode"
        row-size="medium"
        @new-order="updateRowOrder">
        <template #head>
          <VTableRow head>
            <VTableCell
              v-if="(isTemplate && canEdit) || (editMode && canEdit && checkList && !checkList.locked)"
              style="width: 50px" />
            <VTableCell
              v-if="!isTemplate && !editMode"
              style="width: 50px">
              Done
            </VTableCell>
            <VTableCell> Title</VTableCell>
            <template v-if="!isTemplate && !editMode">
              <VTableCell> Notes</VTableCell>
              <VTableCell style="width: 35px"> User</VTableCell>
            </template>
            <VTableCell v-if="(isTemplate && editMode) || (canEdit && !checkList.locked && editMode)" />
          </VTableRow>
        </template>

        <template #row="{ item }">
          <CheckListRow
            :row="item"
            :is-template="isTemplate"
            :locked="checkList?.locked || isDisplay"
            :can-edit="canEdit"
            :edit-mode="editMode"
            @deleted="removeRow(item.id)"
            @updated="fetchCheckListRow(item.id)" />
        </template>

        <template #footer>
          <VTableRow
            v-if="canEdit && !checkList?.locked && editMode"
            class="check-list-new-row dark-input-field">
            <VTableCell />
            <VTableCell
              has-input
              :colspan="2">
              <TextInput
                v-model="newRowTitle"
                placeholder="Title of new Row"
                :disabled="loadingNewRow"
                set-focus
                @keyup.enter="addRow">
                <template #end>
                  <VButton
                    title="Create"
                    :disabled="!newRowTitle || loadingNewRow"
                    icon="fa-save"
                    size="xs"
                    tool-tip-text="Save"
                    type="primary"
                    emphasized
                    class="mr-edge"
                    @click="addRow" />
                </template>
              </TextInput>
            </VTableCell>
          </VTableRow>
        </template>
      </VTable>

      <EmptyStateFullPage
        v-if="checkList && checkList.rows.length === 0 && canEdit && !checkList?.locked && !editMode"
        icon="fa-table fa-regular"
        description="No check list items created yet."
        button-text="Add item"
        :button-function="
          () => {
            editMode = true;
          }
        " />
    </template>
  </ContentContainer>
</template>
