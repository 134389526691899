import { DocumentModelType, DocumentResource } from '@/types/document';
import uploadcare from 'uploadcare-widget';
import { addDocumentToFolder, storeDocument } from '@/services/api-documents';
import { useToast } from 'vue-toastification';
import { UploadcareResponse } from '@/types/uploadcare';

const maxFileSize = (size: number) => (fileInfo: { size: number }) => {
  if (fileInfo.size !== null && fileInfo.size > size) {
    useToast().warning('File is too big (max is ' + size / (1024 * 1024) + 'MB). ');
    throw new Error('File max size');
  }
};

type UploadOptions = {
  modelType?: DocumentModelType | null;
  modelId?: number | null;
  maxUploadSize?: number;
  folderId?: number | null;
  additionalSettings?: any;
  multiple?: boolean;
};

export const openUploadWidget = async ({
  maxUploadSize = 100,
  additionalSettings = null,
  multiple = false,
}): Promise<UploadcareResponse[] | UploadcareResponse> => {
  let settings = {
    validators: [maxFileSize(maxUploadSize * 1024 * 1024)],
  };
  if (additionalSettings) {
    settings = {
      ...settings,
      ...additionalSettings,
    };
  }
  const dialog = await uploadcare.openDialog(null, { multiple: multiple, ...settings }, settings);

  if (multiple) {
    const files = await dialog.files();

    const promises = files.map((file) => {
      return file.promise();
    });

    return Promise.all(promises);
  }

  return dialog;
};

export const storeFiles = async (
  data: UploadcareResponse | UploadcareResponse[],
  modelType: DocumentModelType,
  modelId: number,
  multiple = false
): Promise<DocumentResource | DocumentResource[]> => {
  if (multiple && Array.isArray(data)) {
    const uploadedFiles: Promise<DocumentResource[]>[] = [];

    data.map((f) => {
      const prom = storeDocument(modelType, modelId, f.uuid);
      uploadedFiles.push(prom);
    });

    const test = await Promise.all(uploadedFiles);

    return test.map((t) => t.data);
  } else {
    const { data: file }: { data: DocumentResource } = await storeDocument(modelType, modelId, data.uuid);

    return file;
  }
};

export const uploadFile = async ({
  modelType,
  modelId,
  maxUploadSize = 100,
  folderId,
  additionalSettings = null,
  multiple = false,
}: UploadOptions): Promise<UploadcareResponse | UploadcareResponse[] | DocumentResource | DocumentResource[]> => {
  const uploadCareFiles = await openUploadWidget({ maxUploadSize, multiple, additionalSettings });

  if (!modelType || !modelId) return uploadCareFiles;

  const storedFiles = await storeFiles(uploadCareFiles, modelType, modelId, multiple);

  if (folderId) {
    if (storedFiles && storedFiles.length > 0 && Array.isArray(storedFiles)) {
      const folderPromises: Promise<unknown> = [];
      storedFiles.map((t) => {
        const folder = addDocumentToFolder(modelType, modelId, folderId, t.id);
        folderPromises.push(folder);
      });
      await Promise.all(folderPromises);
    } else if (typeof storedFiles === 'object') {
      await addDocumentToFolder(modelType, modelId, folderId, storedFiles.id);
    }
  }

  return storedFiles;
};

type UploadDropOptions = {
  el: HTMLElement;
  callback: (file: any, folder?: any) => void;
  loading: () => void;
  dropText?: string;
  modelType: string;
  modelId: number;
  folderId?: number;
  maxUploadSize?: number;
};

export const addDropArea = async ({
  el,
  callback,
  loading,
  maxUploadSize = 100,
  modelType,
  modelId,
  folderId,
}: UploadDropOptions) => {
  uploadcare.dragdrop.uploadDrop(
    el,
    async (file: any) => {
      loading();
      const fileInfo = await file;
      const { data: newFile } = await storeDocument(modelType, modelId, fileInfo.uuid);
      if (!folderId) {
        return callback(fileInfo);
      }
      const { data: folder } = await addDocumentToFolder(modelType, modelId, folderId, newFile.id);
      return callback(newFile, folder);
    },
    {
      validators: [maxFileSize(maxUploadSize * 1024 * 1024)],
    }
  );
};
