import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import type { GroupResourceComposable } from '@/provide/keys';
import { groupResourceKey } from '@/provide/keys';
import { useGroupsStore } from '@/store/GroupsStore';

export const useGroup = (groupId: number, shouldProvide = true, canLoad = true): GroupResourceComposable => {
  const { list, loading } = storeToRefs(useGroupsStore());
  const { fetchGroup } = useGroupsStore();

  const group = computed(() => list.value.get(groupId) || null);

  const fetch = async (force = false) => {
    if (!canLoad) return;
    if (force || !list.value.has(groupId)) {
      await fetchGroup(groupId);
    }
  };

  if (shouldProvide) {
    provide(groupResourceKey, {
      group,
      fetch,
      loading: readonly(loading),
    });
  }

  return {
    group,
    loading,
    fetch,
  };
};
