import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { TimelineTemplateModelType, TimelineTemplateResource } from '@/types/timeline-template';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';
import { formatModel } from '@/util/store-helpers';

export const timelineStore = defineStore('timeline-store', () => {
  const list = ref<Map<string, TimelineTemplateResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: TimelineTemplateModelType, modelId: number) => {
    Echo.join(`On.${model}.${modelId}`)
      .listen(`.timelineTemplate.created`, async () => {
        await fetchTimelineTemplates(model, modelId, true);
      })
      .listen(`.timelineTemplate.updated`, async () => {
        await fetchTimelineTemplates(model, modelId, true);
      })
      .listen(`.timelineTemplate.deleted`, async () => {
        await fetchTimelineTemplates(model, modelId, true);
      });
  };

  const fetchTimelineTemplates = async (model: TimelineTemplateModelType, modelId: number, force: boolean = false) => {
    if (!force && list.value.has(formatModel(model, modelId))) return list.value.get(formatModel(model, modelId));

    loading.value = true;

    const { data } = await axios.get('/api/timeline-templates', {
      params: {
        model_type: 'App\\' + model,
        model_id: modelId,
      },
    });

    if (!list.value.has(formatModel(model, modelId))) {
      listenForBroadcast(model, modelId);
    }

    list.value.set(formatModel(model, modelId), data);

    loading.value = false;

    return data;
  };

  const addOrUpdateTimelineTemplate = async (
    model: TimelineTemplateModelType,
    modelId: number,
    timelineTemplate: TimelineTemplateResource
  ) => {
    if (!list.value.has(formatModel(model, modelId))) {
      await fetchTimelineTemplates(model, modelId, true);
      return;
    }
    let data = [...list.value.get(formatModel(model, modelId))];
    data = exchangeValuesOfObject(timelineTemplate, data);
    list.value.set(formatModel(model, modelId), data);
  };

  const removeTimelineTemplate = async (
    model: TimelineTemplateModelType,
    modelId: number,
    timelineTemplateId: number
  ) => {
    if (!list.value.has(formatModel(model, modelId))) {
      await fetchTimelineTemplates(model, modelId, true);
      return;
    }
    const data = list.value.get(formatModel(model, modelId));
    const index = getIndexFromArrayBasedOnId(timelineTemplateId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(formatModel(model, modelId), data);
    } else {
      await fetchTimelineTemplates(model, modelId, true);
    }
  };

  return {
    list,
    loading,
    fetchTimelineTemplates,
    addOrUpdateTimelineTemplate,
    removeTimelineTemplate,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(timelineStore, import.meta.hot));
}
