<script setup lang="ts">
import { useLocalStorage } from '@vueuse/core';
import moment from 'moment';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import ReportModal from '@/components/Modals/ReportModal.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { dateFormat } from '@/variables/date-format';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import { getMetaDataForGroup } from '@/services/api-group';
import { getItemFromArrayBasedOnId } from '@/util/globals';
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';

type Props = {
  isVenue: boolean;
  groupId: number;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'downloading', arg: boolean): void;
}>();

const toast = useToast();

const csv = useLocalStorage('meta_data_report_csv', true);
const with_cancelled = useLocalStorage('meta_data_report_with_cancelled', false);
const with_rooms = useLocalStorage('meta_data_report_with_rooms', false);
const start = ref(moment().startOf('month').format(dateFormat));
const end = ref(moment().endOf('month').format(dateFormat));
const meta_data_id = ref(null);
const meta_table_field_id = ref(null);
const working = ref(false);
const includeMetaDataFields = useLocalStorage('meta_data_report_include_fields', false);
// const includedMetaDataFieldIds = useLocalStorage<number[]>('meta_data_report_selected_field_ids', []);
const includedMetaDataFieldIds = ref([]);
const metaData = ref([]);
const fetchedMetaData = async () => {
  if (props.groupId === null) return;
  const { data } = await getMetaDataForGroup(props.groupId);
  metaData.value = data;
};
fetchedMetaData();

watch(meta_data_id, () => {
  includeMetaDataFields.value = false;
  includedMetaDataFieldIds.value = [];
});

watch(includeMetaDataFields, () => {
  includedMetaDataFieldIds.value = [];
});
watch(working, () => {
  emit('downloading', working.value);
});

const canSave = computed(() => {
  const hasValidDates = moment(start.value).isValid() && moment(end.value).isValid();
  const startEarlier = moment(end.value) > moment(start.value);
  const metaDataId = meta_data_id.value !== null;
  return [hasValidDates, startEarlier, metaDataId].every((v) => v);
});

const downloadReport = async () => {
  if (!canSave.value) {
    return;
  }
  if (working.value) {
    return;
  }
  if (meta_table_field_id.value) {
    working.value = true;
    const response = await axios
      .post(`/api/meta-data-fields/${meta_table_field_id.value}/report`, {
        csv: csv.value,
        start: start.value,
        end: end.value,
        with_cancelled: with_cancelled.value,
        with_rooms: with_rooms.value,
        meta_data_field_ids: includedMetaDataFieldIds.value,
      })
      .catch((error) => {
        console.error(error);
        working.value = false;
        toast.warning('Something went wrong, please try again later');
      });
    if (response.status === 204) {
      toast.warning('There are no result for the requested period');
      working.value = false;
      return;
    }
    await downloadFile(response.data.url, response.data.name);
    working.value = false;

    return;
  }
  if (!meta_data_id.value) {
    return;
  }
  working.value = true;
  const response = await axios
    .post(`/api/meta-data/${meta_data_id.value}/report`, {
      csv: csv.value,
      start: start.value,
      end: end.value,
      with_cancelled: with_cancelled.value,
      with_rooms: with_rooms.value,
      meta_data_field_ids: includeMetaDataFields ? includedMetaDataFieldIds.value : null,
    })
    .catch((error) => {
      console.error(error);
      working.value = false;
      toast.warning('Something went wrong, please try again later');
    });
  if (response.status === 204) {
    toast.warning('There are no result for the requested period');
    working.value = false;
    return;
  }
  await downloadFile(response.data.url, response.data.name);
  working.value = false;
};

const metaDataWithTableFields = computed(() => {
  return metaData.value
    .map((m) => {
      const tableFields = m.fields.filter((f) => f.component === 'field-table');
      if (tableFields.length > 0) {
        return {
          id: m.id,
          label: m.title,
          options: tableFields,
        };
      }
      return null;
    })
    .filter((m) => m !== null);
});
const getMetaDataIdForTableField = () => {
  meta_data_id.value = null;
  if (!meta_table_field_id.value) return;
  const filteredMetaData = metaData.value.filter((m) => {
    return m.fields.filter((f) => f.id === meta_table_field_id.value).length > 0;
  });
  if (filteredMetaData.length === 1) {
    meta_data_id.value = filteredMetaData[0].id;
  }
};

const activeTab = ref('metaData');
</script>

<template>
  <ReportModal
    v-model:start="start"
    v-model:end="end"
    v-model:as-c-s-v="csv"
    :with-button="false"
    :can-download="canSave"
    title="Download Meta Data Report"
    :working="working"
    @update:as-c-s-v="activeTab = 'metaData'"
    @download="downloadReport">
    <template
      v-if="metaDataWithTableFields.length > 0"
      #overContent>
      <div class="col-span-2">
        <ModeSelector
          :model-value="csv && metaDataWithTableFields.length > 0 ? activeTab : 'metaData'"
          class="col-span-2 -mx-edge px-edge"
          :modes="[
            { value: 'metaData', name: 'Meta Data' },
            { value: 'metaDataFieldTable', name: 'Table Field', disabled: !csv },
          ]"
          @update:model-value="[(activeTab = $event), (meta_table_field_id = null), (meta_data_id = null)]" />
      </div>
    </template>

    <SettingToggle
      v-model="with_cancelled"
      label="With Cancelled Events"
      title="If enabled, the report will include cancelled events." />

    <SettingToggle
      v-if="isVenue"
      v-model="with_rooms"
      label="With Rooms"
      title="If enabled, rooms added to the events will be included in the report." />

    <template v-if="activeTab === 'metaDataFieldTable' && csv && metaDataWithTableFields.length > 0">
      <VSelect
        v-model="meta_table_field_id"
        wrapper-class="col-start-1"
        label="Meta Data Table Field"
        groups
        nullable
        nullable-display-text="N/A"
        :required="true"
        :options="metaDataWithTableFields"
        option-value="title"
        @update:model-value="getMetaDataIdForTableField()" />

      <VMultiselect
        v-model="includedMetaDataFieldIds"
        :disabled="!meta_table_field_id || !meta_data_id"
        :close-on-select="false"
        option-label="title"
        :options="
          getItemFromArrayBasedOnId(meta_data_id, metaData, { fields: [] }).fields.map((field) => {
            return {
              title: field.title,
              id: field.id,
              disabled: field.component === 'field-table' || meta_table_field_id === field.id,
            };
          })
        "
        label="Select Other Fields" />
    </template>
    <template v-else>
      <VSelect
        v-model="meta_data_id"
        wrapper-class="col-start-1"
        label="Meta Data"
        nullable
        nullable-display-text="N/A"
        :required="true"
        :options="metaData"
        option-value="title" />

      <SettingToggle
        v-model="includeMetaDataFields"
        :disabled="!meta_data_id"
        class="col-span-2"
        label="Filter Fields to include"
        title="If enabled, you can filter which fields to be included in the download." />

      <VMultiselect
        v-model="includedMetaDataFieldIds"
        :disabled="!includeMetaDataFields || !meta_data_id"
        :close-on-select="false"
        wrapper-class="col-span-2"
        placeholder="If no fields selected, all will be included"
        title="If no fields selected, all will be included"
        option-label="title"
        :options="
          getItemFromArrayBasedOnId(meta_data_id, metaData, { fields: [] }).fields.map((field) => {
            return {
              title: field.title,
              id: field.id,
              disabled: field.component === 'field-table' && csv,
            };
          })
        "
        label="Select Fields" />
    </template>
  </ReportModal>
</template>
