import { useToast } from 'vue-toastification';
import { v4 as uuidv4 } from 'uuid';
import { formatStampAsDate } from '@/util/timeFunctions';
import { dateFormat } from '@/variables/date-format';
import { nextTick } from 'vue';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { router, usePage } from '@inertiajs/vue3';
import { copyObject } from '@/util/object-helpers';
import moment from 'moment/moment';

export const createUuId = (prefix = '') => `${prefix ? prefix + '_' : ''}${uuidv4()}`;

export const getIndexFromArrayBasedOnId = <T>(id: number | null | string, array: T[], indexBasedOn = 'id') => {
  if (id && array && array.length > 0) {
    return array.findIndex((i: T) => {
      return getKey(i, indexBasedOn) === id;
    });
  }
  return -1;
};

export const inArray = <T>(items: T[], item: T) => {
  if (item && items?.length > 0) {
    return items.indexOf(item) > -1;
  }
  return false;
};

export const arrayToJoinString = (arr: string[] = [], splitWord = ' and ', joinBy = ', ') => {
  let a = copyObject(arr);
  switch (typeof a) {
    case 'string': {
      a = JSON.parse(a);
      break;
    }
    default: {
      break;
    }
  }
  if (a.length === 0) {
    return '';
  }
  if (a.length === 1) {
    return a[0];
  }
  let output = a.splice(0, a.length - 1).join(joinBy);
  output += `${splitWord}${a[0]}`;
  return output;
};

export const getItemFromArrayBasedOnId = <T>(
  id: number | null | string,
  items: T[],
  defaultReturn: T | null = null,
  indexBasedOn = 'id'
): T | null => {
  const index = getIndexFromArrayBasedOnId(id, items, indexBasedOn);
  if (index > -1) {
    return items[index];
  }
  return defaultReturn;
};

export const sortArrayBy = (arr, key, asc = true) => {
  return Array.isArray(arr)
    ? arr.sort((a, b) => {
        const textA = a[key] instanceof String || typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const textB = b[key] instanceof String || typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
        if (a[key] === null || !textA) return asc ? -1 : 1;
        if (b[key] === null || !textB) return asc ? 1 : -1;
        if (textA < textB) {
          return asc ? -1 : 1;
        }
        if (textA > textB) {
          return asc ? 1 : -1;
        }
        return 1;
      })
    : arr;
};

export const sortArrayByTime = (
  arr: Array,
  startKey: string = 'start',
  endKey: string | null = 'end',
  asc: boolean = true
) => {
  function comparatorStart(a, b) {
    if (a[startKey] < b[startKey]) return asc ? -1 : 1;
    if (a[startKey] > b[startKey]) return asc ? 1 : -1;
    return 0;
  }

  function comparatorEnd(a, b) {
    if (!endKey) return 0;
    if (a[endKey] < b[endKey]) return asc ? -1 : 1;
    if (a[endKey] > b[endKey]) return asc ? 1 : -1;
    return 0;
  }

  return arr.sort(comparatorEnd).sort(comparatorStart);
};

export const getBadgeClasses = (acceptedAt, declinedAt, text = false) => {
  if (acceptedAt) {
    return text ? 'Accepted' : 'badge-success bg-turquoise';
  }
  if (declinedAt) {
    return text ? 'Declined' : 'badge-danger bg-primary';
  }
  return text ? 'Pending' : 'badge-warning bg-pending';
};

export const withThousandSeparator = (value, defaultReturn = '') => {
  if (!value) return defaultReturn;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const toast = useToast();

export const tooShortOrLong = (value, varName = 'value', minLength = 2, maxLength: number | null = 160) => {
  const min = !minLength ? 2 : minLength;
  const max = !maxLength ? 160 : maxLength;

  if (!value || value.length < min) {
    toast.warning(`The ${varName} must be at least ${minLength} characters long`);
    return true;
  }
  if (max && value.length > max) {
    toast.warning(`The ${varName} must be less than ${maxLength} characters long.`);
    return true;
  }
  return false;
};

export const exchangeValuesOfObject = <T>(
  item: T,
  items: T[],
  excludeParams = ['id'],
  parameter = 'id',
  addIfDoesntExist = true,
  shiftNotPush = false
) => {
  const results = [...items];
  const index = getIndexFromArrayBasedOnId(item[parameter], results);
  if (index > -1) {
    Object.keys(item).forEach((key) => {
      if (!excludeParams.includes(key)) {
        results[index][key] = item[key];
      }
    });
  } else if (addIfDoesntExist) {
    if (shiftNotPush) {
      results.unshift(item);
    } else {
      results.push(item);
    }
  }
  return results;
};

export const updateItem = <T>(newValue: T, oldValue: T, excludeParams = ['id']) => {
  const mergedObject = { ...oldValue };
  Object.keys(newValue).forEach((key) => {
    if (!excludeParams.includes(key)) {
      mergedObject[key] = newValue[key];
    }
  });
  return mergedObject;
};

export const orderBy = (items: Array<any>, sortBy?: string | null, asc?: boolean = true) => {
  return items.sort((a, b) => {
    const first = asc ? a : b;
    const second = asc ? b : a;

    if (sortBy === 'order') {
      return first.order - second.order;
    }
    return (
      moment(sortBy !== null ? first[sortBy] : first).valueOf() -
      moment(sortBy !== null ? second[sortBy] : second).valueOf()
    );
  });
};

export const groupBy = (items: Array<any>, groupByKey: string | any) => {
  return items.reduce((acc, item: any) => {
    switch (typeof groupByKey) {
      case 'function': {
        const key = groupByKey(item);
        (acc[key] ||= []).push(item);
        break;
      }
      default: {
        const key = item[groupByKey];
        (acc[key] ||= []).push(item);
      }
    }
    return acc;
  }, {});
};

export const removeItemFromArrayBasedOnId = (idOfItem: number, items: Array<any>, indexBasedOn = 'id') => {
  const results = items;
  const index = getIndexFromArrayBasedOnId(idOfItem, results, indexBasedOn);
  if (index > -1) {
    results.splice(index, 1);
  }
  return results;
};

export const numberWithCommas = (number: number) => {
  if (number) {
    return (Math.round(number * 100) / 100)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return String(number);
};

export const formatCurrency = (amount: number, currency: string, locale = 'NO') => {
  return amount.toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
};

export const formatCurrencyInCents = (amount: number, currency: string) => formatCurrency(amount / 100, currency);

export const arrayMove = (arr: unknown[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const getMemberImage = (member = null) => {
  if (member) {
    if (member.hasOwnProperty('avatar_url')) {
      if (member.avatar_url) {
        return member.avatar_url;
      }
    }
  }
  return `${usePage().props.asset_url}assets/images/default-avatar.png`;
};

export const getKey = <T>(item: T | null = null, key: string | null = null, defaultReturn: any = null) => {
  if (!item || !key) return defaultReturn;
  if (item.hasOwnProperty(key)) {
    return item[key];
  }
  return defaultReturn;
};

export const slotEmpty = (slots = null, slotName = null) => {
  if (!slots || !slotName) return true;
  try {
    const slot = slots[slotName]();
    const { children } = slot[0];
    if (!children) return true;
    return children.length === 0;
  } catch (_) {}
  return true;
};

export const removeItemFromItems = (items = null, itemKey = null, key = 'id') => {
  if (!items || !itemKey) return;
  const index = getIndexFromArrayBasedOnId(itemKey, items, key);
  if (index === -1) return;
  items.splice(index, 1);
};

export const truncateText = (text = null, charCount = 100, truncater = '...') => {
  if (!text) return;
  return text.length > charCount ? text.slice(0, charCount) + truncater : text;
};

export const isNumeric = (n, allowNegative = true) => {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(parseFloat(n)) && (allowNegative ? parseFloat(n) >= 0 : true);
};

export const uniqueValuesFromArray = (array, key = 'id') => {
  const onlyUnique = (value, index, self) => {
    if (!value) return false;
    return self.indexOf(value) === index;
  };
  if (key === null) return array.filter(onlyUnique);
  return array.map((a) => a[key]).filter(onlyUnique);
};

export const uniqueObjectsFromArray = (array, key = 'id') => {
  const onlyUnique = (value, index, self) => {
    if (!value) return false;
    return getIndexFromArrayBasedOnId(value[key], self, key) === index;
  };
  return array.filter(onlyUnique);
};

export const groupItemsByKey = (array = null, key = 'id') => {
  const result = [];
  if (array) {
    array?.forEach((item) => {
      const index = result.findIndex((r) => r[key] === item[key]);
      if (index > -1) {
        result[index].items.push(item);
      } else {
        result.push({
          [key]: item[key],
          items: [item],
        });
      }
    });
  }

  return result;
};

export const groupItemsByDate = <T>(array: T[] | null = null, key = 'created_at', formatToCompare = dateFormat) => {
  const result: {
    [key: string]: string;
    items: T[];
  }[] = [];

  if (array) {
    array?.forEach((item) => {
      const index = result.findIndex((r) => r[key] === formatStampAsDate(item[key], formatToCompare));
      if (index > -1) {
        result[index].items.push(item);
      } else {
        result.push({
          [key]: formatStampAsDate(item[key], formatToCompare),
          items: [item],
        });
      }
    });
  }

  return result;
};

export const focusOnField = (field = null, actionToTake = 'focus', increments = [0, 10, 50, 100, 200, 250, 500]) => {
  if (!field) return;
  increments.map((num) => {
    setTimeout(() => {
      const focusElement = document.activeElement;
      if (field && focusElement !== field) {
        switch (actionToTake) {
          case 'focus': {
            field.focus();
            break;
          }
          case 'openMenu': {
            field.openMenu();
            break;
          }
          case 'click': {
            field.click();
            break;
          }
          default: {
            break;
          }
        }
      }
    }, num);
  });
};

export const activateFieldById = (id = null, selector = 'input', focusAllText = false) => {
  const element = document.getElementById(id);
  if (!element) return;
  const firstInput = element.querySelector(selector);
  if (firstInput) {
    if (focusAllText) {
      firstInput.select();
    } else {
      firstInput.focus();
    }
  }
};

export const roundOfNumberWithDecimals = (number: number, decimals = 2) => {
  if (number === null) {
    return null;
  }
  return Math.round(number * 10 ** decimals) / 10 ** decimals;
};

export const separateThousands = (value) => {
  if (value) {
    let decimals = '00';
    let isNegative = false;
    let valueAsString = value.toString();
    let wholeNumber = null;

    // Remove minus if negative number
    if (Math.sign(value) === -1) {
      isNegative = true;
      valueAsString = valueAsString.substr(1);
    }

    // Seperate main number and decimals
    if (valueAsString.indexOf('.') !== -1) {
      wholeNumber = valueAsString.split('.')[0];
      decimals = valueAsString.split('.')[1];
    } else {
      wholeNumber = valueAsString;
    }

    // Seperate thousands
    const remainder = wholeNumber.length % 3;
    const returnValue = (
      wholeNumber.substr(0, remainder) + wholeNumber.substr(remainder).replace(/(\d{3})/g, ' $1')
    ).trim();

    if (decimals.length > 2) {
      decimals = decimals.substr(0, 2);
    }

    // Return number with decimals
    return `${(isNegative ? '-' : '') + returnValue}.${decimals.length === 1 ? `${decimals}0` : decimals}`;
  }
  return 0.0;
};

export const getUrlVars = () => {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
};
export const getUrlVar = (itemKey: string) => {
  return getKey(getUrlVars(), itemKey);
};

export const removeKeyFromUrl = (itemKey: string) => {
  if (location.href.includes('?')) {
    const uri = location.href.split('?')[0];
    const string = location.href.split('?')[1].split('&');
    const index = string.findIndex((s) => s.includes(itemKey));
    if (index > -1) {
      string.splice(index, 1);
    }
    if (string.length) {
      router.replace(`${uri}?${string.join('&')}`);
    } else {
      router.replace(uri);
    }
  }
};

export const infoToast = (text: string | null) => {
  if (!text) return;
  useToast().info(text);
};

export const formatMinutesAsHours = (minutes: number | null, decimals = 1) => {
  if (!minutes) return 0 + 'h';
  const hours = minutes / 60;
  if (hours % 1 === 0) return hours + 'h';
  return Math.abs(hours).toFixed(decimals);
};

export const blurActiveElement = () => {
  const activeElement = document.activeElement;
  if (activeElement) {
    activeElement.blur();
  }
};

export const scrollIntoViewById = (id: string) => {
  nextTick(() => {
    const elem = document.getElementById(id);
    if (elem != null) {
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  });
};

export const reorderArrayByIds = (arrayOfObjects, arrayOfIds) => {
  return arrayOfIds
    .map((id) => getItemFromArrayBasedOnId(isNumeric(id) ? Number(id) : id, arrayOfObjects))
    .filter((item) => item !== null);
};

export const convertRemToPixels = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const getCssVariable = (propertyName: string): string | null => {
  const r = document.querySelector(':root');
  if (!r) return null;
  const rs = getComputedStyle(r);
  return rs.getPropertyValue(propertyName);
};
export const setCssVariable = (propertyName: string, value: string) => {
  const r = document.querySelector(':root');
  if (!r) return;
  r.style.setProperty(propertyName, value);
};

export const getRandomItem = (arr: []) => {
  // Generate a random index between 0 and the length of the array
  const randomIndex = Math.floor(Math.random() * arr.length);

  // Return the element at the randomly generated index
  return arr[randomIndex];
};

export const NEXT_DAY_START = '06:00';
export const NEXT_DAY_HOUR = 6;

export const getSortedListOfValues = (array) => {
  const countMap = {};
  array.forEach((value) => {
    countMap[value] = (countMap[value] || 0) + 1;
  });
  return sortArrayBy(
    Object.keys(countMap).map((key) => ({
      value: key,
      count: countMap[key],
    })),
    'count',
    false
  ).map((c) => c.value);
};

export const getCurrentContextGroup = () => {
  const contextSidebarStore = useContextSidebarStore();
  if (!contextSidebarStore.currentContext) return null;
  if (!contextSidebarStore.currentContext.includes('group')) return null;
  const groupId = contextSidebarStore.currentContext?.split('_')[1];
  if (!groupId) return null;
  const group = getItemFromArrayBasedOnId(Number(groupId), usePage().props.auth.user.groups);
  if (!group) return null;
  return group;
};

export const getAllGroupsAndChildrenICanAccess = () => {
  return usePage().props.auth.user.groups?.flatMap(function (g) {
    return [
      {
        id: g.id,
        name: g.name,
        slug: g.slug,
      },
    ].concat(
      g.childGroups.map(function (child) {
        return {
          id: child.id,
          name: child.name,
          slug: child.slug,
        };
      })
    );
  });
};
export const capitalizeString = (string?: string | null) => {
  if (!string) return '';
  return String(string).charAt(0).toUpperCase() + String(string).slice(1);
};

export const addPluralityS = (count?: number | null) => {
  if (count === null) return '';
  if (count >= 2 || count === 0) return 's';
  return '';
};

export const pluralize = (
  word: string | unknown,
  plural: boolean,
  singleVersion: string | null = null,
  pluralVersion: string | null = null
) => {
  if (typeof word !== 'string') return word;

  if (plural) {
    if (pluralVersion) return pluralVersion;
    return `${word}s`;
  }

  if (singleVersion) return singleVersion;

  return word;
};

export const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) {
    return 'Good Morning';
  } else if (hour >= 12 && hour < 17) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
};
