<script lang="ts" setup>
import { allCountryCodes, countryToPhoneCode } from '@/util/country_codes';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useLocalStorage, useVModel } from '@vueuse/core';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { computed, ref, watch } from 'vue';
import { focusOnField } from '@/util/globals';

type Props = {
  countryCode?: string | null;
  phone?: string | null;
  placeholder?: string;
  errorMessage?: string | null;
  canEdit?: boolean;
  isHidden?: boolean;
  square?: boolean;
  label?: string | null;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  withCountryCode?: boolean;
  setFocusOnPhone?: boolean;
  title?: string;
  size?: 'small' | 'medium' | 'large' | 'block';
  withClear?: boolean;
  minHeight?: string;
};

const props = withDefaults(defineProps<Props>(), {
  countryCode: null,
  phone: null,
  placeholder: '',
  errorMessage: null,
  canEdit: true,
  disabled: false,
  isHidden: false,
  square: false,
  label: 'Phone',
  required: false,
  readonly: false,
  withCountryCode: true,
  setFocusOnPhone: false,
  size: 'medium',
  title: '',
  withClear: false,
  minHeight: undefined,
});

const emit = defineEmits<{
  (e: 'update:countryCode', value: string): void;
  (e: 'update:phone', value: string): void;
  (e: 'phoneBlur', value: string): void;
  (e: 'phoneFocus', value: string): void;
}>();

const code = useVModel(props, 'countryCode', emit);
const phoneNumberInput = ref(null);
const activeCountryCode = useLocalStorage<string | null>('country_code_phone', null);
const favoriteCountries = useLocalStorage<Set<string>>(
  'favorite_countries',
  new Set(['+47', '+46', '+45', '+44', '+1'])
);

const setCountryCode = async () => {
  try {
    if (!activeCountryCode.value) {
      const cc = await getCountryCode();

      if (!cc) new Error('Invalid country code');

      activeCountryCode.value = cc;
      favoriteCountries.value.add(cc);

      if (!code.value && props.withCountryCode && props.required) {
        code.value = cc;
      }
    } else if (!code.value && props.withCountryCode && props.required) {
      code.value = activeCountryCode.value;
    }
  } catch (_) {
    if (!code.value && props.withCountryCode && props.required) {
      code.value = '+47';
    }
  }
};

const getCountryCodeByIP = async () => {
  const response = await fetch('https://ipapi.co/json/');

  if (!response.ok) {
    throw new Error('Failed to fetch location data');
  }

  const data = await response.json();
  const countryCode = data.country_calling_code;

  // Find the dial code for the country
  const country = allCountryCodes.find((c) => c.code === countryCode);

  if (!country) throw new Error('Failed to fetch location data');

  return country.code;
};

const getCountryCode = async () => {
  if (!navigator.geolocation) {
    return getCountryCodeByIP();
  }

  // Get current position
  let position: GeolocationPosition;

  try {
    position = await new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  } catch (_) {
    return getCountryCodeByIP();
  }

  const { latitude, longitude } = position.coords;

  // Use reverse geocoding to get country from coordinates
  const response = await fetch(
    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
  );

  if (!response.ok) {
    throw new Error('Failed to fetch location data');
  }

  const data = await response.json();

  const cc = data.address.country_code.toUpperCase();

  if (!cc) throw new Error('Failed to fetch location data');

  // Find the dial code for the country
  const countryCode = countryToPhoneCode[cc];

  if (!countryCode) throw new Error('Failed to fetch location data');

  return countryCode;
};

if (props.withCountryCode) {
  setCountryCode();
}

const tlf = useVModel(props, 'phone', emit);

const sizes = {
  small: 'w-[140px]',
  medium: 'w-[300px]',
  large: 'w-[400px]',
  block: 'w-full',
};

const dropdownIsOpen = ref(false);

const onKeyDown = (e: KeyboardEvent) => {
  if (!e.key.match(/[a-z]/i)) return;

  const item = allCountryCodes.find((o) => o.name?.toLowerCase().startsWith(e.key.toLowerCase()));
  if (!item) return;

  const element = document.getElementById('phone-' + item.name);

  if (!element) return;

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
};

watch(dropdownIsOpen, () => {
  if (dropdownIsOpen.value) {
    window.addEventListener('keydown', onKeyDown);
  } else {
    window.removeEventListener('keydown', onKeyDown);
  }
});

const codes = computed(() => {
  const allCodes = [...allCountryCodes];

  // Move favorites to the top of the list
  const favoriteCountryCodes = Array.from(favoriteCountries.value)
    .map((countryCode) => allCodes.find((country) => country.code === countryCode))
    .filter(Boolean);

  // Remove favorite countries from the original list
  // const nonFavoriteCodes = allCodes.filter((country) => !favoriteCountryCodes.some((fav) => fav.code === country.code));
  const nonFavoriteCodes = allCodes;

  // Combine favorites with separator and the rest of the list
  let processedCodes = [...favoriteCountryCodes];

  if (favoriteCountryCodes.length) {
    processedCodes.push({ separator: true });
  }
  processedCodes = [...processedCodes, ...nonFavoriteCodes];

  // Add unknown code if not in the list
  if (code.value && !processedCodes.find((o) => o.code === code.value)) {
    processedCodes.push({
      name: 'Unknown',
      code: code.value,
      flag: '',
    });
  }

  return processedCodes;
});

if (props.setFocusOnPhone) {
  setTimeout(() => {
    focusOnField(phoneNumberInput.value);
  }, 50);
}
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :is-hidden="isHidden"
      :mandatory-text="required ? 'Mandatory' : null"
      :label="label" />
    <div
      class="flex h-[40px]"
      :class="[sizes[size], isHidden ? 'bg-transparent' : 'bg-inputs-background']">
      <VSelect
        v-if="withCountryCode"
        v-model="code"
        :is-hidden="isHidden"
        :can-edit="canEdit && !disabled"
        wrapper-class="h-full !w-[100px]"
        :input-area-class="`!rounded-r-none ${minHeight ? `!h-[${minHeight}]` : ''} ${square ? ` !rounded-none` : ''}`"
        option-key="code"
        :options="codes"
        placeholder="country code"
        autocomplete="off"
        :hide-arrow="true"
        @dropdown-opened="dropdownIsOpen = true"
        @dropdown-closed="dropdownIsOpen = false">
        <template #single-label="{ value }">
          <div class="flex h-full select-none items-center gap-edge-1/4">
            <div>{{ value.flag }}</div>
            <div
              v-if="size !== 'small'"
              class="truncate">
              {{ value.code }}
            </div>
          </div>
        </template>

        <template #option-label="{ option }">
          <div
            v-if="option.separator"
            class="w-full cursor-not-allowed border-t hover:select-none"></div>
          <div
            v-else
            :id="`phone-${option.name}`"
            class="flex w-full items-center justify-between gap-edge-1/4">
            <div class="flex items-center gap-edge-1/4">
              <span class="w-[24px]"> {{ option.flag }} </span>
              <span class="w-[64px] truncate">{{ option.code }} </span>
              <span class="flex-1">{{ option.name }}</span>
            </div>
            <div
              class="cursor-pointer px-edge-1/2"
              @click.stop="
                favoriteCountries.has(option.code)
                  ? favoriteCountries.delete(option.code)
                  : favoriteCountries.add(option.code)
              ">
              <i
                class="fa fa-fw fa-star"
                :class="{ 'fa-regular': !favoriteCountries.has(option.code) }" />
            </div>
          </div>
        </template>
      </VSelect>
      <div class="relative flex-1">
        <input
          ref="phoneNumberInput"
          v-model="tlf"
          :class="[
            withCountryCode ? 'rounded-left-none rounded-r' : 'rounded',
            square ? '!rounded-none' : '',
            minHeight ? `!h-[${minHeight}]` : '',
            isHidden
              ? 'border-transparent !bg-transparent disabled:!bg-transparent'
              : `bg-inputs-background ${disabled || !canEdit ? 'border-transparent' : 'border-base hover:border-[--color-border-input-hover]'}`,
          ]"
          :placeholder="canEdit && !disabled ? 'Ex: 55 55 55 55' : placeholder"
          :disabled="disabled || !canEdit"
          :readonly="readonly"
          :title="title"
          class="w-full border text-base focus:ring-0 enabled:focus:border-[--color-border-input-focus] disabled:cursor-not-allowed disabled:text-disabled disabled:placeholder:text-disabled"
          type="tel"
          @focus="$emit('phoneFocus', ($event.target as HTMLInputElement).value)"
          @blur="[$emit('phoneBlur', ($event.target as HTMLInputElement).value)]" />
        <i
          v-if="withClear"
          class="fa fa-fw fa-times absolute right-[4px] top-[12px] cursor-pointer text-soft hover:text"
          @click.stop="tlf = ''" />
      </div>
    </div>
  </div>
</template>
