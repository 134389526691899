'
<script setup lang="ts">
import { InvoiceBasesResource } from '@/types/invoice-row';
import { ref } from 'vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { blue, newGreen, yellow } from '@/variables/colors';

type Props = {
  inviteId: number;
  invoice: InvoiceBasesResource;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const emit = defineEmits<{
  (event: 'update:invoice', value: InvoiceBasesResource): void;
}>();

const loading = ref(false);
const setStatus = async (newStatus) => {
  if (props.invoice.status === newStatus) return;
  if (loading.value) return;
  loading.value = true;
  await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
    status: newStatus,
  });

  const newInvoice = { ...props.invoice };
  newInvoice.status = newStatus;
  emit('update:invoice', newInvoice);
  loading.value = false;
};
</script>

<template>
  <div>
    <InputLabel label="Status" />
    <VSelect
      placeholder="Status Unconfirmed"
      wrapper-class="-mx-edge-1/2 [&_.dropdown-element]:p-0"
      is-hidden
      :options="[
        { id: 'Unconfirmed', name: 'Unconfirmed', color: yellow },
        { id: 'Confirmed', name: 'Confirmed', color: newGreen },
        { id: 'Invoiced', name: 'Invoiced', color: blue },
      ]"
      :model-value="invoice.status"
      @update:model-value="setStatus($event)">
      <template #single-label="{ value }">
        <i
          :style="'color: ' + value.color"
          class="fa fa-fw fa-circle"></i>
        {{ value.id }}
      </template>
      <template #option-label="{ option }">
        <div class="flex items-center gap-edge-1/4">
          <i
            :style="'color: ' + option.color"
            class="fa fa-fw fa-circle"></i>
          {{ option.id }}
        </div>
      </template>
    </VSelect>
  </div>
</template>
