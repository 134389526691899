<script lang="ts" setup>
type Props = {
  options: {
    value: string | number;
    label: string;
  }[];
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void;
}>();

const model = defineModel<string | boolean | number>();
</script>

<template>
  <ul class="space-y-edge-1/2 pb-edge">
    <li
      v-for="option in options"
      :key="option.value"
      class="flex cursor-pointer items-center gap-edge"
      @click="emit('update:modelValue', option.value)">
      <input
        v-model="model"
        type="radio"
        style="box-shadow: none"
        class="focus:ring-transparent checked:appearance-none checked:bg-highlight checked:text-highlight focus:bg-highlight focus:shadow-none focus:outline-0 focus:ring-0 checked:focus:border-0 checked:focus:ring-0 focus-visible:bg-highlight focus-visible:outline-none focus-visible:ring-0"
        :value="option.value" />
      <div>
        {{ option.label }}
      </div>
    </li>
  </ul>
</template>
