<script lang="ts" setup>
import { usePageTabsStore } from '@/store/PageTabsStore';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import TheSubNavItem from '@/components/Navbar/TheSubNavItem.vue';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { PageTab } from '@/types/page-tab';
import VList from '@/components/VList.vue';
import { convertRemToPixels } from '@/util/globals';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import VButton from '@/components/Inputs/VButton.vue';
import { useEmitStore } from '@/store/EmitStore';

type Props = {
  isPrimary: boolean;
};

defineProps<Props>();

const pageTabsStore = usePageTabsStore();

const container = ref<HTMLDivElement | null>(null);
const contextSidebarStore = useContextSidebarStore();

const showableTabs = ref(null);
const itemsAfterVisible = ref([]);

const getItemsAfterVisible = async () => {
  itemsAfterVisible.value = [];
  await nextTick();
  if (!pageTabsStore.tabs) return;
  itemsAfterVisible.value = pageTabsStore.tabs?.filter((i, index) => {
    return index > showableTabs.value;
  });
  if (itemsAfterVisible.value.length === 1) {
    showableTabs.value++;
    itemsAfterVisible.value = [];
  }
};

const calcTabMaxWidth = async (tryAgain = true) => {
  if (!container.value) {
    if (tryAgain) {
      setTimeout(() => {
        calcTabMaxWidth(false);
      }, 100);
    }
    return;
  }
  const expectedWidthOfTab = convertRemToPixels(0.75) + 100;
  let newShowableTabs = Math.round(container.value.offsetWidth / expectedWidthOfTab);
  if (newShowableTabs < pageTabsStore.tabs.length) {
    newShowableTabs = Math.round((container.value.offsetWidth - 80 - convertRemToPixels(0.75)) / expectedWidthOfTab);
  }
  if (newShowableTabs !== showableTabs.value) {
    showableTabs.value = newShowableTabs;
  }
  getItemsAfterVisible();
};

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'navbar-tab-list-changed': {
      calcTabMaxWidth();
      break;
    }
    default:
      break;
  }
});

watch(
  () => pageTabsStore.tabs,
  () => {
    calcTabMaxWidth();
    setTimeout(() => {
      calcTabMaxWidth(false);
    }, 500);
    setTimeout(() => {
      calcTabMaxWidth(false);
    }, 1000);
  },
  {
    immediate: true,
  }
);

const widthOfScreen = ref(window.innerWidth);
const resetSizes = () => {
  if (widthOfScreen.value !== window.innerWidth) {
    calcTabMaxWidth();
  }
  widthOfScreen.value = window.innerWidth;
};

onMounted(() => {
  calcTabMaxWidth(true);
  setTimeout(() => {
    calcTabMaxWidth(false);
  }, 500);
  setTimeout(() => {
    calcTabMaxWidth(false);
  }, 1000);
  checkIfSubNavAlternativeIsEmpty();
  window.addEventListener('resize', resetSizes);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resetSizes);
});

setTimeout(() => {
  if (!showableTabs.value) {
    calcTabMaxWidth(false);
  }
}, 5000);

const dropDownList = (close: () => void) => {
  return itemsAfterVisible.value.map((item: PageTab) => {
    return {
      title: item.title,
      postIcon: pageTabsStore.activeTab?.key === item.key ? 'fa-check text-highlight' : '',
      action: () => {
        pageTabsStore.setActiveTab(item);
        close();
      },
    };
  });
};

const alternativeContainer = ref<HTMLDivElement | null>(null);
const subNavAlternativeIsEmpty = ref(true);
const checkIfSubNavAlternativeIsEmpty = () => {
  subNavAlternativeIsEmpty.value = true;
  for (let k = 0; k < 5000; k = k + 100) {
    setTimeout(() => {
      doTheCheckIfSubNavAlternativeIsEmpty();
    }, k);
  }
};
const doTheCheckIfSubNavAlternativeIsEmpty = async () => {
  if (!alternativeContainer.value) return;
  subNavAlternativeIsEmpty.value = false;
  await nextTick();
  subNavAlternativeIsEmpty.value = alternativeContainer.value.childNodes.length === 0;
};
watch(
  () => pageTabsStore.activeTab,
  () => {
    checkIfSubNavAlternativeIsEmpty();
  },
  { deep: true, immediate: true }
);
</script>

<template>
  <nav
    ref="container"
    :class="[
      showableTabs ? 'opacity-100' : 'opacity-0',
      isPrimary ? 'bg-[--color-background-content-header-primary]' : 'bg-[--color-background-content-header-secondary]',
    ]"
    class="flex w-full gap-edge border-b pl-edge"
    data-intro="page-tabs">
    <div
      v-for="(tab, index) in pageTabsStore.tabs"
      v-show="!showableTabs || index <= showableTabs"
      :key="tab.key"
      :class="[
        pageTabsStore.activeTab?.key === tab.key
          ? 'border-[--border-sub-nav-active] font-semibold text'
          : 'border-transparent font-mediumbold text-soft',
      ]"
      class="flex cursor-pointer items-center text-nowrap border-b-[2px] capitalize hover:text"
      @click="pageTabsStore.setActiveTab(tab)">
      <div class="my-auto">{{ tab.title }}</div>
    </div>

    <div
      v-if="itemsAfterVisible.length > 1"
      class="flex items-center">
      <VDropdown
        :items="
          itemsAfterVisible.map((item: PageTab) => {
            return {
              title: item.title,
            };
          })
        "
        close-on-click>
        <template #click-area>
          <TheSubNavItem
            v-if="itemsAfterVisible.length > 1"
            class="w-[80px]"
            :active="itemsAfterVisible.map((t) => t.key).includes(pageTabsStore.activeTab?.key)"
            :title="'+' + itemsAfterVisible.length + ' items'" />
        </template>
        <template #dropdown="{ close }">
          <VList :items="dropDownList(close).concat(pageTabsStore.addTabItems ?? [])" />
        </template>
      </VDropdown>
    </div>

    <div v-if="pageTabsStore.addTabItems.length > 0 && !itemsAfterVisible.length">
      <VDropdown :items="pageTabsStore.addTabItems">
        <template #click-area>
          <VButton
            icon="fa-plus"
            tool-tip-text="Add New Tab" />
        </template>
      </VDropdown>
    </div>

    <TheSubNavItem
      v-if="itemsAfterVisible.length === 1"
      :active="itemsAfterVisible[0].key === pageTabsStore.activeTab?.key"
      :title="itemsAfterVisible[0].title"
      @click="pageTabsStore.setActiveTab(itemsAfterVisible[0])" />
  </nav>
</template>
