<script setup lang="ts">
import GroupEventSectionWrapper from '@/components/Groups/partials/GroupEventSectionWrapper.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import InputWithSearch from '@/components/Inputs/InputWithSearch.vue';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { postContactInvite } from '@/services/api-contacts';
import { createUuId, getKey, groupBy } from '@/util/globals';
import { copyObject } from '@/util/object-helpers';
import { validEmail } from '@/util/validators';
import { computed, defineAsyncComponent, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { PartnerContactResource } from '@/types/partners';
import { usePage } from '@inertiajs/vue3';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';

const props = withDefaults(defineProps<Props>(), {
  id: null,
  contactModelSlug: null,
  projectLeader: null,
  serialNumber: 3,
  isUpdate: false,
});

const emit = defineEmits<{
  (e: 'add', value: object): void;
  (e: 'update', value: object): void;
  (e: 'remove', arg: string | number): void;
  (e: 'openContactPage', value: number): void;
}>();

const PartnerSlideOut = defineAsyncComponent(() => import('@/components/Modals/PartnerSlideOut.vue'));

type Contact = {
  id: number | string | null;
  role: string;
  phone: string;
  email: string;
  contact_id: number | null;
  contact_type: string | null;
  invite: boolean;
  write: boolean;
  first_name: string;
  last_name: string;
  name?: string;
  country_code: string;
};

type Props = {
  id?: null | string;
  contacts: Contact[];
  tabIndex: number;
  serialNumber?: number;
  hasPartners: boolean;
  isUpdate?: boolean | null;
  contactModel: string;
  contactModelId: number;
  contactModelSlug?: string | null;
  projectLeader?: object | null;
  invitedEmails: string[];
};

const toast = useToast();

const contactsByRole = computed(() => groupBy(props.contacts, 'role'));

const modalOpen = ref(false);
const canSubmit = ref(true);

const alreadyUsedRoles = computed(() => {
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  return props.contacts.map((c) => c.role).filter(onlyUnique);
});

const currentContact = ref<Contact>({
  id: null,
  contact_id: null,
  first_name: '',
  last_name: '',
  phone: '',
  country_code: '',
  email: '',
  role: '',
  name: '',
  contact_type: null,
  invite: false,
  write: false,
});

const canSave = computed(() => {
  const name = props.hasPartners ? currentContact.value.first_name.length : currentContact.value.name?.length;
  const role = currentContact.value.role && currentContact.value.role.length;

  return [role, name].every((v) => v);
});

const openModal = (contact: any = null) => {
  if (contact) {
    currentContact.value = copyObject(contact);
  } else {
    currentContact.value = {
      id: null,
      contact_id: null,
      contact_type: null,
      first_name: '',
      last_name: '',
      phone: '',
      name: '',
      country_code: '',
      email: '',
      role: '',
      invite: false,
      write: false,
    };
  }

  modalOpen.value = true;
};

const createContact = (close: () => void) => {
  if (!canSave.value) {
    return;
  }
  emit('add', { ...currentContact.value, id: createUuId('temp_') });
  close();
};

const updateContact = (close: () => void) => {
  if (!canSave.value) {
    return;
  }
  emit('update', currentContact.value);
  close();
};

const onDeleteContact = (close: () => void) => {
  if (!currentContact.value.id) return;

  emit('remove', currentContact.value.id);
  toast.success('Contact removed');
  close();
};

const doTheActualInviteOfContactToEvent = async (write: boolean, close: () => void) => {
  if (!currentContact.value.id || typeof currentContact.value.id === 'string') return;

  const { data } = await postContactInvite(currentContact.value.id, {
    write,
    email: currentContact.value.email,
    name: currentContact.value.first_name + ' ' + currentContact.value.last_name,
    phone: currentContact.value.phone,
    role: currentContact.value.role,
  });

  currentContact.value.email = data[1].contactEmail;
  currentContact.value.phone = data[1].contactPhone;
  if (data[1].contact?.first_name) currentContact.value.first_name = data[1].contact.first_name;
  if (data[1].contact?.last_name) currentContact.value.last_name = data[1].contact.last_name;
  currentContact.value.contact_type = data[1].contact_type;
  currentContact.value.contact_id = data[1].contact_id;
  currentContact.value.invite = true;
  toast.success(`${currentContact.value.first_name + ' ' + currentContact.value.last_name} has been invited`);
  updateContact(close);
};

const inviteContactToEvent = async (close: () => void) => {
  if (currentContact.value.id) {
    const yes = await useCertaintyModal().assertCertain(
      'Invite contact to event',
      `Would you like to give ${currentContact.value.first_name} write access?`
    );
    if (yes) {
      await doTheActualInviteOfContactToEvent(true, close);
    } else {
      await doTheActualInviteOfContactToEvent(false, close);
    }
  }
};

const addContact = (contact) => {
  currentContact.value.contact_id = contact.id;
  currentContact.value.first_name = contact.first_name;
  currentContact.value.last_name = contact.last_name;
  currentContact.value.contact_type = 'App\\PartnerContact';
  currentContact.value.email = contact.email;
  currentContact.value.phone = contact.phone;
  currentContact.value.country_code = contact.country_code;
  currentContact.value.write = false;
};

const showPartner = ref(false);
const partnerId = ref<number | null>(null);

const openCurrentContact = async () => {
  if (!currentContact.value.contact_id) return;
  if (!props.contactModelSlug) {
    emit('openContactPage', currentContact.value.contact_id);
    return;
  }
  partnerId.value = null;
  showPartner.value = false;
  await nextTick();
  partnerId.value = currentContact.value.contact_id;
  showPartner.value = true;
};

const onUpdateSelectedContact = (updatedPartner: PartnerContactResource) => {
  currentContact.value.first_name = updatedPartner.first_name;
  if (updatedPartner.last_name !== null) currentContact.value.last_name = updatedPartner.last_name;
  if (updatedPartner.email !== null) currentContact.value.email = updatedPartner.email;
  if (updatedPartner.phone !== null) currentContact.value.phone = updatedPartner.phone;
  if (updatedPartner.country_code !== null) currentContact.value.country_code = updatedPartner.country_code;
};

const actions = computed(() => {
  let array = [];

  const emailsInContacts = props.contacts.map((c) => c.email);
  const me = usePage().props.auth.user;

  const addMe = !emailsInContacts.includes(me.email);
  const addProjectLeader =
    props.projectLeader &&
    getKey(props.projectLeader, 'email') !== me.email &&
    !emailsInContacts.includes(props.projectLeader.email);

  if (addProjectLeader && !props.isUpdate) {
    array.push({
      title: 'Project Leader',
      primary: false,
      icon: 'fa-plus',
      emphasized: true,
      action: () => {
        openModal({
          id: null,
          contact_id: props.projectLeader.id,
          contact_type: 'App\\User',
          first_name: props.projectLeader.first_name,
          last_name: props.projectLeader.last_name,
          phone: props.projectLeader.phone,
          name: props.projectLeader.name,
          country_code: props.projectLeader.country_code,
          email: props.projectLeader.email,
          role: '',
          invite: false,
          write: false,
        });
      },
    });
  }
  if (addMe && !props.isUpdate) {
    array.push({
      title: 'Add Me',
      primary: false,
      icon: 'fa-plus',
      emphasized: true,
      action: () => {
        openModal({
          id: null,
          contact_id: me.id,
          contact_type: 'App\\User',
          first_name: me.first_name,
          last_name: me.last_name,
          phone: me.phone,
          name: me.name,
          country_code: me.country_code,
          email: me.email,
          role: '',
          invite: false,
          write: false,
        });
      },
    });
  }
  return array.concat([
    {
      title: 'Add Contact',
      primary: false,
      icon: 'fa-plus',
      emphasized: true,
      action: () => {
        openModal();
      },
    },
  ]);
});

const closeModal = () => {
  modalOpen.value = false;

  currentContact.value = {
    id: null,
    invite: false,
    contact_id: null,
    contact_type: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    country_code: '',
    role: '',
    write: false,
  };
};

const isContactCreated = (contact: Contact) => {
  return !(!contact.id || (typeof contact.id === 'string' && contact.id.startsWith('temp_')));
};
</script>

<template>
  <div>
    <GroupEventSectionWrapper
      :serial-number="serialNumber"
      title="Key Contacts"
      :actions="actions"
      :content-padding="contacts.length > 0"
      :has-content="contacts.length > 0"
      sub-title="Key contacts of the event, easily accessible for everyone in this event.">
      <template #content>
        <div class="grid grid-cols-2 gap-edge">
          <BoxContainer
            v-for="(contactArray, role) in contactsByRole"
            :key="contactArray.id"
            :content-padding="false"
            header-size="h4"
            :title="role ? role : 'No Role'">
            <VTable edge-to-edge>
              <VTableRow
                v-for="contact in contactArray"
                :key="contact.id"
                main-row
                clickable
                @click="openModal(contact)">
                <VTableCell style="width: 30px">
                  <i
                    v-if="contact.email !== null && contact.invite"
                    class="fa fa-envelope fa-regular"
                    :title="contact.invite ? 'Already invited to Event' : 'Not Invited to event'" />
                </VTableCell>

                <VTableCell>
                  <span v-if="!hasPartners">
                    {{ contact.name }}
                  </span>
                  <span v-else>
                    {{ contact.first_name }}
                    {{ contact.last_name }}
                  </span>
                </VTableCell>
                <VTableCell style="width: 40px">
                  <VButton
                    icon="fa-pencil"
                    size="xs"
                    @click="openModal(contact)" />
                </VTableCell>
              </VTableRow>
            </VTable>
          </BoxContainer>
        </div>
        <EmptyStateFullPage
          v-if="contacts.length === 0 && isUpdate"
          icon="fa-key fa-sm fa-regular"
          size="sm"
          class="mt-edge"
          button-text="Add Contact"
          :button-function="null"
          description="No Contacts Created" />
      </template>
    </GroupEventSectionWrapper>
    <div>
      <CrudModal
        v-if="modalOpen"
        :update="!!currentContact.id"
        medium
        :disabled="!canSave || !canSubmit"
        :title="`${!!currentContact.id ? 'Update' : 'Create'} Contact`"
        @update="updateContact"
        @create="createContact"
        @delete="onDeleteContact"
        @closed="closeModal">
        <template #area-in-footer-for-buttons>
          <div
            v-if="
              currentContact.contact_type !== null &&
              currentContact.contact_id !== null &&
              contactModelSlug &&
              currentContact.contact_type === 'App\\PartnerContact'
            ">
            <VButton
              size="lg"
              type="primary"
              title="Update Contact"
              icon="fa-pencil"
              @click="openCurrentContact" />
          </div>
        </template>
        <template #default="{ close }">
          <div class="form-layout grid-cols-2">
            <template v-if="hasPartners">
              <InputWithSearch
                v-model:text="currentContact.first_name"
                set-focus
                label="First name"
                required
                :can-edit="currentContact.contact_type === null"
                :search-string="`${currentContact.first_name}%${currentContact.last_name ? currentContact.last_name : ''}`"
                placeholder="Jane"
                search-param="search"
                url="/api/partners/contacts"
                :params="{
                  model_type: 'App\\' + contactModel,
                  model_id: contactModelId,
                }"
                @selected="addContact" />

              <InputWithSearch
                v-model:text="currentContact.last_name"
                label="Last name"
                required
                :can-edit="currentContact.contact_type === null"
                :search-string="`${currentContact.first_name}%${currentContact.last_name ? currentContact.last_name : ''}`"
                placeholder="Doe"
                search-param="search"
                url="/api/partners/contacts"
                :params="{
                  model_type: 'App\\' + contactModel,
                  model_id: contactModelId,
                }"
                @selected="addContact" />

              <InputWithSearch
                v-model:text="currentContact.email"
                label="Email"
                is-email
                :can-edit="currentContact.contact_type === null"
                placeholder="me@example.com"
                url="/api/partners/contacts"
                search-param="search"
                :params="{
                  model_type: 'App\\' + contactModel,
                  model_id: contactModelId,
                }"
                @selected="addContact" />

              <PhoneInput
                v-model:phone="currentContact.phone"
                v-model:country-code="currentContact.country_code"
                :can-edit="currentContact.contact_type === null"
                size="block" />
            </template>

            <template v-else>
              <TextInput
                v-model="currentContact.name"
                required
                label="Name" />

              <EmailInput v-model="currentContact.email" />

              <PhoneInput
                v-model:phone="currentContact.phone"
                v-model:country-code="currentContact.country_code"
                size="block" />
            </template>

            <TextInput
              v-model="currentContact.role"
              required
              :set-focus="currentContact.contact_type !== null"
              :data-list-options="alreadyUsedRoles"
              placeholder="Add a Role"
              label="role" />

            <div class="flex flex-col items-end justify-end gap-edge-1/4">
              <VButton
                v-if="currentContact.contact_type !== null && currentContact.contact_id === null"
                title="clear contact"
                size="xs"
                type="warning"
                @click="[(currentContact.contact_type = null), (currentContact.contact_id = null)]" />

              <div
                v-if="
                  (currentContact.contact_type !== null || currentContact.contact_id !== null) &&
                  currentContact.id === null
                "
                class="text">
                <VButton
                  size="md"
                  tool-tip-text="Clear Contact, from contact list."
                  icon="fa-times"
                  type="warning"
                  title="Clear Contact"
                  @click="[
                    (currentContact.contact_id = null),
                    (currentContact.contact_type = null),
                    (currentContact.first_name = ''),
                    (currentContact.last_name = ''),
                    (currentContact.phone = ''),
                    (currentContact.name = ''),
                    (currentContact.country_code = ''),
                    (currentContact.email = ''),
                    (currentContact.invite = false),
                    (currentContact.write = false),
                  ]" />
              </div>
            </div>

            <div class="min-h-[40px]">
              <!--          <template v-if="currentContact.email && currentContact.email.length && validEmail(currentContact.email)">-->
              <template v-if="!isContactCreated(currentContact)">
                <SettingCheck
                  v-model="currentContact.invite"
                  :disabled="!(currentContact.email && currentContact.email.length && validEmail(currentContact.email))"
                  label="Invite Contact to Event"
                  @update:model-value="[!$event ? (currentContact.write = false) : null]" />

                <SettingCheck
                  v-model="currentContact.write"
                  :disabled="!currentContact.invite"
                  disabled-title="To give write access, you must invite the contact to an event."
                  label="Give Write access" />
              </template>
              <div
                v-if="isContactCreated(currentContact)"
                style="min-height: 80px">
                <VButton
                  v-if="!currentContact.invite"
                  :disabled="!(currentContact.email && currentContact.email.length && validEmail(currentContact.email))"
                  icon="fa-envelope fa-regular"
                  title="Invite"
                  size="sm"
                  type="default"
                  @click="inviteContactToEvent(close)" />

                <VButton
                  v-if="currentContact.invite"
                  type="default"
                  size="sm"
                  icon="fa-envelope fa-regular"
                  title="Invite"
                  disabled
                  disabled-tool-tip-text="Already invited" />
              </div>
              <!--          </template>-->
            </div>
          </div>
        </template>
      </CrudModal>
      <Suspense>
        <template #default>
          <PartnerSlideOut
            v-if="showPartner && partnerId"
            :partner-type="'contact'"
            :partner-id="partnerId"
            :model-id="contactModelId"
            :model-type="'Group'"
            can-edit
            :venue-id="null"
            :z-index="3651"
            :simple="true"
            @update-partner="onUpdateSelectedContact"
            @closed="[(showPartner = false)]" />
        </template>

        <template #fallback>
          <div>loading...</div>
        </template>
      </Suspense>
    </div>
  </div>
</template>
