import { formatModel } from '@/util/store-helpers';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { GuestFieldResource, GuestListSettingAccessResource } from '@/types/festival';

export const useGuestListSettingsStore = defineStore('guest-list-settings', () => {
  const list = ref<Map<string, any[]>>(new Map());
  const loading = ref(false);

  const fetchGuestListSettings = async (modelType: string, modelId: number, performanceId: number | null) => {
    if (modelType.includes('App\\')) throw new Error('modelType should not include App\\');

    loading.value = true;
    const { data } = await axios.get('/api/guest-list-settings', {
      params: {
        model_type: `App\\${modelType}`,
        model_id: modelId,
        performance_id: performanceId,
      },
    });
    list.value.set(formatModel(modelType, modelId), data);
    loading.value = false;
  };
  const exchangeGuestListSettingFields = async (
    modelType: string,
    modelId: number,
    performanceId: number | null,
    guestListSettingFields: GuestFieldResource[]
  ) => {
    if (modelType.includes('App\\')) throw new Error('modelType should not include App\\');

    const data = list.value.get(formatModel(modelType, modelId));
    if (!data) {
      await fetchGuestListSettings(modelType, modelId, performanceId);
      return;
    }
    data[0].guest_fields = guestListSettingFields;
    list.value.set(formatModel(modelType, modelId), data);
  };
  const exchangeGuestListSettingAccesses = async (
    modelType: string,
    modelId: number,
    performanceId: number | null,
    guestListSettingAccesses: GuestListSettingAccessResource[]
  ) => {
    if (modelType.includes('App\\')) throw new Error('modelType should not include App\\');

    const data = list.value.get(formatModel(modelType, modelId));
    if (!data) {
      await fetchGuestListSettings(modelType, modelId, performanceId);
      return;
    }
    data[0].guest_list_settings_accesses = guestListSettingAccesses;
    list.value.set(formatModel(modelType, modelId), data);
  };

  return {
    list,
    loading,
    fetchGuestListSettings,
    exchangeGuestListSettingFields,
    exchangeGuestListSettingAccesses,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGuestListSettingsStore, import.meta.hot));
}
