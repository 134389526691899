<script setup lang="ts">
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import { computed } from 'vue';

type Props = {
  modelValue?: string | object | number[] | null;
  canEdit?: boolean;
  isHidden?: boolean;
  options?: string[] | object[] | number[] | null;
  iconLeft?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  canEdit: false,
  isHidden: false,
  options: null,
  iconLeft: null,
});

const emit = defineEmits<{
  (e: 'blur', arg: string | number | null): void;
  (e: 'dropdownClosed'): void;
}>();

const emitValue = (value: [] | null) => {
  emit('blur', JSON.stringify(value));
};

const formattedModelValue = computed(() => {
  let value = props.modelValue;
  if (typeof value === 'string' && value?.length > 0) {
    value = JSON.parse(value);
  }
  return value;
});
</script>

<template>
  <VMultiselect
    :model-value="formattedModelValue"
    :can-edit="canEdit"
    :options="options"
    :icon-left="iconLeft"
    :is-hidden="isHidden"
    nullable
    class="min-w-[50px]"
    placeholder=""
    nullable-display-text=""
    @dropdown-closed="emit('dropdownClosed')"
    @update:model-value="emitValue" />
</template>
