import axios, { AxiosResponse } from 'axios';

type Success<T> = {
  data: T;
  error?: never;
};

type Failure<E> = {
  data?: never;
  error: E;
};

type Result<T, E = Error> = Success<T> | Failure<E>;

export const tryCatch = async <T, E = Error>(promise: Promise<AxiosResponse<T>>): Promise<Result<T, E>> => {
  try {
    const { data } = await promise;
    return { data, error: undefined };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const message = err.response?.data?.message ?? 'Unknown error';
      return { data: undefined, error: message as E };
    }
    return { data: undefined, error: err as E };
  }
};

export const tryCatchAny = async <T, E = Error>(promise: Promise<T>): Promise<Result<T, E>> => {
  try {
    const data = await promise;
    return { data, error: undefined };
  } catch (error) {
    return { data: undefined, error: error as E };
  }
};
