<script setup lang="ts">
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import {
  compareTwo,
  formatStampAsDate,
  formatStampAsHumanReadableDate,
  formattedStartAndEndAsInterval,
} from '@/util/timeFunctions';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import { humanReadableWeekDay } from '@/variables/date-format';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import NameAvatar from '@/components/NameAvatar.vue';
import { concatName } from '@/services/api-partners';
import DisplayPhone from '@/components/Display/DisplayPhone.vue';
import DisplayEmail from '@/components/Display/DisplayEmail.vue';
import { getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey, groupBy } from '@/util/globals';
import { getComponent } from '@/util/get-component';
import GridTemplate from '@/components/GridTemplate.vue';
import { allAvailableFieldTypes, multiSelectFieldType, tableFieldType } from '@/util/fields';
import {
  PerformanceForInfoAccessPerformanceInfoFieldResource,
  PerformanceForInfoAccessResource,
} from '@/types/performance';
import { InviteResource } from '@/types/invite';
import { EventResource } from '@/types/event';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import { ref } from 'vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import { useEmitStore } from '@/store/EmitStore';

type Props = {
  performance: PerformanceForInfoAccessResource;
  invite?: InviteResource | null;
  event?: EventResource | null;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
  invite: null,
  event: null,
});

const emit = defineEmits<{
  (e: 'update:performance', arg: PerformanceForInfoAccessResource): void;
}>();

const performanceItinerary = () => {
  if (!props.performance.itinerary) return null;
  const groupedItems = props.performance.itinerary.sort((a, b) => compareTwo(a, b, 'start'));
  return groupBy(groupedItems, (item) => formatStampAsDate(item.start));
};

const getItemIcon = (item) => {
  switch (item.type) {
    case 'App\\ShowTime': {
      return 'fa-volume-up';
    }
    case 'App\\Models\\LocalTravels\\LocalTravel': {
      return 'fa-car';
    }
  }
  return 'fa-list';
};

const assignValue = async (value, field: PerformanceForInfoAccessPerformanceInfoFieldResource, sectionId: number) => {
  if (!field.editable && !props.canEdit) return;
  if (field.performance_info_access_id) {
    if (props.invite) {
      await axios.post(`/api/invites/${props.invite.id}/performance-info-accesses/`, {
        performance_id: props.performance.id,
        performance_info_section_id: sectionId,
        performance_info_field_id: field.id,
        value: value,
      });
    } else if (props.event) {
      await axios.post(`/api/events/${props.event.id}/performance-info-accesses/`, {
        performance_id: props.performance.id,
        performance_info_section_id: sectionId,
        performance_info_field_id: field.id,
        value: value,
      });
    }
  } else {
    await axios.post(`/api/performances/${props.performance.id}/performance-info-fields/${field.id}`, {
      value,
    });
  }
  const perf = { ...props.performance };
  const sectionIndex = getIndexFromArrayBasedOnId(sectionId, perf.performance_info_sections);
  if (sectionIndex === -1) return;
  const fieldIndex = getIndexFromArrayBasedOnId(
    field.id,
    perf.performance_info_sections[sectionIndex].performance_info_fields
  );
  if (fieldIndex === -1) return;
  perf.performance_info_sections[sectionIndex].performance_info_fields[fieldIndex].value = value;

  if (field.component === 'field-document' && !value) {
    perf.performance_info_sections[sectionIndex].performance_info_fields[fieldIndex].meta = null;
  }
  emit('update:performance', perf);
  useEmitStore().rootEmit('performance-info-field-value-updated', { field: field, performance: props.performance });
};

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'performance-info-field-value-updated': {
      const field = getKey(state.item?.payload, 'field');
      const performance = getKey(state.item?.payload, 'performance');
      if (!field || !performance) return;
      if (performance.id !== props.performance.id) return;
      const performanceData = { ...props.performance };
      const infoSectionIndex = getIndexFromArrayBasedOnId(
        field.performance_info_section_id,
        performanceData.performance_info_sections
      );
      if (infoSectionIndex === -1) return;
      const fieldIndex = getIndexFromArrayBasedOnId(
        field.id,
        performanceData.performance_info_sections[infoSectionIndex].performance_info_fields
      );
      if (fieldIndex === -1) return;
      performanceData.performance_info_sections[infoSectionIndex].performance_info_fields[fieldIndex].value =
        field.value;
      emit('update:performance', performanceData);
      break;
    }
    default:
      break;
  }
});

const documentUploaded = async (sectionId: number, fieldId: number, doc) => {
  const perf = { ...props.performance };
  const sectionIndex = getIndexFromArrayBasedOnId(sectionId, perf.performance_info_sections);
  if (sectionIndex === -1) return;
  const fieldIndex = getIndexFromArrayBasedOnId(
    fieldId,
    perf.performance_info_sections[sectionIndex].performance_info_fields
  );
  if (fieldIndex === -1) return;
  perf.performance_info_sections[sectionIndex].performance_info_fields[fieldIndex].meta = doc;
  emit('update:performance', perf);
};

const getProps = (field: PerformanceForInfoAccessPerformanceInfoFieldResource) => {
  const defaultProps = {
    canEdit: field.editable && props.canEdit,
    'model-value': getKey(field, 'value', null),
  };

  const leftIcon = getItemFromArrayBasedOnId(
    field.component,
    [...allAvailableFieldTypes, ...multiSelectFieldType, ...tableFieldType],
    { icon: null },
    'component'
  ).icon;
  switch (field.component) {
    case 'field-text': {
      return {
        ...defaultProps,
        'min-height': 40,
        'min-rows': 1,
        'icon-left': leftIcon,
      };
    }
    case 'field-date':
    case 'field-rich-text': {
      return {
        ...defaultProps,
      };
    }
    case 'field-number': {
      return {
        ...defaultProps,
        withDecimals: true,
        'icon-left': leftIcon,
        'size': 'block',
      };
    }
    case 'field-multi-select':
    case 'field-list': {
      return {
        ...defaultProps,
        options: field.options,
        'icon-left': leftIcon,
        nullableDisplayText: ' ',
      };
    }
    case 'field-table': {
      return {
        ...defaultProps,
        options: field.options,
        nullable: true,
        'icon-left': leftIcon,
        nullableDisplayText: ' ',
        editForm: false,
        editContent: false,
      };
    }
    case 'field-document':
    case 'field-public-document': {
      defaultProps.modelValue = getKey(field, 'meta', null);
      return {
        ...defaultProps,
        model: 'Performance',
        modelId: props.performance.id,
        canEditImage: false,
        useUploadModal: false,
        'emit-document-action-as': 'blur',
      };
    }
    default: {
      return defaultProps;
    }
  }
};

const open = ref(true);
</script>
<template>
  <div
    class="h-full"
    :class="{ 'flex': performance.itinerary !== null }">
    <div class="flex-1 overflow-auto p-edge">
      <div
        v-if="event"
        class="flex cursor-pointer items-center gap-edge"
        @click="open = !open">
        <h3>
          <ChevronToggle
            classes="fa-sm fa-regular"
            :model-value="open" />
          {{ performance.name }}
        </h3>
        <DisplayBadge
          v-if="performance.owner"
          color="green"
          :text="performance.owner.name"
          size="small" />
      </div>
      <div class="transition-all duration-200">
        <div
          v-show="open"
          class="flex-1 transform space-y-edge overflow-auto pt-edge">
          <BoxContainer
            v-if="performance.contacts"
            header-size="h3"
            openable
            title="Contacts">
            <div class="grid grid-cols-[repeat(auto-fit,minmax(250px,350px))] gap-edge">
              <div
                v-for="contact in performance.contacts"
                class="group flex items-center gap-edge rounded border p-edge">
                <NameAvatar
                  :full-name="concatName(contact)"
                  background-color="bg-[hsl(var(--color-event-type-orange))]"
                  class="h-[50px] text-2xl text-[hsl(var(--gray-950))]" />
                <div class="flex flex-1 flex-col overflow-hidden">
                  <span class="text-sm text-soft">{{ contact.title }}</span>
                  <div class="flex items-center gap-edge-1/4">
                    <span :title="concatName(contact)">{{ concatName(contact) }}</span>
                  </div>
                  <DisplayPhone
                    v-if="contact.phone"
                    :country-code="contact.country_code"
                    :phone-number="contact.phone" />
                  <DisplayEmail
                    v-if="contact.email"
                    :email="contact.email" />
                </div>
              </div>
            </div>
          </BoxContainer>
          <BoxContainer
            v-for="section in performance.performance_info_sections"
            header-size="h3"
            :title="section.title">
            <GridTemplate
              v-slot="{ item: field }"
              gap="edge"
              :model-value="section.performance_info_fields"
              :columns="4">
              <div
                class="group flex h-full"
                style="width: inherit">
                <div
                  class="flex h-full flex-1 flex-col"
                  style="width: inherit">
                  <div>
                    <div class="gap-3 flex flex-1 truncate">
                      <InputLabel
                        :label="field.title"
                        :title="field.description" />
                    </div>
                  </div>
                  <div class="h-full min-h-[40px] w-full flex-1 items-center">
                    <component
                      :is="getComponent(field.component)"
                      v-bind="getProps(field)"
                      @document-uploaded="documentUploaded(section.id, field.id, $event)"
                      @blur="assignValue($event, field, section.id)" />
                  </div>
                </div>
              </div>
            </GridTemplate>
          </BoxContainer>
        </div>
      </div>
    </div>
    <div
      v-if="performance.itinerary"
      class="transition-all duration-200">
      <div
        :class="[open ? 'h-full' : 'h-0']"
        class="performance-info-access-itinerary-container flex w-[250px] flex-col overflow-hidden border-l bg">
        <h3 class="px-edge py-edge-1/2">Itinerary</h3>
        <div v-if="Object.keys(performanceItinerary()).length === 0">
          <EmptyStateFullPage
            icon="fa-list"
            size="small"
            description="No itinerary Items Added Yet"
            :button-function="null" />
        </div>
        <div
          v-else
          class="flex-1 divide-y overflow-auto pb-edge">
          <div
            v-for="(date, idx) in performanceItinerary()"
            :key="idx">
            <div class="px-edge py-edge-1/2">
              <InputLabel
                super-text
                :label="formatStampAsHumanReadableDate(date[0].start, humanReadableWeekDay)" />

              <h4>{{ formatStampAsHumanReadableDate(date[0].start, 'Do [of] MMMM') }}</h4>
            </div>
            <div>
              <div
                v-for="(item, _idx) in date"
                :key="_idx"
                class="gap-x-2 grid min-h-[4rem] grid-cols-[40px_auto] items-center text-soft hover:bg-row hover:text">
                <div class="text-center">
                  <i
                    class="fa fa-fw fa-regular"
                    :class="getItemIcon(item)" />
                </div>
                <div>
                  <div class="flex flex-col">
                    <InputLabel
                      super-text
                      :label="formattedStartAndEndAsInterval(item.start, item.end, date[0].start)" />

                    <div class="text-sm text">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
