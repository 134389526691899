export const formatModel = (model: string | null, modelId: number | null, type?: null | string | undefined) => {
  if (!model || !modelId) return null;

  if (model.includes('App\\')) throw new Error('model should not include App\\');

  if (type) {
    return `${model}_${modelId}_type_${type}`;
  }

  return `${model}_${modelId}`;
};
