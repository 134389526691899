<script setup lang="ts">
import { computed } from 'vue';
import { twMerge } from 'tailwind-merge';

export type NameAvatarProps = {
  fullName: string;
  backgroundColor?: string;
};

const { fullName } = defineProps<NameAvatarProps>();

const avatarName = computed(() => {
  const splitted = fullName.split(' ');

  if (splitted.length < 2) return `${splitted[0].charAt(0)}${splitted[0].charAt(1)}`;

  return `${splitted[0].charAt(0)}${splitted[splitted.length - 1]?.charAt(0)}`;
});
</script>

<template>
  <div
    :class="
      twMerge(
        'inline-flex aspect-1 items-center justify-center rounded-full p-edge-1/4 uppercase',
        $attrs.class,
        backgroundColor
      )
    ">
    {{ avatarName }}
  </div>
</template>
