<script setup lang="ts">
import { computed, nextTick, ref } from 'vue';
import { useFestivalSectionsStore } from '@/store/festival/festival-section-store';
import { formatStampAsDate, formatStampAsHumanReadableDate, timeStampsAreSame } from '@/util/timeFunctions';
import { dateTimeFormat, humanReadableDateFormatShort, timeFormat } from '@/variables/date-format';
import VTable from '@/components/Tables/VTable.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import ShiftForm from '@/components/Modals/ShiftForm.vue';
import { FestivalResource } from '@/types/festival';
import { useTimeSlots } from '@/composables/use-time-slots';
import { getFestivalSectionCrew } from '@/services/api-festival-sections';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import moment from 'moment/moment';
import { usePage } from '@inertiajs/vue3';
import { getFestivalSchedulerCalendar, getFestivalSchedulerVenues } from '@/services/api-festivals';
import { useToast } from 'vue-toastification';
import VButton from '@/components/Inputs/VButton.vue';
import FestivalShiftAssignModal from '@/components/Festivals/Shifts/FestivalShiftAssignModal.vue';
import { updateCountOfShiftUpForSaleForShift, updateCountOfSlotsExistingForShift } from '@/helpers/shiftFunctions';
import { useEmitStore } from '@/store/EmitStore';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { useFestival } from '@/composables/use-festival';
import { canEditFestivalSectionsOnFestivalString } from '@/variables/permissions';

type Props = {
  festival: FestivalResource;
  event: object;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const loading = ref(false);

const useFestivalSectionStore = useFestivalSectionsStore();
const festivalSections = computed(() => useFestivalSectionStore.getForFestival(props.festival?.id));
const { fetch: fetchTimeSlots, timeSlots } = useTimeSlots('Festival', props.festival.id);
const { fetch: fetchFestivalResource, festival: festivalResource } = useFestival(props.festival.id, false);

const shifts = ref([]);

const fetchShifts = async () => {
  if (!props.event || !props.festival) return;
  loading.value = true;
  axios.get(`/api/festivals/${props.festival.id}/events/${props.event.id}/shifts`).then((response) => {
    shifts.value = response.data;
    loading.value = false;
  });
};

const shiftsForSection = (section) => {
  return shifts.value.filter(
    (s) => s.via_id === section.id && s.via_type === 'App\\Models\\Festivals\\FestivalSection'
  );
};

const getCounterClass = (section, forShift = true) => {
  let sectionShifts = shiftsForSection(section);
  if (!forShift) {
    sectionShifts = sectionShifts.flatMap((s) => s.shift_crew_slots);
  }

  if (sectionShifts.length === 0) {
    return 'transparent';
  }

  if (sectionShifts.length <= sectionShifts.filter((s) => s.accepted_at !== null && s.slottable !== null).length) {
    return 'success';
  }
  if (sectionShifts.filter((s) => s.accepted_at !== null).length === 0) {
    return 'warning';
  }
  return 'pending';
};

const getIconForShift = (shift, title = false) => {
  if (shift.user_id === null) {
    return title ? 'Un-assigned' : 'fa-circle text-[hsl(var(--blue-100))]';
  }
  if (shift.declined_at !== null) {
    return title ? 'Declined' : 'fa-circle text-red';
  }
  if (shift.accepted_at === null) {
    return title ? 'Pending Response' : 'fa-circle text-pending';
  }
  if (shift.check_in === null) {
    return title ? 'Accepted' : 'fa-circle text-success';
  }
  if (shift.check_out === null) {
    return title ? 'Ongoing' : 'fa-circle-o-notch text-success fa-spin';
  }
  return title ? 'Complete' : 'fa-check-double text-success';
};

const getIconForSlot = (slot, title = false) => {
  if (slot.slottable_id === null) {
    return title ? 'Un-assigned' : 'fa-circle text-[hsl(var(--blue-100))]';
  }
  if (slot.check_in === null) {
    return title ? 'Assigned' : 'fa-circle text-success';
  }
  if (slot.check_out === null) {
    return title ? 'Ongoing' : 'fa-circle-o-notch text-success fa-spin';
  }
  return title ? 'Complete' : 'fa-check-double text-success';
};

const openingUpShiftForm = ref(false);
const schedulerEvents = ref([]);
const schedulerEventsLoaded = ref(false);
const fetchSchedulerEvents = async () => {
  if (schedulerEventsLoaded.value) return;
  const { data } = await getFestivalSchedulerCalendar(props.festival.id);
  schedulerEvents.value = data;
  schedulerEventsLoaded.value = true;
};

const crew = ref([]);
const fetchCrewForFestivalSection = async (festivalSectionId) => {
  const index = getIndexFromArrayBasedOnId(festivalSectionId, crew);
  if (index === -1) {
    const { data } = await getFestivalSectionCrew(festivalSectionId);
    crew.value.push({
      id: festivalSectionId,
      crew: data,
    });
  }
};

const openSections = ref([]);

const venues = ref([]);
const availableVenues = ref([]);
const venuesLoaded = ref(false);
const fetchVenues = async () => {
  if (venuesLoaded.value) return;
  const { data } = await getFestivalSchedulerVenues(props.festival.id);
  venues.value = data;
  availableVenues.value = data
    .filter((v) => v.model !== 'venue' || props.event?.venue?.id === v.model_id)
    .map((v) =>
      Object.assign(v, { children: v.children.filter((c) => v.model !== 'room' || [].includes(c.model_id)) })
    );
  venuesLoaded.value = true;
};

const fetchAll = async () => {
  await fetchShifts();
  await fetchFestivalResource();
};
fetchAll();

const showShiftModal = ref(false);
const selectedShift = ref(null);
const selectedFestivalSection = ref(null);

const openCreateNewShiftForFestivalSection = async (festivalSection) => {
  if (!festivalSection.shift_follows_events) {
    useToast().info('Can only add shifts to sections where shift follows events.');
    return;
  }
  showShiftModal.value = false;
  openingUpShiftForm.value = true;
  await nextTick();
  const start = moment(props.event.start_date).startOf('day').add(12, 'hours').format(dateTimeFormat);
  const end = moment(props.event.start_date).startOf('day').add(14, 'hours').format(dateTimeFormat);
  await fetchCrewForFestivalSection(festivalSection.id);
  await fetchTimeSlots();
  await fetchSchedulerEvents();
  await fetchVenues();
  selectedShift.value = {
    id: null,
    title: '',
    description: '',
    write: false,
    in_timeline: false,
    approved: false,
    for_sale: false,
    shift_type_id: null,
    start,
    end,
    user_id: null,
    via_id: festivalSection.id,
    via_type: 'App\\Models\\Festivals\\FestivalSection',
    time_slot_id: null,
    place_id: null,
    place_type: '',
    place_string: '',
    count: 1,
    invited_by: usePage().props.auth.user.id,
    events: [],
    crew_slots_count: 0,
    initial_events: [{ id: props.event.id }],
  };
  selectedFestivalSection.value = festivalSection;
  showShiftModal.value = true;
  openingUpShiftForm.value = false;
};
const editShift = async (shift, festivalSection) => {
  if (!festivalSection.shift_follows_events) {
    useToast().info('Can only add shifts to sections where shift follows events.');
    return;
  }
  showShiftModal.value = false;
  openingUpShiftForm.value = true;
  await nextTick();
  await fetchCrewForFestivalSection(festivalSection.id);
  await fetchTimeSlots();
  await fetchSchedulerEvents();
  await fetchVenues();
  selectedShift.value = { ...shift, initial_events: [{ id: props.event.id }] };
  selectedFestivalSection.value = festivalSection;
  showShiftModal.value = true;
  openingUpShiftForm.value = false;
};

const selectedAssignShift = ref(null);
const showAssignShiftModal = ref(false);
const openAssignModal = async (shift, festivalSection) => {
  selectedAssignShift.value = null;
  selectedFestivalSection.value = null;
  showAssignShiftModal.value = false;
  await fetchCrewForFestivalSection(festivalSection.id);
  await fetchTimeSlots();
  await nextTick();
  selectedAssignShift.value = shift;
  showAssignShiftModal.value = true;
  selectedFestivalSection.value = festivalSection;
};

const updateSlotForSale = ({ value, index, shift_id }) => {
  const { shift_crew_slots } = shifts.value.find((s) => s.id === shift_id);
  if (shift_crew_slots) {
    shift_crew_slots[index].for_sale = value;
  } else {
    console.error('Error: updateing slot for sale');
  }
};

const clearAllSlots = (shiftId) => {
  const { shift_crew_slots } = shifts.value.find((sh) => sh.id === shiftId);
  if (shift_crew_slots?.length) {
    shift_crew_slots.forEach((s) => {
      s.slottable_id = null;
      s.slottable_type = null;
      s.slottable = null;
    });
  } else {
    useToast().warning('Error: no slots.');
  }
};

const slotCountUpdated = (shiftId, newSlotCount) => {
  const index = getIndexFromArrayBasedOnId(shiftId, shifts.value);
  if (index > -1) {
    updateCountOfSlotsExistingForShift(shifts.value[index], newSlotCount);
  }
};
const upForSaleCountUpdated = (shiftId, newUpForSaleCount) => {
  const index = getIndexFromArrayBasedOnId(shiftId, shifts.value);
  if (index > -1) {
    updateCountOfShiftUpForSaleForShift(shifts.value[index], newUpForSaleCount);
    if (shifts.value[index].shift_crew_slots.filter((slot) => slot.for_sale).length < newUpForSaleCount) {
      useToast().warning('Not enough shifts to mark as up for sale. Please create more.');
    }
  }
};

const slotAssigned = (data) => {
  const { shift_crew_slots } = shifts.value.find((shift) => shift.id === data.shiftId);
  if (shift_crew_slots) {
    const slot = shift_crew_slots.find((s) => data.slotId === s.id);
    if (slot) {
      slot.for_sale = false;
      slot.slottable_id = data.publicFormResponseId;
      slot.slottable_type = 'App\\Models\\PublicForms\\PublicFormResponse';
      slot.slottable = data.slottable;
    }
  }
};
const unAssignedSlot = (data) => {
  const index = getIndexFromArrayBasedOnId(data.shiftId, shifts.value);
  if (index > -1) {
    const slotIndex = shifts.value[index].shift_crew_slots.findIndex((slot) => slot.id === data.shiftCrewSlotId);
    if (slotIndex > -1) {
      shifts.value[index].shift_crew_slots[slotIndex].slottable_id = null;
      shifts.value[index].shift_crew_slots[slotIndex].slottable = null;
      shifts.value[index].shift_crew_slots[slotIndex].slottable_type = null;
    }
  }
};

useEmitStore().$subscribe((mutation, state) => {
  const payload = state.item?.payload;
  switch (state.item?.key) {
    case 'shiftCrewSlotsUpdated': {
      const shiftId = getKey(payload, 'id');
      const shiftCrewSlots = getKey(payload, 'shift_crew_slots');
      const index = getIndexFromArrayBasedOnId(shiftId, shifts.value);
      if (index > -1) {
        shifts.value[index].shift_crew_slots = shiftCrewSlots;
      }
      if (shiftId === selectedAssignShift.value.id) {
        selectedAssignShift.value.shift_crew_slot = shiftCrewSlots;
      }
      break;
    }
    default:
      break;
  }
});
</script>
<template>
  <div class="p-edge">
    <BoxContainer
      header-size="h2"
      :title="festival.name + ' - Shifts'">
      <div class="space-y-edge">
        <BoxContainer
          v-for="section in festivalSections"
          :key="section.id"
          :title="section.name"
          header-size="h3"
          :model-value="false"
          openable
          :actions="
            [
              !section.shift_follows_events
                ? {
                    title: 'Add Shift',
                    icon: 'fa-plus',
                    disabled: true,
                    toolTopText:
                      'Shifts cannot be added or edited for ' +
                      section.name +
                      ' because shifts does not follow events for it.',
                  }
                : null,
              section.shift_follows_events &&
              (canEdit || festivalResource?.permissions?.includes(canEditFestivalSectionsOnFestivalString))
                ? {
                    title: 'Add Shift',
                    icon: 'fa-plus',
                    action: () => {
                      openCreateNewShiftForFestivalSection(section);
                    },
                  }
                : null,
            ].filter((i) => i !== null)
          "
          :default-open="false">
          <template #title-inner>
            <div class="grid grid-cols-[550px_120px] items-center">
              <h3>{{ section.name }}</h3>
              <div class="float-right flex gap-edge [&>div]:w-[80px]">
                <div style="text-align: center">
                  <InputLabel
                    super-text
                    label="Crew" />
                  <DisplayBadge
                    :color="getCounterClass(section, true)"
                    size="small"
                    class="min-w-[80px]"
                    :text="
                      shiftsForSection(section).filter((s) => s.accepted_at !== null).length +
                      '/' +
                      shiftsForSection(section).length
                    " />
                </div>
                <div style="text-align: center">
                  <InputLabel
                    super-text
                    label="Responders" />
                  <DisplayBadge
                    :color="getCounterClass(section, false)"
                    class="min-w-[80px]"
                    size="small"
                    :text="
                      shiftsForSection(section)
                        .flatMap((s) => s.shift_crew_slots)
                        .filter((s) => s.accepted_at !== null && s.slottable !== null).length +
                      '/' +
                      shiftsForSection(section).flatMap((s) => s.shift_crew_slots).length
                    " />
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="shiftsForSection(section).length === 0"
            class="p-edge text-soft">
            No Shifts Added to {{ section.name }}
          </div>
          <VTable
            v-if="shiftsForSection(section).length > 0"
            sticky-header
            edge-to-edge>
            <template #head>
              <VTableRow head>
                <VTableCell style="width: 120px">When</VTableCell>
                <VTableCell>Where</VTableCell>
                <VTableCell>Assignee</VTableCell>
                <VTableCell>Title</VTableCell>
                <VTableCell
                  v-if="canEdit"
                  style="width: 50px" />
              </VTableRow>
            </template>
            <template v-for="shift in shiftsForSection(section)">
              <VTableRow>
                <VTableCell :rowspan="shift.shift_crew_slots.length + 1">
                  <div class="flex flex-col gap-edge">
                    <div
                      v-if="!timeStampsAreSame(event.start_date, shift.start)"
                      class="text">
                      {{ formatStampAsHumanReadableDate(shift.start, humanReadableDateFormatShort) }}
                    </div>
                    <div class="grid grid-cols-[auto_15px_1fr] items-center text-left">
                      <div>
                        <small
                          v-if="shift.check_in && shift.check_in !== shift.start"
                          class="mr-[64px]"
                          style="text-decoration: line-through">
                          {{ formatStampAsDate(shift.start, timeFormat) }}
                        </small>
                        <small>
                          {{ `${formatStampAsDate(shift.check_in ? shift.check_in : shift.start, timeFormat)}` }}
                        </small>
                      </div>
                      <small class="text-center"> - </small>
                      <div>
                        <small
                          v-if="shift.check_out && shift.check_out !== shift.end"
                          class="mr-[64px]"
                          style="text-decoration: line-through">
                          {{ formatStampAsDate(shift.end, timeFormat) }}
                        </small>
                        <small>
                          {{ `${formatStampAsDate(shift.check_out ? shift.check_out : shift.end, timeFormat)}` }}
                        </small>
                      </div>
                    </div>
                  </div>
                </VTableCell>

                <VTableCell :rowspan="shift.shift_crew_slots.length + 1">
                  <small>
                    {{ shift.place ? shift.place.name : shift.place_string }}
                  </small>
                </VTableCell>
                <VTableCell class="text-left">
                  <div class="flex items-center gap-edge-1/2">
                    <div>
                      <i
                        :class="'fa fa-fw ' + getIconForShift(shift)"
                        :title="getIconForShift(shift, true)" />
                    </div>
                    <small>
                      {{ shift.user ? shift.user.name : 'Not Assigned' }}
                    </small>
                  </div>
                </VTableCell>
                <VTableCell class="text-left">
                  <small>
                    {{ shift.title }}
                    <i
                      v-if="shift.description"
                      class="fa fa-fw fa-comment"
                      :title="shift.description" />
                  </small>
                </VTableCell>

                <VTableCell v-if="canEdit">
                  <VButton
                    v-if="section.shift_follows_events"
                    size="sm"
                    title="Edit"
                    icon="fa-pencil"
                    emphasized
                    @click="editShift(shift, section)" />
                </VTableCell>
              </VTableRow>
              <VTableRow
                v-for="slot in shift.shift_crew_slots"
                :key="shift.id + '_slot_' + slot.id">
                <!--              <VTableCell :colspan="1" />-->
                <VTableCell class="!pl-edge-1/2 text-left">
                  <div class="flex items-center gap-edge-1/2">
                    <div>
                      <i
                        :class="'fa fa-fw ' + getIconForSlot(slot)"
                        :title="getIconForSlot(slot, true)" />
                    </div>
                    <small>
                      {{ slot.slottable ? slot.slottable.name : ' - ' }}
                    </small>
                  </div>
                </VTableCell>
                <VTableCell class="text-left">
                  <small>
                    {{ slot.title }}
                    <i
                      v-if="slot.description"
                      class="fa fa-fw fa-comment"
                      :title="slot.description" />
                  </small>
                </VTableCell>

                <VTableCell v-if="canEdit">
                  <VButton
                    size="sm"
                    title="Assign"
                    @click="openAssignModal(shift, section)" />
                </VTableCell>
              </VTableRow>
            </template>
          </VTable>
        </BoxContainer>
      </div>

      <ShiftForm
        v-if="
          showShiftModal &&
          (canEdit || festivalResource?.permissions?.includes(canEditFestivalSectionsOnFestivalString)) &&
          selectedFestivalSection &&
          selectedShift &&
          selectedFestivalSection.shift_follows_events
        "
        :model="'Festival'"
        :model-id="festival.id"
        :inital-shift="selectedShift"
        :in-timeline="selectedFestivalSection?.timeline ?? false"
        :can-be-invited="selectedFestivalSection?.invite ?? false"
        :can-edit="canEdit || festivalResource?.permissions?.includes(canEditFestivalSectionsOnFestivalString)"
        :time-slots="timeSlots"
        :all-resources="getItemFromArrayBasedOnId(selectedFestivalSection?.id, crew, { crew: [] }).crew"
        :venues="availableVenues"
        :with-events="true"
        :has-crew-slots="true"
        :auto-approved="true"
        :with-event-ids="selectedFestivalSection?.shift_follows_events ?? false"
        :append-to-title="' for ' + selectedFestivalSection?.name"
        :multiple-events="false"
        :all-available-events="schedulerEvents"
        :show-event-selector="false"
        @show-assign-modal="[(showShiftModal = false), openAssignModal(selectedShift, selectedFestivalSection)]"
        @deleted="fetchShifts()"
        @updated="fetchShifts()"
        @created="fetchShifts()"
        @closed="showShiftModal = false" />

      <FestivalShiftAssignModal
        v-if="showAssignShiftModal && canEdit && selectedFestivalSection"
        :shift="selectedAssignShift"
        :festival-section="selectedFestivalSection"
        :venues="venues"
        :time-slots="timeSlots"
        :crew="crew"
        :with-button="false"
        :can-assign="canEdit"
        :can-check-slots="canEdit"
        :write="canEdit"
        :crew-resources="
          getItemFromArrayBasedOnId(selectedFestivalSection?.id, crew, { crew: [] }).crew.filter(
            (c) => c.model === 'user'
          )
        "
        @for-sale="updateSlotForSale"
        @clear-all-slots="clearAllSlots"
        @updated-shift="exchangeValuesOfObject($event, shifts, ['id'], 'id', false)"
        @updated="exchangeValuesOfObject($event, shifts, ['id'], 'id', false)"
        @checked-in="exchangeValuesOfObject($event, shifts, ['id'], 'id', false)"
        @checked-out="exchangeValuesOfObject($event, shifts, ['id'], 'id', false)"
        @clear-check-in-and-out="exchangeValuesOfObject($event, shifts, ['id'], 'id', false)"
        @up-for-sale-count-updated="upForSaleCountUpdated"
        @slot-count-updated="slotCountUpdated"
        @assigned-to-slot="slotAssigned"
        @un-assigned-slot="unAssignedSlot" />
    </BoxContainer>
  </div>
</template>
