<script lang="ts" setup>
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { computed, ref } from 'vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import { getKey } from '@/util/globals';
import VButton from '@/components/Inputs/VButton.vue';

const props = withDefaults(
  defineProps<{
    options: string[] | object[] | number[];
    overrideWidth?: number | null;
    nullable?: boolean;
    multiple?: boolean;
    groups?: boolean;
    withFiltering?: boolean;
    buttonText?: string;
    selectedText?: string | number | null;
    icon?: string | null;
    toolTipText?: string | null;
    loading?: boolean;
  }>(),
  {
    overrideWidth: null,
    toolTipText: null,
    icon: null,
    buttonText: '',
    selectedText: 'All',
    multiple: false,
    groups: false,
    withFiltering: false,
    loading: false,
  }
);

const isOpen = ref(false);
const searchTerm = ref('');
const filterOptions = computed(() => {
  if (!props.withFiltering) {
    return props.options;
  }
  return props.options.filter((o) => {
    if (getKey(o, 'type') !== null) return searchTerm.value.length === 0 || getKey(o, 'type') === 'header';
    return searchTerm.value.length === 0
      ? true
      : getKey(o, 'title', '').toLowerCase().includes(searchTerm.value.toLowerCase());
  });
});
</script>

<template>
  <div class="text">
    <VDropdown
      :close-on-click="!multiple"
      :items="filterOptions"
      :have-max-width="false"
      with-arrows-up-and-down
      :highlight-text="searchTerm"
      @dropdown-opened="isOpen = true"
      @dropdown-closed="isOpen = false">
      <template
        v-if="withFiltering"
        #aboveDropdown>
        <TextInput
          v-model="searchTerm"
          placeholder="Search"
          set-focus
          square />
      </template>

      <template #click-area>
        <VButton
          :tool-tip-text="toolTipText"
          :emphasized="false"
          :loading="loading"
          :title="`${buttonText} ${selectedText}`"
          post-icon="fa-chevron-down"
          :icon="icon" />
      </template>
    </VDropdown>
  </div>
</template>
