<script setup lang="ts">
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { ref, watch } from 'vue';
import VButton from '@/components/Inputs/VButton.vue';

const { modelValue, isTemplate, type, loading, disabled, titleHighlight, update, goToTemplate } = defineProps<{
  modelValue: boolean;
  isTemplate?: boolean | null;
  type: string;
  loading: boolean;
  disabled: boolean;
  titleHighlight?: string;
  update: boolean;
  goToTemplate?: () => void;
}>();

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'update:modelValue', arg: boolean): void;
}>();

const modes = [
  {
    value: 'template',
    name: 'From Template',
  },
  {
    value: 'scratch',
    name: 'Blank',
  },
] as const;

const currentTab = ref(isTemplate || update ? 'scratch' : 'template');

watch(currentTab, () => {
  emit('update:modelValue', currentTab.value === 'template');
});
</script>

<template>
  <CrudModal
    :update="update"
    :loading="loading"
    :title-highlight="titleHighlight"
    :disabled="disabled"
    :title="update ? 'Update ' + type : 'Create ' + type"
    @closed="$emit('closed')">
    <div>
      <ModeSelector
        v-if="!isTemplate && !update"
        v-model="currentTab"
        class="-mx-edge mb-edge-1/2 border-b px-edge"
        :modes="modes" />

      <slot />
    </div>

    <template
      v-if="goToTemplate"
      #area-in-footer-for-buttons>
      <div>
        <VButton
          :title="update ? 'Edit Template' : 'Edit Templates'"
          :emphasized="true"
          size="lg"
          icon="fa-cog"
          @click="goToTemplate()" />
      </div>
    </template>
  </CrudModal>
</template>
