import { useGuestListSettingsStore } from '@/store/guest-list-settings-store';
import { computed, provide, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { formatModel } from '@/util/store-helpers';
import type { ModelType } from '@/types/global';
import { guestListSettingKey } from '@/provide/keys';
import { GuestFieldResource, GuestListSettingAccessResource } from '@/types/festival';

export const useGuestListSettings = (
  model: ModelType,
  modelId: number,
  shouldProvide = true,
  canView = true,
  performanceId: number | null = null
) => {
  const { list, loading } = storeToRefs(useGuestListSettingsStore());
  const { fetchGuestListSettings, exchangeGuestListSettingFields, exchangeGuestListSettingAccesses } =
    useGuestListSettingsStore();

  const loaded = ref(false);
  const guestListSettings = computed(() => {
    const item = list.value.get(formatModel(model, modelId));
    if (item) {
      return item[0];
    }
    return null;
  });

  const fetch = async (force = false) => {
    if (!canView) return;
    if (force || !list.value.has(formatModel(model, modelId))) {
      await fetchGuestListSettings(model, modelId, performanceId);
    }
    loaded.value = true;
  };

  const flushForModel = () => {
    list.value.delete(formatModel(model, modelId));
  };

  const exchangeGuestFields = (guestFields: GuestFieldResource[]) => {
    exchangeGuestListSettingFields(model, modelId, performanceId, guestFields);
  };
  const exchangeAccesses = (guestListSettingAccesses: GuestListSettingAccessResource[]) => {
    exchangeGuestListSettingAccesses(model, modelId, performanceId, guestListSettingAccesses);
  };

  if (shouldProvide) {
    provide(guestListSettingKey, {
      canView,
      loaded,
      loading,
      exchangeGuestFields,
      exchangeAccesses,
      guestListSetting: guestListSettings,
      getGuestListSetting: async (force) => {
        await fetch(force);
      },
    });
  }
  return {
    guestListSettings,
    loading,
    loaded,
    fetch,
    flushForModel,
    exchangeGuestFields,
    exchangeAccesses,
  };
};
