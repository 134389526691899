import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export type EmitReFetchPendingEventRequests = 'pending-event-requests-update';
export type EmitUpdateAssigmentIntermediateStep = 'update-assignment-intermediate-step';
export type EmitFestivalSectionCalendarUpdate = 'festival-section-calendar-update';
export type EmitReFetchEventsMembers = 'refetch-events-members';
export type EmitOpenInviteOnDashboard = 'open-invite-on-dashboard';
export type EmitFetchFiltersDashboard = 'fetch-filters-dashboard';
export type EmitEventTypeForInviteUpdate = 'event-type-for-invite-updated';
export type EmitStatusBarNumberUpdate = 'status-bar-number-updated';
export type EmitRoomStructureChanged = 'room-structure-changed';
export type EmitRefetchRoomsCalendar = 'refetch-rooms-calendar';
export type EmitGroupMemberStructureUpdated = 'group-member-structure-updated';
export type EmitPerformanceUpdate = 'performance-update';
export type EmitReFetchGroupDashboard = 'group-dashboard-re-fetch';
export type EmitInviteChanged = 'invite-changed';
export type EmitShiftsChanged = 'shifts-changed';
export type EmitFormAddedToEvent = 'form-added-to-event';
export type EmitRunningOrderAddedToEvent = 'runningorder-added-to-event';
export type EmitRoomRemovedFromEvent = 'room-removed-from-event';
export type EmitRoomUpdateOnEvent = 'room-update-on-event';
export type EmitRoomAddedToEvent = 'room-added-to-event';
export type EmitCheckListAddedToEvent = 'check-list-added-to-event-via-group-add-to-event';
export type EmitInvoiceBasisAddedToEvent = 'invoice-basis-added-tp-event-via-group-add-to-event';
export type EmitFolderAddedToEvent = 'folder-added-to-event';
export type EmitFolderRemovedFromEvent = 'folder-removed-from-event';
export type EmitBoardAddedToEvent = 'board-added-to-event';
export type EmitPerformanceCreate = 'performance-create';
export type EmitPublicFormResponsesAddedToFestivalSectionAndTimeSlot =
  'publicForm_response_added_to_festivalSection_and_timeSlot';
export type EmitPublicFormResponsesAddedToFestival = 'publicFormResponse_added_to_festivalSection';
export type EmitAssignmentsAddedToEvent = 'assignments_added_to_event';
export type EmitShiftCrewSlotsUpdated = 'shiftCrewSlotsUpdated';
export type EmitCloseAllDropDowns = 'close-all-drop-downs';
export type EmitReFetchDocumentCenter = 're-fetch-document-center';
export type EmitAdminCountChanged = 'admin-count-changed';
export type EmitEventCountChanged = 'event-count-changed';
export type EmitChangeEventCount = 'change-event-count';
export type EmitTaskCreated = 'task-created';
export type EmitTaskChanged = 'task-changed';
export type EmitTaskDeleted = 'task-deleted';
export type EmitOpenEventCreateForGroup = 'open-event-create-on-dashboard-for-group';
export type EmitTemplateGalleryImport = 'template-gallery-import';
export type EmitCloseDropdownHover = 'close-other-dropdowns-on-hover';
export type EmitPartnersChangedOnInvite = 'partners-changed-on-invite';
export type EmitPerformancesChangedOnEvent = 'performances-changed-on-event';
export type EmitInviteRespondedTo = 'invite-responded-to';
export type EmitMetaDataFieldValueUpdated = 'meta-data-field-value-updated';
export type EmitPerformanceInfoFieldValueUpdated = 'performance-info-field-value-updated';
export type EmitNavbarTabListChanged = 'navbar-tab-list-changed';

export type EmitKey =
  | EmitPerformanceUpdate
  | EmitUpdateAssigmentIntermediateStep
  | EmitReFetchEventsMembers
  | EmitOpenInviteOnDashboard
  | EmitReFetchGroupDashboard
  | EmitFetchFiltersDashboard
  | EmitEventTypeForInviteUpdate
  | EmitStatusBarNumberUpdate
  | EmitRoomStructureChanged
  | EmitRefetchRoomsCalendar
  | EmitGroupMemberStructureUpdated
  | EmitPerformanceCreate
  | EmitFolderAddedToEvent
  | EmitRunningOrderAddedToEvent
  | EmitCheckListAddedToEvent
  | EmitInviteChanged
  | EmitShiftsChanged
  | EmitRoomRemovedFromEvent
  | EmitRoomAddedToEvent
  | EmitRoomUpdateOnEvent
  | EmitFolderRemovedFromEvent
  | EmitFormAddedToEvent
  | EmitBoardAddedToEvent
  | EmitPublicFormResponsesAddedToFestivalSectionAndTimeSlot
  | EmitPublicFormResponsesAddedToFestival
  | EmitReFetchPendingEventRequests
  | EmitShiftCrewSlotsUpdated
  | EmitAssignmentsAddedToEvent
  | EmitFestivalSectionCalendarUpdate
  | EmitCloseAllDropDowns
  | EmitReFetchDocumentCenter
  | EmitInvoiceBasisAddedToEvent
  | EmitAdminCountChanged
  | EmitEventCountChanged
  | EmitTaskCreated
  | EmitTaskChanged
  | EmitOpenEventCreateForGroup
  | EmitTaskDeleted
  | EmitCloseDropdownHover
  | EmitTemplateGalleryImport
  | EmitPartnersChangedOnInvite
  | EmitPerformancesChangedOnEvent
  | EmitInviteRespondedTo
  | EmitMetaDataFieldValueUpdated
  | EmitPerformanceInfoFieldValueUpdated
  | EmitNavbarTabListChanged
  | EmitChangeEventCount;

type EmitData = {
  key: EmitKey;
  payload?: any;
};

export const useEmitStore = defineStore('emit-store', () => {
  const item = ref<EmitData | null>(null);

  const rootEmit = (event: EmitKey, payload?: any) => {
    item.value = { key: event, payload };
  };

  return {
    item,
    rootEmit,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEmitStore, import.meta.hot));
}
