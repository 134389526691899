import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { partnerTypesCompaniesKey, partnerTypesContactsKey } from '@/provide/keys';
import { formatModel } from '@/util/store-helpers';
import { usePartnerTypesStore } from '@/store/PartnerTypesStore';
import { ModelType, PartnerTypesResource } from '@/types/global';

export const usePartnerTypes = (
  modelType: ModelType,
  modelId: number,
  type: string,
  shouldProvide = true,
  performanceId: number | null = null
) => {
  const { list, loading } = storeToRefs(usePartnerTypesStore());
  const { fetchPartnerTypes: fetch, addOrUpdate, removeById } = usePartnerTypesStore();

  const partnerTypes = computed(() => list.value.get(formatModel(modelType, modelId, type)) ?? []);

  const fetchPartnerTypes = async (force?: false) => {
    await fetch(modelType, modelId, type, force ?? false, performanceId);
  };

  const addOrUpdateType = async (partnerType: PartnerTypesResource) => {
    await addOrUpdate(modelType, modelId, type, partnerType);
  };

  const removeType = async (partnerTypeId: number) => {
    await removeById(modelType, modelId, type, partnerTypeId);
  };

  if (shouldProvide) {
    provide(type === 'App\\PartnerContact' ? partnerTypesContactsKey : partnerTypesCompaniesKey, {
      partnerTypes: readonly(partnerTypes),
      loading: readonly(loading),
      fetchPartnerTypes,
      addOrUpdateType,
      removeType,
    });
  }

  return {
    partnerTypes,
    fetchPartnerTypes,
    loading,
    addOrUpdateType,
    removeType,
  };
};
