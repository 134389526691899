<script lang="ts" setup>
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { usePageTabsStore } from '@/store/PageTabsStore';
import { router } from '@inertiajs/vue3';
import { computed, onMounted, ref, watch } from 'vue';
import { CompletionTrackerNames } from '@/types/completion-tracker';
import { completionTrackerHasItem } from '@/util/completion-tracker-functions';
import { slugify } from '@/util/string-utils';
import { tooltip as toolTip } from '@/util/tooltip';
import { useUserStore } from '@/store/UserStore';

type Props = {
  itemId: string;
  title: string;
  route?: string;
  hash: string;
  icon: string;
  completionTrackerName?: CompletionTrackerNames | null;
  mainMenu?: boolean | null;
  fullName?: boolean;
  clickAction?: () => void;
  action?: () => void;
  tooltip?: boolean;
  missingInfo?: boolean;
  missingInfoTooltip?: string;
};

const props = withDefaults(defineProps<Props>(), {
  mainMenu: false,
  completionTrackerName: null,
  route: undefined,
  fullName: false,
  clickAction: undefined,
  action: undefined,
  tooltip: true,
  missingInfo: false,
  missingInfoTooltip: '',
});

const emit = defineEmits<{
  (e: 'openList'): void;
}>();

const contextSidebarStore = useContextSidebarStore();

const userStore = useUserStore();

const active = computed(() => props.itemId === contextSidebarStore.currentView);

watch(
  active,
  (newValue) => {
    if (newValue) {
      contextSidebarStore.setCurrentSelectedSidebarItem({
        id: props.itemId,
        title: props.title,
      });
      emit('openList');
    }
  },
  { immediate: true }
);

const onClick = (event: MouseEvent) => {
  const route = window.location.origin + window.location.pathname;

  if (props.action) {
    props.action();
  }
  if (!props.route) {
    return;
  }

  if (event.ctrlKey || event.metaKey) {
    let newUrl = props.route;
    if (props.hash) {
      newUrl = `${props.route}#${props.hash}`;
    }
    window.open(newUrl);
  } else if (route === props.route) {
    let newUrl = props.route;
    if (props.hash) {
      newUrl = `${props.route}#${props.hash}`;
    }
    if (newUrl === window.location.href) {
      const tabsStore = usePageTabsStore();
      tabsStore.setActiveTab(tabsStore.tabs[0]);
      return;
    } else if (props.route === window.location.origin + window.location.pathname) {
      const tabsStore = usePageTabsStore();
      tabsStore.setActiveTab(tabsStore.tabs[0]);
    }
    contextSidebarStore.currentView = props.itemId;
    window.location.hash = props.hash;
  } else {
    let url = `${props.route}`;
    if (props.hash) {
      url += `#${props.hash}`;
    }
    try {
      router.visit(url);
    } catch (e) {
      console.error(e);
      window.location = url;
    }
  }
};

const wrapper = ref<HTMLDivElement | null>(null);

const isOpen = ref(contextSidebarStore.sidebarLarge);

if (props.tooltip) {
  onMounted(() => {
    watch(
      () => contextSidebarStore.sidebarLarge,
      (v) => {
        isOpen.value = v;
        toolTip(wrapper.value, props.title, 'right', 10, isOpen);
      },
      {
        immediate: true,
      }
    );
  });
}
</script>

<template>
  <div
    ref="wrapper"
    :class="(active ? ' ' : '') + (contextSidebarStore.sidebarLarge ? ' ' : ' !pl-edge-1/4')"
    :data-intro="`${itemId}`"
    class="h-[1.6785rem] cursor-pointer truncate px-edge-1/2"
    :dusk="`context-sidebar-list-item-${slugify(title)}`"
    @click="onClick">
    <div
      class="flex h-full items-center gap-edge-1/2 rounded hover:bg-[--color-background-context-sidebar-item-hover]"
      :class="[
        active
          ? 'bg-[--color-background-context-sidebar-item-active] font-mediumbold text-sidebar'
          : 'text-sidebar-soft',
        contextSidebarStore.sidebarLarge ? 'pl-edge-1/2' : 'items-center justify-center',
        !contextSidebarStore.sidebarLarge && !props.tooltip ? '-ml-edge-1/4 pl-edge' : '',
      ]">
      <i
        :class="[icon, active || !contextSidebarStore.sidebarLarge ? 'text' : 'text-soft']"
        class="fa fa-fw mt-1 w-[15px] text-sm" />
      <div
        v-if="contextSidebarStore.sidebarLarge || fullName"
        :class="[{ 'w-[0px]': !contextSidebarStore.sidebarLarge }]"
        class="flex-1 truncate pr-edge">
        {{ title }}
      </div>

      <span
        v-if="
          completionTrackerHasItem(contextSidebarStore.completionTracker, completionTrackerName) &&
          contextSidebarStore.sidebarLarge
        "
        :title="
          completionTrackerHasItem(contextSidebarStore.completionTracker, completionTrackerName)?.item.description
        "
        class="pr-edge-1/2">
        <i
          class="fa fa-fw fa-exclamation-circle fa-regular text-sm"
          :class="userStore.theme === 'base' ? '!text-pending' : '!text'" />
      </span>

      <span
        v-else-if="missingInfo"
        :title="missingInfoTooltip"
        class="pr-edge-1/2">
        <i
          class="fa fa-fw fa-exclamation-circle fa-regular text-sm"
          :class="userStore.theme === 'base' ? '!text-pending' : '!text'" />
      </span>
    </div>
  </div>
</template>
