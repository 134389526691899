import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';
import { FestivalSectionResource } from '@/types/festival-section';

export const useFestivalSectionsStore = defineStore('festival-sections', () => {
  const list = ref(new Map());
  const loading = ref(false);

  const fetchFestivalFestivalSections = async (festivalId: number) => {
    if (loading.value) return;
    loading.value = true;
    const { data } = await axios.get('/api/festival-sections/', {
      params: {
        festival_id: festivalId,
        with_users: true,
        with_users_count: true,
        with_public_forms: true,
        with_dates: true,
      },
    });
    list.value.set(festivalId, data);
    loading.value = false;
  };

  const getForFestival = (festivalId: number, force = false) => {
    if (force) {
      fetchFestivalFestivalSections(festivalId);
    } else if (!list.value.has(festivalId)) {
      list.value.set(festivalId, []);
      fetchFestivalFestivalSections(festivalId);
      // TODO: listen for broadcast
    }
    return list.value.get(festivalId);
  };

  const addOrUpdateFestivalSection = async (festivalId: number, festivalSection: FestivalSectionResource) => {
    if (!list.value.has(festivalId)) {
      await getForFestival(festivalId);
      return;
    }
    let data = list.value.get(festivalId);
    data = exchangeValuesOfObject(festivalSection, data);
    list.value.set(festivalId, data);
  };

  const removeFestivalSection = async (festivalId: number, festivalSectionId: number) => {
    if (!list.value.has(festivalId)) {
      await getForFestival(festivalId);
      return;
    }
    const data = list.value.get(festivalId);
    const index = getIndexFromArrayBasedOnId(festivalSectionId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(festivalId, data);
    } else {
      await getForFestival(festivalId);
    }
  };

  const flushForFestival = (festivalId: number) => {
    list.value.delete(festivalId);
  };

  return {
    list,
    loading,
    getForFestival,
    flushForFestival,
    addOrUpdateFestivalSection,
    removeFestivalSection,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFestivalSectionsStore, import.meta.hot));
}
