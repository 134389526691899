<script setup lang="ts">
import CrudModal from '@/components/Modals/CrudModal.vue';
import { EventTypeResource } from '@/types/event-request';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import VTimePicker from '@/components/Inputs/Date/VTimePicker.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import type { CheckListResource } from '@/types/check-list';
import VRadioList from '@/components/Inputs/VRadioList.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { useEventTypes } from '@/composables/use-event-types';
import { EventTypeModelTypes } from '@/components/Config/EventTypes/EventTypeSlideOut.vue';

type Props = {
  modelType: EventTypeModelTypes;
  modelId: number;
  eventType: EventTypeResource;
  checkList: CheckListResource;
  loading: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:loading': boolean;
}>();

const toast = useToast();

const modalOpen = ref(true);
const checkListStart = ref<string | null>(null);
const { fetch: fetchEventTypes } = useEventTypes(props.modelType, props.modelId, false);

const addToAllFuture = ref(true);

const attachOfCheckList = async () => {
  if (!props.checkList || !props.checkList.id) return;
  if (checkListStart.value !== null && checkListStart.value?.length !== 5) {
    toast.warning('Start is not correctly formatted.');
    return;
  }
  emit('update:loading', true);
  await axios.post(`/api/event-types/${props.eventType.id}/pivot/check-lists`, {
    check_list_id: props.checkList.id,
    attach: true,
    start: checkListStart.value,
    add_to_all_future_invites: addToAllFuture.value,
  });

  await fetchEventTypes(true);

  emit('update:loading', false);

  if (addToAllFuture.value) {
    toast.success(`Added ${props.checkList.title} to ${props.eventType.name}`);
  } else {
    toast.success(`${props.checkList.title} added`);
  }
  modalOpen.value = false;
};
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    create-button-text="Attach"
    small
    title="Attach"
    @closed="modalOpen = false"
    @create="attachOfCheckList">
    <div class="p-edge">
      Do you want to add {{ checkList.title }} to new events created only or previously created future events as well?
      <VRadioList
        v-model="addToAllFuture"
        class="mt-edge"
        :options="[
          { label: 'All Future Events', value: true },
          { label: 'New Events Only', value: false },
        ]" />
    </div>

    <BoxContainer
      title="Set time"
      header-size="h3">
      <div class="text-soft">When do you want the check list to be added?</div>
      <div class="grid grid-cols-2">
        <SettingToggle
          :model-value="checkListStart !== null"
          label="Set Start"
          label-title="Would you like to set a start for this check list?"
          @update:model-value="checkListStart === null ? (checkListStart = '12:00') : (checkListStart = null)" />
        <VTimePicker
          v-if="checkListStart"
          v-model="checkListStart" />
        <VTimePicker
          v-else
          :model-value="null"
          :can-edit="false" />
      </div>
    </BoxContainer>
  </CrudModal>
</template>
