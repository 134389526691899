import { router } from '@inertiajs/vue3';
import { storeToRefs } from 'pinia';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { useUserStore } from '@/store/UserStore';

export const getRoute = (page: string, options: any = null): string => window.route(page, options);

export const openRoute = (route: string, event: MouseEvent | null = null, inNewTab = false): void => {
  const { isControlDown } = storeToRefs(useUserStore());
  if ((event && event.ctrlKey) || inNewTab || isControlDown.value) {
    window.open(route);
    return;
  }
  router.visit(route);
};

export const openExternalPage = (url: string, event: MouseEvent | null = null, inNewTab = false): void => {
  if ((event && event.ctrlKey) || inNewTab) {
    window.open(url);
    return;
  }
  router.visit(url);
};
export const goBack = (): void => {
  window.history.back();
};

export const reloadPage = (): void => {
  location.reload();
};

export const visitRoute = (route: string, options?: { hash?: string; slug?: string }) => {
  const { currentView, currentContext } = storeToRefs(useContextSidebarStore());

  let newRoute;

  if (options?.slug) {
    newRoute = getRoute(route, options.slug);
  } else {
    newRoute = getRoute(route);
  }

  const oldRoute = window.location.origin + window.location.pathname;
  if (newRoute === oldRoute) {
    if (options?.hash) {
      window.location.hash = options.hash;
      currentView.value = `${currentContext.value}_${options.hash}`;
    } else {
      window.location.hash = '';
      currentView.value = `${currentContext.value}`;
    }
  } else {
    router.visit(!options?.hash ? newRoute : `${newRoute}#${options.hash}`);
  }
};
