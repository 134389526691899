import { MetaDataAccessResource } from '@/types/meta-data';

export const fieldVisible = (
  metaDataAccesses: MetaDataAccessResource[],
  metaDataId: number,
  metaDataFieldId: number,
  sharedToGroupId: null | number
) => {
  return (
    metaDataAccesses.filter(
      (metaDataAccess) =>
        metaDataId === metaDataAccess.meta_data_id &&
        metaDataFieldId === metaDataAccess.meta_data_field_id &&
        sharedToGroupId === metaDataAccess.shared_to_group_id
    ).length > 0
  );
};
export const fieldEditable = (
  metaDataAccesses: MetaDataAccessResource[],
  metaDataId: number,
  metaDataFieldId: number,
  sharedToGroupId: null | number
) => {
  return (
    metaDataAccesses.filter(
      (metaDataAccess) =>
        metaDataId === metaDataAccess.meta_data_id &&
        metaDataFieldId === metaDataAccess.meta_data_field_id &&
        sharedToGroupId === metaDataAccess.shared_to_group_id &&
        metaDataAccess.editable
    ).length > 0
  );
};

export const metaDataVisible = (
  metaDataAccesses: MetaDataAccessResource[],
  metaDataId: number,
  sharedToGroupId: null | number
) => {
  return (
    metaDataAccesses.filter(
      (metaDataAccess) =>
        metaDataId === metaDataAccess.meta_data_id &&
        metaDataAccess.meta_data_field_id === null &&
        sharedToGroupId === metaDataAccess.shared_to_group_id
    ).length > 0
  );
};

export const metaDataEditable = (
  metaDataAccesses: MetaDataAccessResource[],
  metaDataId: number,
  sharedToGroupId: null | number
) => {
  return (
    metaDataAccesses.filter(
      (metaDataAccess) =>
        metaDataId === metaDataAccess.meta_data_id &&
        metaDataAccess.meta_data_field_id === null &&
        sharedToGroupId === metaDataAccess.shared_to_group_id &&
        metaDataAccess.editable
    ).length > 0
  );
};
