import { getTimelineTemplateEvents, postTimelineTemplateEvent } from '@/services/api-timeline-templates';
import { EventMinimalResource } from '@/types/event';
import { TimelineTemplateResource } from '@/types/timeline-template';
import { useToast } from 'vue-toastification';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { ShowTimeResource } from '@/types/show-time';

export const addTimelineTemplateToEvent = async (
  template: TimelineTemplateResource,
  event: EventMinimalResource,
  withToast = true,
  alreadyCertain = false,
  showTime: ShowTimeResource
) => {
  const { data: earlierUses } = await getTimelineTemplateEvents(template.id, event.id);

  let text = `Are you sure you want to add ${template.title} to ${event.name}${showTime ? ' on ' + showTime.title : ''} ?`;

  if (earlierUses > 0) {
    text = `${text} It has already been added ${earlierUses} time(s) before.`;
  }

  if (!alreadyCertain || earlierUses > 0) {
    const certain = await useCertaintyModal().assertCertain(`Add ${template.title}`, text);
    if (!certain) return false;
  }

  await postTimelineTemplateEvent(template.id, event.id, true, showTime?.id);

  if (withToast) {
    const toast = useToast();
    toast.success('Added');
  }

  return true;
};
