<script setup lang="ts">
import { computed, reactive, ref, watchEffect } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { getDiffInInterval } from '@/util/timeFunctions';
import VButton from '@/components/Inputs/VButton.vue';
import { getGroupUsage, GroupUsage } from '@/services/api-group';
import { useEmitStore } from '@/store/EmitStore';
import { getKey } from '@/util/globals';
import BaseModal from '@/components/Base/BaseModal.vue';
import { getLockPageItem } from './unlock-features-helper';
import { sendAnErrorToSlack } from '@/util/error-handler';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VDropdown from '../Inputs/Dropdown/VDropdown.vue';

const isModalOpen = ref(false);

const page = usePage();
const userGroups = page.props.auth.user.groups;
const contextStore = useContextSidebarStore();

const getViewedGroup = () => {
  if (contextStore.currentContextType === 'personal' && contextStore.currentView?.includes('group')) {
    const groupId = contextStore.currentView.split('_')[1];

    return userGroups.find((userGroup) => userGroup.id == groupId);
  }

  if (contextStore.currentContextType === 'group') {
    return userGroups.find((userGroup) => userGroup.id === contextStore.currentContextId);
  }

  return null;
};

const shouldShow = computed(() => {
  let group = getViewedGroup();

  // Not member of the group, or we are not in a group.
  if (!group) {
    return false;
  }

  // Self-onboarded free group
  if (group.is_free) {
    return 'free';
  }

  // Group is currently on trial
  if (group.trial_ends_at != null) {
    return 'trial';
  }

  // Group probably has a subscription
  return false;
});

const trialDaysRemaining = computed(() => {
  const g = getViewedGroup();

  const group = userGroups.find((userGroup) => {
    return userGroup.id === g.id;
  });

  if (!group) {
    return 0;
  }

  return getDiffInInterval(new Date(), group.trial_ends_at, 'days');
});

const showDropdown = ref(false);
const buttonObject = ref(null);
const buttonContainer = ref(null);

const dropdownStyle = reactive({
  y: 0,
  x: 0,
});

const updateDropdownPosition = () => {
  if (!buttonContainer.value) return;
  const rect = buttonContainer?.value?.getBoundingClientRect();
  if (!rect) {
    return;
  }

  dropdownStyle.y = rect.bottom;
  dropdownStyle.x = rect.right - 250;
};

watchEffect(() => {
  if (showDropdown.value) {
    updateDropdownPosition();
  }
});

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

window.addEventListener('resize', updateDropdownPosition);

const momentOfLoading = ref(false);
[50, 100, 150, 250, 300, 350, 400, 460, 500, 600, 760].map((e) => {
  setTimeout(() => {
    if (!momentOfLoading.value) {
      const item = document.getElementById('before-navbar');
      if (item) {
        momentOfLoading.value = true;
      }
    }
  }, e);
});

const usage = ref<GroupUsage | null>(null);

const refreshUsageCount = () => {
  const group = getViewedGroup();

  if (!group) {
    usage.value = null;
    return;
  }

  getGroupUsage(group?.id).then((res) => {
    usage.value = res.data;
  });
};

const talkToSales = () => {
  isModalOpen.value = false;

  sendAnErrorToSlack(
    'User Wants to talk to sales about upgrading group with ' + lockPageItem.key,
    ' <@U5KFQVBU5> <@U05USSDMAJG>',
    'https://hooks.slack.com/services/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'
  );
  window.open('https://meet.crescat.io/talk-to-sales-enterprise');
};

watchEffect(() => {
  if (shouldShow.value && getViewedGroup != null) {
    refreshUsageCount();
  }
});

useEmitStore().$subscribe((mutation, state) => {
  if (state.item?.key === 'admin-count-changed' || state.item?.key === 'event-count-changed') {
    refreshUsageCount();
  }
  if (state.item?.key === 'change-event-count') {
    const allowed = getKey(state.item.payload, 'allowed');
    const created = getKey(state.item.payload, 'created');
    if (!allowed || !created || !usage.value) {
      refreshUsageCount();
    } else {
      usage.value.events_allowed_count = allowed;
      usage.value.events_used_count = created;
    }
  }
});

refreshUsageCount();

const lockPageItem = getLockPageItem({
  title: 'Group',
  key: 'group',
});

const getTitleOfButton = () => {
  switch (shouldShow.value) {
    case 'trial': {
      if (trialDaysRemaining.value > 0) {
        return trialDaysRemaining.value + ' Days Left of Your Free Trial';
      }
      return 'Your trial has expired';
    }
    case 'free': {
      return 'Free Group';
    }
    default: {
      return null;
    }
  }
};

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <VDropdown v-if="shouldShow">
    <template #click-area>
      <VButton
        post-icon="fa-chevron-down"
        type="pending"
        :emphasized="true"
        icon="fa-star-shooting">
        <template #title>
          <span v-if="shouldShow == 'trial'">
            <span v-if="trialDaysRemaining > 0">{{ trialDaysRemaining }} Days Left of Your Free Trial</span>
            <span v-else>Your trial has expired</span>
          </span>
          <span v-if="shouldShow == 'free'">
            <span>Free Plan</span>
          </span>
        </template>
      </VButton>
    </template>
    <template #dropdown>
      <div class="divide-y">
        <div class="p-edge">
          <div class="grid grid-cols-2 gap-edge">
            <div class="flex flex-col">
              <InputLabel
                super-text
                label="Events" />
              <span class="flex gap-edge-1/4 text-base font-mediumbold text">
                <span
                  v-if="usage?.unlimited_events"
                  class="flex items-baseline gap-edge-1/4 whitespace-nowrap">
                  <span class="inline-block">{{ usage?.events_used_count }}</span>
                  <span class="inline-block"> / </span>
                  <i class="fa fa-infinity fa-sm inline-block"></i>
                </span>
                <span
                  v-else
                  class="whitespace-nowrap">
                  {{ usage?.events_used_count }} /
                  {{ usage?.events_allowed_count }}
                </span>
                <span class="ml-1">Events</span>
              </span>
            </div>
            <div class="flex flex-col">
              <InputLabel
                super-text
                label="Admins" />
              <span class="flex gap-edge-1/4 text-base font-mediumbold text">
                <span class="flex items-baseline gap-edge-1/4 whitespace-nowrap">
                  <span class="inline-block">{{ usage?.admins_used_count }}</span>
                  <span class="inline-block"> / </span>
                  <span class="inline-block">{{ usage?.admins_allowed_count }}</span>
                </span>
                <span class="ml-1">Seats</span>
              </span>
            </div>

            <div class="flex flex-col items-start justify-center">
              <VButton
                type="primary"
                size="sm"
                emphasized
                title="Upgrade"
                @click="[(isModalOpen = true), (showDropdown = false)]" />
            </div>
            <div class="flex flex-col items-start justify-center">
              <a
                href="https://meet.crescat.io/talk-to-sales-enterprise"
                target="_blank"
                @click="[(showDropdown = false)]">
                <VButton
                  size="sm"
                  type="default"
                  title="Talk to Sales" />
              </a>
            </div>
          </div>
        </div>
        <div class="p-edge-1/2">
          <p class="text-balance text-center text-sm text-soft">
            Unlock exclusive features by upgrading to Crescat Premium
          </p>
        </div>
      </div>
    </template>
  </VDropdown>

  <BaseModal
    v-if="isModalOpen"
    width="495px"
    @closed="isModalOpen = false">
    <template #default="{ close }">
      <div class="-m-[1px] flex h-[212px] items-center justify-center overflow-hidden rounded-t bg-highlight">
        <img
          :src="usePage().props.asset_url + 'assets/icons/Organic.png'"
          class="pointer-events-none select-none"
          alt="" />

        <div class="absolute right-[12px] top-[12px]">
          <VButton
            icon="fa-times"
            size="sm"
            @click="close()" />
        </div>
      </div>
      <div>
        <div class="space-y-edge p-edge text-center">
          <h2>Upgrade your execution on Crescat</h2>
          <p class="text-pretty">
            Upgrade to a Paid Plan today and Boost Your Event Success with a wider range of features
          </p>

          <div class="mx-auto mt-edge-2x flex flex-row items-center justify-center gap-x-edge">
            <VButton
              title="Talk to Sales"
              @click="talkToSales" />

            <VButton
              type="primary"
              emphasized
              title="Upgrade"
              @click="[lockPageItem.action(), (isModalOpen = false)]" />
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>
