<script setup lang="ts">
import { nextTick, ref } from 'vue';
import { InviteFestival, InviteResource } from '@/types/invite';
import ContentList from '@/components/Content/ContentList.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import { createUuId, exchangeValuesOfObject, getItemFromArrayBasedOnId } from '@/util/globals';
import { PerformanceForInfoAccessResource } from '@/types/performance';
import DisplayPerformanceInfoAccessContainer from '@/components/Groups/Settings/Performances/DisplayPerformanceInfoAccessContainer.vue';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import { ActionButtonProps } from '@/components/Inputs/Components/ActionButtonGroup.vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import { useToast } from 'vue-toastification';
import { useEmitStore } from '@/store/EmitStore';

type Props = {
  invite: InviteResource;
  groupId: number;
  canEdit: boolean;
  festival: InviteFestival | null;
  admin: boolean;
  currentGroupId?: number;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const { rootEmit } = useEmitStore();

const fetchingData = ref(true);
const performances = ref<PerformanceForInfoAccessResource[]>([]);

const fetchItAll = async () => {
  fetchingData.value = true;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/performance-info-accesses/`);
  performances.value = data;
  fetchingData.value = false;
};
fetchItAll();

const anchorId = createUuId('--anchor_');

const pageX = ref(null);
const pageY = ref(null);
const performanceSearchOpen = ref(false);
const openPerformanceDropdown = (event) => {
  if (!props.admin || !props.invite) return;
  if (!props.invite.access_levels.has_performances && !props.festival) return;

  pageX.value = null;
  pageY.value = null;

  nextTick(() => {
    performanceSearchOpen.value = true;
    pageX.value = event.target.getBoundingClientRect().x - 235;
    pageY.value = event.target.getBoundingClientRect().y + 35;
  });
};

const actions = [
  {
    title: 'Add',
    type: 'primary',
    icon: 'fa-plus',
    style: `anchor-name: ${anchorId}`,
    action: ($event) => {
      openPerformanceDropdown($event);
    },
  },
] satisfies ActionButtonProps[];

const addPerformance = async (performance) => {
  if (getItemFromArrayBasedOnId(performance.id, performances.value) !== null) {
    useToast().warning(performance.name + ' already added.');
    return;
  }
  await axios.post(`/api/performances/${performance.id}/invites/attach`, {
    invite_id: props.invite.id,
  });
  useToast().success(performance.name + ' added.');
  rootEmit('performances-changed-on-event');
  await fetchItAll();
};
</script>
<template>
  <div>
    <ContentList
      :key="currentGroupId"
      :loading="fetchingData"
      title="Performances"
      :actions="actions"
      :with-back-button="false">
      <SearchSelectFloatingWindow
        v-if="pageX && pageY && performanceSearchOpen"
        url="/api/performances"
        :params="{
          model_type: festival ? 'App\\Festival' : invite.invitable_type,
          model_id: festival ? festival.id : invite.invitable_id,
        }"
        search-on-open
        :page-y="pageY"
        :page-x="pageX"
        :can-create="false"
        :anchor-element-id="anchorId"
        @closed="pageX = null"
        @selected="addPerformance">
        <template #default="{ option }">
          <div class="flex items-center justify-between">
            <h4>{{ option.name }}</h4>
            <div>
              <span
                :class="{ 'text-soft': option.invites_count === 0 }"
                class="font-headers text-xs">
                {{
                  option.invites_count === 0
                    ? 'No Events'
                    : option.invites_count + ' Event' + (option.invites_count === 1 ? '' : 's')
                }}
              </span>
            </div>
          </div>
        </template>
      </SearchSelectFloatingWindow>

      <div
        v-if="!fetchingData"
        :key="currentGroupId"
        class="h-full space-y-edge overflow-auto pt-edge">
        <EmptyStateFullPage
          v-if="!fetchingData && performances.length === 0"
          icon="fa-music fa-regular"
          description="No Performances Added"
          :button-function="null" />

        <ContentContainer
          v-for="performance in performances"
          :key="`${currentGroupId}-${performance.id}`"
          :start-open="performances.length === 1"
          :title="performance.name">
          <template #content>
            <DisplayPerformanceInfoAccessContainer
              :can-edit="canEdit"
              :performance="performance"
              @update:performance="performances = exchangeValuesOfObject($event, performances)" />
          </template>
        </ContentContainer>
      </div>
    </ContentList>
  </div>
</template>
