'
<script setup lang="ts">
import { defineAsyncComponent, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import VButton from '@/components/Inputs/VButton.vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import PartnerContactCreateModal from '@/components/Partners/PartnerContactCreateModal.vue';
import PartnerCompanyCreateModal from '@/components/Partners/PartnerCompanyCreateModal.vue';
import { createUuId, exchangeValuesOfObject, getIndexFromArrayBasedOnId, getKey } from '@/util/globals';
import { InvoiceBasesResource } from '@/types/invoice-row';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { concatName } from '@/services/api-partners';
import { useEmitStore } from '@/store/EmitStore';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';

type Props = {
  inviteId: number;
  groupId?: number | null;
  invoice: InvoiceBasesResource;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
  groupId: null,
});

const emit = defineEmits<{
  (event: 'update:invoice', value: InvoiceBasesResource): void;
}>();

const PartnerSlideOut = defineAsyncComponent(() => import('@/components/Modals/PartnerSlideOut.vue'));

const toast = useToast();
const { assertCertain } = useCertaintyModal();
const { rootEmit } = useEmitStore();
const uuidOfComponent = createUuId();

const showDropdown = ref(true);
const loading = ref(false);
const modalOpen = ref(false);
const hasLoaded = ref(false);
const partnerContacts = ref([]);
const partnerCompanies = ref([]);

const getPartners = async (event, isCompany) => {
  if (!hasLoaded.value) {
    loading.value = true;
    const { data } = await axios.get(`/api/invites/${props.inviteId}/partners`);

    partnerContacts.value = data.contacts;
    partnerCompanies.value = data.companies;
    loading.value = false;
    hasLoaded.value = true;
  }
  if (isCompany) {
    if (partnerCompanies.value.length === 0) {
      openModal(event, true);
      await nextTick();
      showDropdown.value = false;
      await nextTick();
      showDropdown.value = true;
    }
  } else {
    if (partnerContacts.value.length === 0) {
      openModal(event, false);
      await nextTick();
      showDropdown.value = false;
      await nextTick();
      showDropdown.value = true;
    }
  }
};

const searchForCompany = ref(false);
const searchForContact = ref(false);
const pageY = ref(null);
const pageX = ref(null);
const openModal = (event, newSearchForCompany = true) => {
  searchForCompany.value = false;
  searchForContact.value = false;
  pageY.value = null;
  pageX.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 300;
    pageY.value = event.target.getBoundingClientRect().y + 25;
    searchForCompany.value = newSearchForCompany;
    searchForContact.value = !newSearchForCompany;
  });
};
const createCompanyName = ref(null);
const showCreatePartnerSlideOut = ref(false);

const createCompany = (companyName) => {
  showDropdown.value = false;
  searchForCompany.value = false;
  searchForContact.value = false;
  pageY.value = null;
  pageX.value = null;

  createCompanyName.value = companyName;
  showCreatePartnerSlideOut.value = false;
  nextTick(() => {
    showDropdown.value = true;
    showCreatePartnerSlideOut.value = true;
  });
};
const newPartnerContactName = ref('');
const showPartnerContactModal = ref(false);
const createPartnerContact = (name: string) => {
  showDropdown.value = false;
  searchForCompany.value = false;
  searchForContact.value = false;
  pageY.value = null;
  pageX.value = null;
  newPartnerContactName.value = name;
  showPartnerContactModal.value = false;
  nextTick(() => {
    showDropdown.value = true;
    showPartnerContactModal.value = true;
  });
};

const attachCompany = async (company) => {
  await axios.post(`/api/partners/companies/${company.id}/invites/${props.inviteId}/attach`);
  partnerCompanies.value.push(company);
  rootEmit('partners-changed-on-invite', { uuid: uuidOfComponent });
};
const attachContact = async (contact) => {
  await axios.post(`/api/partners/contacts/${contact.id}/invites/${props.inviteId}/attach`);
  partnerContacts.value.push(contact);
  rootEmit('partners-changed-on-invite', { uuid: uuidOfComponent });
};
//
// const addCompany = async (company) => {
//   await axios.post(`/api/partners/companies/${company.id}/invites/${props.inviteId}/attach`);
//   toast.success(`${company.name} added`);
//
//   partnerCompanies.value.push({
//     name: company.name,
//     id: company.id,
//     partner_type_id: company.partner_type_id,
//   });
// };
//
// const addContact = async (c) => {
//   if (partnerContacts.value.map((e) => e.id).includes(c.id)) return;
//   await axios.post(`/api/partners/contacts/${c.id}/invites/${props.inviteId}/attach`);
//   partnerContacts.value.push({
//     first_name: c.first_name,
//     last_name: c.last_name,
//     phone: c.phone,
//     email: c.email,
//     id: c.id,
//   });
//   toast.success(`${c.name} added`);
// };

const showPartner = ref(false);
const partnerId = ref<number | null>(null);
const currentType = ref<'company' | 'contact' | null>(null);

const openPartner = (partner: any, type: 'company' | 'contact') => {
  partnerId.value = partner.id;
  currentType.value = type;
  showPartner.value = true;
};

const openOther = (e: { type: 'company' | 'contact'; [key: string] }) => {
  showPartner.value = false;
  currentType.value = e.type;
  setTimeout(() => {
    openPartner(e, e.type);
  }, 200);
};

defineOptions({
  inheritAttrs: false,
});

const removePartnerCompany = async () => {
  if (props.invoice.id) {
    await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
      partner_company_id: null,
    });
  }
  const newInvoice = { ...props.invoice };
  newInvoice.partner_company_id = null;
  newInvoice.partner_company = null;
  emit('update:invoice', newInvoice);
};
const setPartnerCompany = async (partnerCompany) => {
  const index = getIndexFromArrayBasedOnId(partnerCompany.id, partnerCompanies.value);
  if (index === -1) {
    await attachCompany(partnerCompany);
  }
  if (props.invoice.id) {
    await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
      partner_company_id: partnerCompany.id,
    });
  }

  const newInvoice = { ...props.invoice };
  newInvoice.partner_company_id = partnerCompany.id;
  newInvoice.partner_company = partnerCompany;
  emit('update:invoice', newInvoice);
};

const removePartnerContact = async () => {
  if (props.invoice.id) {
    await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
      partner_contact_id: null,
    });
  }
  const newInvoice = { ...props.invoice };
  newInvoice.partner_contact_id = null;
  newInvoice.partner_contact = null;
  emit('update:invoice', newInvoice);
};
const setPartnerContact = async (partnerContact) => {
  const index = getIndexFromArrayBasedOnId(partnerContact.id, partnerContacts.value);
  if (index === -1) {
    await attachContact(partnerContact);
  }
  if (props.invoice.id) {
    await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
      partner_contact_id: partnerContact.id,
    });
  }

  const newInvoice = { ...props.invoice };
  newInvoice.partner_contact_id = partnerContact.id;
  newInvoice.partner_contact = partnerContact;
  emit('update:invoice', newInvoice);
};
const changePrimaryBilling = async (newContactPrimaryBilling) => {
  if (props.invoice.id) {
    await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
      contact_primary_billing: newContactPrimaryBilling,
    });
  }
  const newInvoice = { ...props.invoice };
  newInvoice.contact_primary_billing = newContactPrimaryBilling;
  emit('update:invoice', newInvoice);
};

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'partners-changed-on-invite': {
      const emitUuid = getKey(state.item?.payload, 'uuid');
      if (uuidOfComponent === emitUuid) return;
      hasLoaded.value = false;
      break;
    }
    default:
      break;
  }
});
</script>

<template>
  <div>
    <div class="grid grid-cols-2 gap-edge">
      <div>
        <div class="group flex justify-between">
          <InputLabel label="Company"></InputLabel>
          <DisplayBadge
            v-if="invoice.partner_company_id && (!invoice.partner_contact_id || !invoice.contact_primary_billing)"
            color="green"
            text="Billable" />
          <DisplayBadge
            v-else-if="invoice.partner_contact_id && invoice.partner_company_id && invoice.contact_primary_billing"
            text="Make Billable"
            size="tiny"
            color="warning"
            class="invisible cursor-pointer group-hover:visible"
            @click="changePrimaryBilling(false)" />
        </div>
        <VDropdown
          v-if="showDropdown"
          :items="
            partnerCompanies.map((p) => {
              return {
                id: p.id,
                title: p.name,
                postIcon: invoice.partner_company_id === p.id ? 'fa-check' : '',
                action: (close) => {
                  setPartnerCompany(p);
                  close();
                },
              };
            })
          "
          :close-on-click="true">
          <template #underDropdown>
            <div class="flex w-full justify-center py-edge-1/2">
              <VButton
                size="xs"
                emphasized
                type="primary"
                title="Add or Create New"
                @click="openModal($event, true)" />
            </div>
          </template>
          <template #click-area>
            <div
              v-if="invoice.partner_company"
              class="group flex items-center gap-edge-1/4">
              <div
                class="w-full cursor-pointer truncate rounded-full hover:underline"
                @click.stop="openPartner(invoice.partner_company, 'company')">
                {{ invoice.partner_company.name }}
              </div>
              <VButton
                size="sm"
                tool-tip-text="Change Company"
                icon="fa-pencil fa-regular invisible group-hover:visible"
                @click="getPartners($event, true)" />
              <VButton
                v-if="canEdit"
                size="sm"
                tool-tip-text="Remove Company"
                icon="fa-times fa-regular invisible group-hover:visible"
                @click.stop="removePartnerCompany" />
            </div>
            <VButton
              v-else
              size="sm"
              :loading="loading"
              icon="fa-building fa-regular"
              type="primary"
              title="Set Company"
              @click="getPartners($event, true)">
            </VButton>
          </template>
        </VDropdown>
      </div>
      <div>
        <div class="group flex justify-between">
          <InputLabel label="Contact"></InputLabel>
          <DisplayBadge
            v-if="invoice.partner_contact_id && (!invoice.partner_company_id || invoice.contact_primary_billing)"
            color="green"
            size="tiny"
            text="Billable" />
          <DisplayBadge
            v-else-if="invoice.partner_contact_id && invoice.partner_company_id && !invoice.contact_primary_billing"
            text="Make Billable"
            size="tiny"
            color="warning"
            class="invisible cursor-pointer group-hover:visible"
            @click="changePrimaryBilling(true)" />
        </div>
        <VDropdown
          v-if="showDropdown"
          :items="
            partnerContacts.map((p) => {
              return {
                id: p.id,
                title: concatName(p),
                postIcon: invoice.partner_contact_id === p.id ? 'fa-check' : '',
                action: (close) => {
                  setPartnerContact(p);
                  close();
                },
              };
            })
          "
          :close-on-click="true">
          <template #underDropdown>
            <div class="flex w-full justify-center py-edge-1/2">
              <VButton
                size="xs"
                emphasized
                type="primary"
                title="Add or Create New"
                @click="openModal($event, false)"></VButton>
            </div>
          </template>
          <template #click-area>
            <div
              v-if="invoice.partner_contact"
              class="group flex items-center gap-edge-1/4">
              <div
                class="w-full cursor-pointer truncate rounded-full hover:underline"
                @click.stop="openPartner(invoice.partner_contact, 'contact')">
                {{ concatName(invoice.partner_contact) }}
              </div>
              <VButton
                size="sm"
                tool-tip-text="Change Contact"
                icon="fa-pencil fa-regular invisible group-hover:visible"
                @click="getPartners($event, false)" />
              <VButton
                v-if="canEdit"
                size="sm"
                tool-tip-text="Remove Contact"
                icon="fa-times fa-regular invisible group-hover:visible"
                @click.stop="removePartnerContact" />
            </div>
            <VButton
              v-else
              size="sm"
              :loading="loading"
              icon="fa-user fa-regular"
              type="primary"
              title="Set Contact"
              @click="getPartners($event, false)">
            </VButton>
          </template>
        </VDropdown>
      </div>
    </div>
  </div>

  <Suspense>
    <template #default>
      <PartnerSlideOut
        v-if="showPartner && partnerId"
        :partner-type="currentType"
        :partner-id="partnerId"
        :model-id="groupId"
        :model-type="'Group'"
        :can-edit="canEdit"
        :venue-id="null"
        :z-index="3700"
        @update-partner="
          currentType === 'company'
            ? exchangeValuesOfObject($event, partnerCompanies, ['id'], 'id', false)
            : exchangeValuesOfObject($event, partnerContacts, ['id'], 'id', false)
        "
        @deleted="[(showPartner = false)]"
        @open-other="openOther"
        @closed="[(showPartner = false)]" />
    </template>

    <template #fallback>
      <div>loading...</div>
    </template>
  </Suspense>

  <SearchSelectFloatingWindow
    v-if="pageX && pageY && searchForCompany"
    url="/api/partners/companies"
    placeholder="Search For Company"
    :debounce-time="500"
    can-create
    :params="{
      model_type: 'App\\Group',
      model_id: groupId,
    }"
    :page-y="pageY"
    :page-x="pageX"
    :already-selected-ids="partnerCompanies.map((item) => item.id)"
    @closed="pageX = null"
    @create="createCompany"
    @selected="setPartnerCompany" />

  <PartnerCompanyCreateModal
    v-if="showCreatePartnerSlideOut"
    :init-company="{ name: createCompanyName }"
    :model-id="groupId"
    model="Group"
    :z-index="3700"
    :can-add-contacts="false"
    @closed="showCreatePartnerSlideOut = false"
    @saved="setPartnerCompany($event)" />

  <SearchSelectFloatingWindow
    v-if="pageX && pageY && searchForContact"
    url="/api/partners/contacts"
    placeholder="Search For Contact"
    :debounce-time="500"
    can-create
    :params="{
      model_type: 'App\\Group',
      model_id: groupId,
    }"
    :page-y="pageY"
    :page-x="pageX"
    :already-selected-ids="partnerContacts.map((item) => item.id)"
    @closed="pageX = null"
    @create="createPartnerContact"
    @selected="setPartnerContact" />

  <PartnerContactCreateModal
    v-if="showPartnerContactModal"
    :model-value="{ name: newPartnerContactName }"
    :model-id="groupId"
    :z-index="3700"
    model="Group"
    :can-add-company="false"
    @closed="showPartnerContactModal = false"
    @created="setPartnerContact($event)" />
</template>
