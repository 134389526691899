<script setup lang="ts">
import FieldModal from '@/components/Modals/FieldModal.vue';
import {
  destroyMetaData,
  destroyMetaDataField,
  getMetaData,
  getMetaDataFields,
  patchMetaDataField,
  postMetaDataField,
} from '@/services/api-meta-data';
import { usePage } from '@inertiajs/vue3';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { GroupResource } from '@/types/group';
import {
  MetaDataAccessResource,
  MetaDataFieldResource,
  MetaDataFieldSharedWithGroupResource,
  MetaDataModelType,
  MetaDataResource,
} from '@/types/meta-data';
import { ShowTimeResource } from '@/types/show-time';
import { useToast } from 'vue-toastification';
import {
  arrayToJoinString,
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  sortArrayBy,
} from '@/util/globals';
import FieldsList from '@/components/Fields/FieldsList.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import { formatRelativeTimeToHumanForm, getNow } from '@/util/timeFunctions';
import { MetaDataAccessComposable, metaDataAccessesKey } from '@/provide/keys';
import { allAvailableFieldTypes, multiSelectFieldType, tableFieldType } from '@/util/fields';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import SectionConnectedToEventTypeSubHeader from '@/components/Config/EventTypes/SectionConnectedToEventTypeSubHeader.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import {
  fieldEditable,
  fieldVisible,
  metaDataEditable,
  metaDataVisible,
} from '@/components/Models/MetaData/meta-data-access-functions';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { useEmitStore } from '@/store/EmitStore';
import { hasAccessToFeatureInTesting } from '@/variables/feature-testing-constants';
import { getGroupById } from '@/util/group-helpers';
import { getRoute, openRoute } from '@/util/route';

type Props = {
  isTemplate: boolean;
  modelId: number;
  model: MetaDataModelType;
  canEditForm: boolean;
  canEditContent: boolean;
  isTemplateGallery?: boolean;
  isDisplay?: boolean;
  metaData: MetaDataResource;
  group?: GroupResource | null;
  documents?: [] | null;
  showTimes?: ShowTimeResource[] | null;
  parentModel?: string | null;
  parentModelId?: number | null;
  currentGroupId?: number;
};

const props = withDefaults(defineProps<Props>(), {
  group: null,
  isDisplay: false,
  isTemplateGallery: false,
  documents: () => [],
  showTimes: () => [],
  parentModel: null,
  parentModelId: null,
});

const emits = defineEmits<{
  (event: 'edit'): void;
  (event: 'updated'): void;
  (event: 'updateData'): void;
}>();

const toast = useToast();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const isOpen = ref(false);
if (props.isDisplay) {
  isOpen.value = true;
}
const editMode = ref(true);
const loading = ref(false);

const { metaDataAccesses, fetchMetaDataAccesses, removeMetaDataAccess, addOrUpdateMetaDataAccess } = inject(
  metaDataAccessesKey,
  {
    metaDataAccesses: () => [],
    fetchMetaDataAccesses: async () => {},
    removeMetaDataAccess: async () => {},
    addOrUpdateMetaDataAccess: async () => {},
  }
) as MetaDataAccessComposable;
fetchMetaDataAccesses();

const fields = ref<MetaDataFieldResource[]>([]);
const sharedWithGroups = ref<MetaDataFieldSharedWithGroupResource[]>([]);
const selectedField = ref<MetaDataFieldResource | null>(null);
const selectedGroupIds = ref<Map<number | null, boolean>>(new Map());
fields.value = sortArrayBy(props.metaData.fields, 'order') as MetaDataFieldResource[];

const fieldSharedToGroup = (
  field: MetaDataFieldResource,
  groupId,
  onlyIfEdit = false,
  onlyCheckMetaDataAsWhole = false
) => {
  return (
    metaDataAccesses.value
      .filter((m) => m.shared_to_group_id === groupId)
      .filter((m) => m.meta_data_id === props.metaData.id)
      .filter((m) => (onlyIfEdit ? m.editable : true))
      .filter((m) =>
        onlyCheckMetaDataAsWhole
          ? m.meta_data_field_id === null
          : m.meta_data_field_id === null || m.meta_data_field_id === field.id
      ).length > 0
  );
};

const allShareToAbleItems = computed(() => {
  return [
    hasAccessToFeatureInTesting()
      ? {
          id: null,
          name: 'All Event Members',
        }
      : null,
  ]
    .concat(
      props.group
        ? props.group?.childGroups.map((c) => {
            return {
              id: c.id,
              name: c.name,
            };
          })
        : []
    )
    .filter((i) => i !== null);
});

const groupsSharedMetaDataFieldWith = (field: MetaDataFieldResource) => {
  if (allShareToAbleItems.value.length > 0) {
    const sharedToChildren = allShareToAbleItems.value.filter((child) => {
      return (
        metaDataVisible(metaDataAccesses.value, props.metaData.id, child.id) ||
        fieldVisible(metaDataAccesses.value, props.metaData.id, field.id, child.id)
      );
    });
    if (sharedToChildren.length === 0) {
      return 'N/A';
    }
    return arrayToJoinString(sharedToChildren.map((child) => child.name));
  }
  return null;
};
const groupsSharedMetaDataWith = () => {
  if (allShareToAbleItems.value.length > 0) {
    const sharedToChildren = allShareToAbleItems.value.filter((child) => {
      return metaDataVisible(metaDataAccesses.value, props.metaData.id, child.id);
    });
    if (sharedToChildren.length === 0) {
      return 'N/A';
    }
    return arrayToJoinString(sharedToChildren.map((child) => child.name));
  }
  return null;
};

const fixTitleOfFields = () => {
  if (props.canEditForm && metaDataAccesses.value.length > 0) {
    fields.value.forEach((field) => {
      field.displayTitle = `${field.title} <br> <div class="flex gap-edge-1/4 items-center"> <h5 class="text-soft">Shared With: </h5> <span class="text-sm">${groupsSharedMetaDataFieldWith(field)}</span></div>`;
    });
  }
};

watch(
  metaDataAccesses,
  () => {
    fixTitleOfFields();
  },
  { deep: true }
);

watch(
  fields,
  () => {
    fixTitleOfFields();
  },
  { deep: true }
);

const showModal = ref(false);

const openEditFieldModal = async (field: MetaDataFieldResource | null) => {
  showModal.value = false;
  selectedField.value = field;
  selectedGroupIds.value = new Map();
  await nextTick();
  showModal.value = true;
};

const actions = computed(() => {
  const array = [];
  if (props.canEditForm) {
    if (editMode.value) {
      array.push({
        title: 'Add Field',
        type: 'primary',
        icon: 'fa-plus',
        action: () => {
          openEditFieldModal({ id: null });
        },
      });
    }
  }

  if (props.isTemplate ? (props.canEditForm ? true : props.canEditContent) : props.canEditContent) {
    array.push({
      title: 'Edit',
      icon: 'fa-pencil',
      action: () => {
        emits('edit');
      },
      buttonDropdown: [
        !props.isTemplate
          ? {
              title: 'Edit Template',
              preIcon: 'fa-cog fa-regular',
              action: async () => {
                const group = await getGroupById(props.parentModelId);
                openRoute(
                  getRoute('groups.administrator', group.slug) + `?open=MetaData_${props.metaData.parent_id}#meta-data`
                );
              },
            }
          : null,
        !props.isTemplate
          ? {
              type: 'divider',
            }
          : null,
        {
          title: 'Delete',
          preIcon: 'fa-trash fa-regular',
          type: 'warning',
          action: async (close) => {
            close();
            if (!props.metaData?.id) return;
            const yes = await assertReadyToDeleteModal(
              'Delete Meta Data',
              `Are you sure that you want to delete ${props.metaData.title}?`
            );
            if (!yes) return;
            loading.value = true;
            await destroyMetaData(props.metaData.id);
            toast.success('Meta Data Deleted');
            emits('deleted', props.metaData.id);
            loading.value = false;
          },
        },
      ].filter((i) => i !== null),
    });
  }

  return array;
});

const superHeader = computed(() => {
  if (props.metaData.show_time_id && props.showTimes.length > 0) {
    const showTime = getItemFromArrayBasedOnId(props.metaData.show_time_id, props.showTimes);
    return showTime && showTime.title ? showTime.title : 'Show #' + (props.showTimes?.indexOf(showTime) + 1);
  }
  return null;
});

const onOpen = (newOpen) => {
  isOpen.value = newOpen;
  fixTitleOfFields();
};

const fieldAdded = async (field: any) => {
  loading.value = true;

  const { data } = await postMetaDataField({
    class: field.class,
    options: field.options,
    title: field.title,
    component: field.component,
    linebreak_after: field.linebreak_after,
    meta_data_id: props.metaData.id,
  });

  fields.value.push(data);

  for (const [shareToGroupId, editable] of selectedGroupIds.value.entries()) {
    const { data: newMetaDataAccess } = await axios.post(`/api/meta-data-accesses/`, {
      model_type: 'App\\Group',
      model_id: props.modelId,
      meta_data_field_id: data.id,
      meta_data_id: props.metaData.id,
      shared_to_group_id: shareToGroupId,
      editable: editable,
    });
    await addOrUpdateMetaDataAccess(newMetaDataAccess);
  }
  emits('updateData', { id: props.metaData.id, fields: fields.value });
  loading.value = false;
  toast.success('Created');
};
const removeField = async () => {
  if (!selectedField.value?.id) return;

  const index = getIndexFromArrayBasedOnId(selectedField.value.id, fields.value);

  const deleteIt = await assertReadyToDeleteModal(
    `Remove ${selectedField.value.title}`,
    'Are you sure you want to remove this field?'
  );

  if (!deleteIt) return;

  loading.value = true;
  await destroyMetaDataField(selectedField.value.id);
  loading.value = false;
  toast.success('Deleted');
  fields.value.splice(index, 1);
};
const fieldUpdated = async (field: any) => {
  if (!selectedField.value?.id) return;

  loading.value = true;

  await patchMetaDataField(selectedField.value.id, {
    class: field.class,
    options: field.options,
    title: field.title,
    linebreak_after: field.linebreak_after,
  });

  loading.value = false;
  toast.success('Updated');
  fields.value = exchangeValuesOfObject({ ...selectedField.value, ...field }, fields.value, ['id', 'component']);
  fixTitleOfFields();
};

const fieldOrderChanged = async (field: MetaDataFieldResource, newOrder: number) => {
  await patchMetaDataField(field.id, {
    order: newOrder,
  });
  useToast().success('field order changed');
};

const assignValue = async (value: any, field: MetaDataFieldResource) => {
  if (field.value === value) return;
  await axios.patch(`/api/meta-data-fields/${field.id}/input`, {
    value,
  });
  toast.success('Saved');
  field.value = value;
  field.edited_by_id = usePage().props.auth.user.id;
  field.edited_at = getNow();
  field.updated_at = getNow();

  emits('updateData', {
    id: props.metaData.id,
    last_updated_field: { updated_by: usePage().props.auth.user.name, updated_at: getNow() },
  });
  useEmitStore().rootEmit('meta-data-field-value-updated', { ...field, modelId: props.modelId, model: props.model });
};

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'meta-data-field-value-updated': {
      const field = state.item?.payload;
      if (!field) return;
      const metaDataFieldIndex = getIndexFromArrayBasedOnId(field.id, fields.value);
      if (metaDataFieldIndex === -1) return;
      fields.value[metaDataFieldIndex].value = field.value;
      break;
    }
    default:
      break;
  }
});

const fetchField = async (fieldId: number) => {
  const { data } = await getMetaDataFields(fieldId);
  fields.value = exchangeValuesOfObject(data, fields.value, ['id', 'component']);
};
const fetchMetaData = async () => {
  const { data } = await getMetaData(props.metaData.id);
  emits('updateData', data);
};

const listenForBroadcast = () => {
  if (!props.modelId || !props.model) return;

  Echo.channel(`private-${props.model}.${props.modelId}`)
    .listen(`.metaData.${props.metaData.id}.updated`, (e) => {
      fetchMetaData();
    })
    .listen(`.metaData.${props.metaData.id}.fieldCreated`, (e) => {
      fetchField(e.id);
    })
    .listen(`.metaData.${props.metaData.id}.fieldUpdated`, (e) => {
      fetchField(e.id);
    })
    .listen(`.metaData.${props.metaData.id}.fieldDeleted`, (e) => {
      const index = fields.value.findIndex((f: MetaDataFieldResource) => Number(f.id) === Number(e.id));
      if (index) {
        fields.value.splice(index, 1);
      } else {
        fetchMetaData();
      }
    });
};

onMounted(() => {
  listenForBroadcast();
});

// const toggleWriteOnFieldForGroup = (field, group) => {
//   if(fieldSharedToGroup(field, group.id, true, true)){
//     useToast().info('Meta Data Shared with Edit access. Cannot change field.');
//     return;
//   }
// }

const toggleViewAccessForGroup = async (child) => {
  if (props.model !== 'Group') return;
  if (!selectedField.value) return;

  if (selectedField.value.id === null) {
    if (selectedGroupIds.value.has(child.id)) {
      selectedGroupIds.value.delete(child.id);
    } else {
      selectedGroupIds.value.set(child.id, false);
    }
    return;
  }

  if (metaDataVisible(metaDataAccesses.value, props.metaData.id, child.id)) {
    return;
  }
  const index = metaDataAccesses.value.findIndex(
    (i: MetaDataAccessResource) =>
      i.meta_data_id === props.metaData.id &&
      i.shared_to_group_id === child.id &&
      i.meta_data_field_id === selectedField.value.id
  );
  if (index === -1) {
    const { data } = await axios.post(`/api/meta-data-accesses/`, {
      model_type: 'App\\Group',
      model_id: props.modelId,
      meta_data_field_id: selectedField.value.id,
      meta_data_id: props.metaData.id,
      shared_to_group_id: child.id,
    });
    addOrUpdateMetaDataAccess(data);
    useToast().info('Added');
  } else {
    await axios.delete(`/api/meta-data-accesses/${metaDataAccesses.value[index].id}`);
    removeMetaDataAccess(metaDataAccesses.value[index].id);
    useToast().info('Removed');
  }
};

const toggleEditAccessForGroup = async (child) => {
  if (props.model !== 'Group') return;
  if (metaDataEditable(metaDataAccesses.value, props.metaData.id, child.id)) {
    return;
  }
  if (selectedField.value.id === null) {
    if (selectedGroupIds.value.has(child.id)) {
      selectedGroupIds.value.set(child.id, !selectedGroupIds.value.get(child.id));
    } else {
      selectedGroupIds.value.set(child.id, true);
    }

    if (!selectedGroupIds.value.get(child.id) && metaDataVisible(metaDataAccesses.value, props.metaData.id, child.id)) {
      selectedGroupIds.value.delete(child.id);
    }
    return;
  }

  const index = metaDataAccesses.value.findIndex(
    (i: MetaDataAccessResource) =>
      i.meta_data_id === props.metaData.id &&
      i.shared_to_group_id === child.id &&
      i.meta_data_field_id === selectedField.value.id
  );
  if (index > -1) {
    const item = { ...metaDataAccesses.value[index] };
    item.editable = !item.editable;
    await axios.patch(`/api/meta-data-accesses/${item.id}`, {
      editable: item.editable,
    });
    addOrUpdateMetaDataAccess(item);
    useToast().info('Updated ');
  } else {
    const { data } = await axios.post(`/api/meta-data-accesses/`, {
      model_type: 'App\\Group',
      model_id: props.modelId,
      meta_data_field_id: selectedField.value.id,
      meta_data_id: props.metaData.id,
      shared_to_group_id: child.id,
      editable: true,
    });
    addOrUpdateMetaDataAccess(data);
    useToast().info('Added');
  }
};
</script>

<template>
  <ContentContainer
    :key="currentGroupId"
    :can-edit="canEditForm || canEditContent"
    :loading="loading"
    :title="metaData.title"
    :just-content-without-header="isDisplay"
    :super-header="superHeader"
    :actions="actions"
    pre-icon="fa-table fa-regular"
    :extra-height="isTemplate || isDisplay ? 0 : 70"
    @open="onOpen">
    <template #underHeader>
      <div v-if="isTemplate">
        <div
          :class="[isOpen ? 'ml-[45px]' : '']"
          class="flex items-center gap-edge-1/4">
          <h5 class="text-soft">Shared With:</h5>
          <span class="text-sm">{{ groupsSharedMetaDataWith() }}</span>
        </div>
      </div>
      <div
        v-if="!isOpen && metaData.last_updated_field && !isTemplate"
        class="!sub-title text-soft">
        Last edited by <span>{{ metaData.last_updated_field.updated_by }}</span> at
        <span v-if="metaData.last_updated_field.updated_at !== 'N/A'">
          {{ formatRelativeTimeToHumanForm(new Date(metaData.last_updated_field.updated_at)) }}
        </span>
        <span v-else>N/A</span>
      </div>
      <div v-else-if="isOpen && isTemplate">
        <SectionConnectedToEventTypeSubHeader
          :model="model"
          :model-id="modelId"
          pre-icon="fa-table"
          :title-of-item="metaData.title"
          :id-of-item="metaData.id"
          type-of-item="MetaData" />
      </div>
    </template>

    <template #content>
      <div :class="{ 'px-edge py-edge': editMode, 'p-edge': isTemplate }">
        <component
          :is="isDisplay ? 'div' : BoxContainer"
          v-if="fields.length > 0">
          <FieldsList
            v-model="fields"
            :edit-content="canEditContent && editMode"
            :edit-form="canEditForm && editMode"
            :model="model"
            :model-id="modelId"
            :documents="documents"
            with-icons
            :whisper-channel="model === 'Invite' ? 'Invite.' + modelId : null"
            :whisper-string="'field.'"
            :loading-order="false"
            @field-order-changed="fieldOrderChanged"
            @assign-value="assignValue"
            @open-edit-modal="openEditFieldModal" />
        </component>

        <EmptyStateFullPage
          v-if="fields.length === 0 && editMode && canEditForm"
          icon="fa-table fa-regular"
          description="No meta data fields created yet."
          button-text="Create New Field"
          :button-function="
            () => {
              openEditFieldModal({ id: null });
            }
          " />

        <div
          v-if="canEditForm && editMode"
          class="h-[12px]">
          <FieldModal
            v-if="showModal && selectedField"
            :init-field="selectedField"
            :with-description="false"
            :field-types="[...allAvailableFieldTypes, ...multiSelectFieldType, ...tableFieldType]"
            @created="fieldAdded"
            @deleted="removeField"
            @closed="showModal = false"
            @updated="fieldUpdated">
            <template
              #under
              v-if="allShareToAbleItems.length > 0">
              <div class="mt-edge">
                <h3
                  class="pointer"
                  title="These group will then see, and if write-access is granted, edit fields in your meta data.">
                  <i class="fa fa-group fa-fw" />
                  Share with your teams
                </h3>
                <VTable
                  edge-to-edge
                  row-size="small"
                  rounded-pill-rows>
                  <template #head>
                    <VTableRow head>
                      <VTableCell>Who</VTableCell>
                      <VTableCell style="width: 40px">View</VTableCell>
                      <VTableCell style="width: 40px">Edit</VTableCell>
                    </VTableRow>
                  </template>
                  <VTableRow
                    v-for="item in allShareToAbleItems"
                    :key="item.id">
                    <VTableCell
                      :main-cell="
                        metaDataVisible(metaDataAccesses, metaData.id, item.id) ||
                        fieldVisible(metaDataAccesses, metaData.id, selectedField.id, item.id)
                      ">
                      {{ item.name }}
                    </VTableCell>
                    <VTableCell>
                      <SettingCheck
                        v-if="metaDataVisible(metaDataAccesses, metaData.id, item.id)"
                        disabled
                        disabled-title="Meta Data Shared."
                        :model-value="true" />
                      <template v-else-if="selectedField.id">
                        <SettingCheck
                          :model-value="fieldVisible(metaDataAccesses, metaData.id, selectedField.id, item.id)"
                          @click="toggleViewAccessForGroup(item)" />
                      </template>
                      <template v-else>
                        <SettingCheck
                          :model-value="selectedGroupIds.has(item.id)"
                          @click="toggleViewAccessForGroup(item)" />
                      </template>
                    </VTableCell>
                    <VTableCell main-cell>
                      <template v-if="selectedField.id">
                        <SettingCheck
                          v-if="metaDataEditable(metaDataAccesses, metaData.id, item.id)"
                          disabled
                          :model-value="true" />

                        <SettingCheck
                          v-else
                          :model-value="fieldEditable(metaDataAccesses, metaData.id, selectedField.id, item.id)"
                          @click="toggleEditAccessForGroup(item)" />
                      </template>
                      <template v-else>
                        <SettingCheck
                          v-if="metaDataEditable(metaDataAccesses, metaData.id, item.id)"
                          disabled
                          :model-value="true" />
                        <SettingCheck
                          v-else
                          :model-value="selectedGroupIds.get(item.id)"
                          @click="toggleEditAccessForGroup(item)" />
                      </template>
                    </VTableCell>
                  </VTableRow>
                </VTable>
              </div>
            </template>
          </FieldModal>
        </div>
      </div>
    </template>
  </ContentContainer>
</template>
