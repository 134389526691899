<script setup lang="ts">
import TruncateDisplay from '@/components/Display/TruncateDisplay.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import VToggle from '@/components/Inputs/VToggle.vue';
import { FestivalSectionResource, FestivalSectionTimeSlot, FestivalSectionVenue } from '@/types/festival-section';
import { computed, inject, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { arrayToJoinString, getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import {
  formatMinutesAsHoursAndMinutes,
  formatStampAsDate,
  formatStampAsHumanReadableDate,
  formatStampAsTime,
} from '@/util/timeFunctions';
import { canCheckIntoShift, canCheckOutOfShift, updateShiftSlotCount } from '@/helpers/shiftFunctions';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import ShiftCrewSlot from '@/components/Festivals/Shifts/ShiftCrewSlot.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useEmitStore } from '@/store/EmitStore';
import {
  assignSlotOfShiftToPublicFormResponse,
  getTitleForPublicFormResponseAvailableInSlot,
} from '@/helpers/publicFormFuctions';
import type { ShiftResource } from '@/types/event';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import ShiftCheckModal from '@/components/Shifts/ShiftCheckModal.vue';
import DisplayColoredCount from '@/components/Display/Components/DisplayColoredCount.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { getPublicFormResponses } from '@/services/api-public-form-responses';
import { PublicFormResponseResource } from '@/types/public-form-response';
import { patchShift } from '@/services/api-shifts';
import ShiftSendReminderSlideOut from '@/components/Shifts/ShiftSendReminderSlideOut.vue';
import { hasSMSKey } from '@/provide/keys';
import TableActionFilter from '@/components/Tables/Headers/TableActionFilter.vue';
import TableSearchRow from '@/components/Tables/Headers/TableSearchRow.vue';
import TableButtonSelector from '@/components/Tables/Headers/TableButtonSelector.vue';
import Paginator from '@/components/Tables/Paginator.vue';
import { highlightStringBySearch } from '@/util/text-replace-helper';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { getRoute } from '@/util/route';

type Props = {
  festivalSection?: FestivalSectionResource | null;
  shift: {
    id: ShiftResource['id'];
    time_slot_id: ShiftResource['time_slot_id'];
    shift_crew_slots: ShiftResource['shift_crew_slots'];
    start: ShiftResource['start'];
    end: ShiftResource['end'];
    place_id: ShiftResource['place_id'];
    place_string: ShiftResource['place_string'];
    place: ShiftResource['place'];
    place_type: ShiftResource['place_type'];
    title: ShiftResource['title'];
    description: ShiftResource['description'];
    events: ShiftResource['events'];
    check_in: ShiftResource['check_in'];
    check_out: ShiftResource['check_out'];
    notes: ShiftResource['notes'];
  };
  write: boolean;
  withButton: boolean;
  buttonText?: string;
  timeSlots?: FestivalSectionTimeSlot[];
  venues?: FestivalSectionVenue[];
  crewResources?: {
    model_id: number;
    title: string;
  }[];
  canAssign?: boolean;
  canCheckSlots?: boolean;
  isDisplay?: boolean;
  slideOutSmall?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  festivalSection: null,
  crewResources: () => [],
  timeSlots: () => [],
  venues: () => [],
  buttonText: 'Assign Shift',
  canAssign: false,
  isDisplay: false,
  canCheckSlots: true,
  slideOutSmall: false,
});

const emit = defineEmits<{
  (event: 'closed', ...args: any[]): void;
  (event: 'assignedToSlot', ...args: any[]): void;
  (event: 'slotCountUpdated', ...args: any[]): void;
  (event: 'forSale', ...args: any[]): void;
  (event: 'updated', ...args: any[]): void;
  (event: 'upForSaleCountUpdated', ...args: any[]): void;
  (event: 'clearAllSlots', ...args: any[]): void;
  (event: 'checkedIn', ...args: any[]): void;
  (event: 'clearCheckInAndOut', ...args: any[]): void;
  (event: 'checkedOut', ...args: any[]): void;
  (event: 'showShiftCheckIn', ...args: any[]): void;
  (event: 'showShiftCheckOut', ...args: any[]): void;
  (event: 'unAssignedSlot', ...args: any[]): void;
  (event: 'updatedShift', ...args: any[]): void;
  (event: 'slotUpdated', ...args: any[]): void;
  (event: 'checkCleared'): void;
}>();

const hasSMS = inject(hasSMSKey, false);

const toast = useToast();
const { assertCertain } = useCertaintyModal();

const working = ref(false);
const modalOpen = ref(!props.withButton);
const loaded = ref(false);
const loading = ref(false);
const timeSlot = ref(getItemFromArrayBasedOnId(props.shift.time_slot_id, props.timeSlots));
const publicFormResponses = ref([]);
const last_page = ref(1);
const totalCount = ref(1);
const per_page = ref(10);
const currentPage = ref(1);

const shiftCrewSlots = ref(props.shift.shift_crew_slots);
const newSlotsCountUpdateFunction = ref(null);
const newUpForSaleCountUpdatedFunction = ref(null);
const newUpForSaleCount = ref(props.shift.shift_crew_slots.filter((slot) => slot.for_sale === true).length);

const searchByPreferringSlot = ref(true);
const selectedShiftCrewSlot = ref(null);

const availableSlots = computed(() => {
  return shiftCrewSlots.value?.filter((s) => s.slottable_id === null);
});

const upForSaleSlots = computed(() => {
  return shiftCrewSlots.value?.filter((s) => s.for_sale === true);
});

const fetchAvailablePublicForms = (page = 1) => {
  if (!props.festivalSection) return;
  if (!props.write) return;
  if (page > last_page.value) return;
  if (loading.value) return;

  currentPage.value = page;
  loading.value = true;
  const params = {
    festival_section_id: props.festivalSection.id,
    status: 'accepted',
    order_by: 'assigned_work_minutes',
    per_page: per_page.value,
    page,
  };
  if (props.timeSlots.length && searchByPreferringSlot.value && props.shift.time_slot_id) {
    params.preferred_time_slot_ids = [props.shift.time_slot_id];
  } else {
    params.start = props.shift.start;
    params.end = props.shift.end;
  }
  axios
    .get('/api/public-form-responses', { params })
    .then((resp) => {
      publicFormResponses.value = resp.data.data;
      last_page.value = resp.data.meta.last_page;
      totalCount.value = resp.data.meta.total;
      nextTick(() => {
        loading.value = false;
      });
    })
    .catch((error) => {
      toast.warning('Could not find anything');
      console.error(error.response.data);
    });
};

const toggleModal = () => {
  modalOpen.value = false;
  fetchAvailablePublicForms();
  nextTick(() => {
    modalOpen.value = true;
  });
};

const isAlreadyAssigned = (publicFormResponse) => {
  return getIndexFromArrayBasedOnId(publicFormResponse.id, props.shift.shift_crew_slots, 'slottable_id') > -1;
};

const unassignSlot = async (event, slotId: number) => {
  emit('unAssignedSlot', event);
  emit('slotUpdated', { id: slotId, slottable_id: null, slottable_type: null, slottable: null });
  const index = getIndexFromArrayBasedOnId(slotId, shiftCrewSlots.value);
  if (index === -1) return;
  shiftCrewSlots.value[index].slottable_id = null;
  shiftCrewSlots.value[index].slottable_type = null;
  shiftCrewSlots.value[index].slottable = null;
};
const assignTo = async (publicFormResponse, slotId: number) => {
  if (availableSlots.value.length === 0) {
    toast.warning('All Slots has already been assigned.');
    return;
  }
  if (isAlreadyAssigned(publicFormResponse)) {
    toast.warning('Already assigned');
    return;
  }
  if (!working.value) {
    working.value = true;
    await assignSlotOfShiftToPublicFormResponse(slotId, publicFormResponse.id);

    toast.success(`Added ${publicFormResponse.name} to slot`);

    const index = getIndexFromArrayBasedOnId(publicFormResponse.id, publicFormResponses.value);
    if (index > -1) {
      publicFormResponses.value.splice(index, 1);
    }
    emit('assignedToSlot', {
      publicFormResponseId: publicFormResponse.id,
      shiftId: props.shift.id,
      slotId,
      slottable: {
        id: publicFormResponse.id,
        name: publicFormResponse.name,
      },
    });

    const slotIndex = getIndexFromArrayBasedOnId(slotId, shiftCrewSlots.value);
    if (slotIndex > -1) {
      shiftCrewSlots.value[slotIndex].slottable = {
        id: publicFormResponse.id,
        email: publicFormResponse.email,
        name: `${publicFormResponse.first_name} ${publicFormResponse.last_name}`,
        phone: publicFormResponse.phone,
        country_code: publicFormResponse.country_code,
      };

      emit('slotUpdated', {
        id: shiftCrewSlots.value[slotIndex].id,
        slottable_id: shiftCrewSlots.value[slotIndex].slottable_id,
        slottable_type: shiftCrewSlots.value[slotIndex].slottable_type,
        slottable: shiftCrewSlots.value[slotIndex].slottable,
      });
    }
    working.value = false;
  }
};

const getTimeKeyOfShift = (key) => {
  if (!props.shift) return 'N/A';
  if (props.shift[key]) return formatStampAsTime(props.shift[key]);

  const timeSlot = getItemFromArrayBasedOnId(props.shift.time_slot_id, props.timeSlots);
  if (timeSlot && timeSlot[key]) return formatStampAsTime(timeSlot[key]);

  return 'N/A';
};

const newSlotCount = ref(props.shift.shift_crew_slots.length);

const doTheUpdateShiftSlotCount = async () => {
  if (newSlotCount.value === props.shift.shift_crew_slots.length) return false;

  try {
    await updateShiftSlotCount(props.shift.id, newSlotCount.value);
    emit('slotCountUpdated', props.shift.id, newSlotCount.value);
    setTimeout(() => {
      shiftCrewSlots.value = props.shift.shift_crew_slots;
      newUpForSaleCount.value = availableSlots.value.length;
    }, 100);
    return true;
  } catch (e) {
    if (e.response.data) {
      toast.warning(e.response.data);
    } else {
      toast.warning('Could not update slot count');
    }
    return false;
  }
};

const updateSlotForSale = (value, index) => {
  if (value === false) {
    if (newUpForSaleCount.value > 0) {
      newUpForSaleCount.value -= 1;
    }
  } else {
    newUpForSaleCount.value += 1;
  }
  shiftCrewSlots.value[index].for_sale = value;
  emit('forSale', { value, index, shift_id: props.shift.id });
  emit('slotUpdated', { id: shiftCrewSlots.value[index].id, for_sale: shiftCrewSlots.value[index].for_sale });
};

const updateAssignedCrewSlot = (data) => {
  let index = getIndexFromArrayBasedOnId(data.shiftCrewSlotId, shiftCrewSlots.value);
  if (index > -1) {
    shiftCrewSlots.value[index].title = data.title;
    shiftCrewSlots.value[index].description = data.description;
  }
  index = getIndexFromArrayBasedOnId(data.shiftCrewSlotId, props.shift.shift_crew_slots);
  if (index > -1) {
    emit('updated', {
      index,
      title: data.title,
      description: data.description,
    });
  }
};

const markAsUpForSale = async (withToast = false) => {
  if (newUpForSaleCount.value === upForSaleSlots.value?.length) {
    return;
  }
  if (newUpForSaleCount.value > availableSlots.value.length) {
    toast.warning('Cannot mark more slots than are available.');
    newUpForSaleCount.value = availableSlots.value.length;
  }
  try {
    const { data } = await axios.post(`/api/shifts/${props.shift.id}/slots-for-sale`, {
      count: newUpForSaleCount.value,
      shift_crew_role_id: null,
      disregard_crew_role: false,
    });
    if (data.count < newUpForSaleCount.value) {
      toast.warning(`Could only mark ${data.count} slots as up for sale`);
      newUpForSaleCount.value = data.count;
    }
    if (withToast) {
      data.count === 0 ? toast.success('No slots for sale anymore.') : toast.success(`${data.count} slots for sale.`);
    }

    emit('upForSaleCountUpdated', props.shift.id, newUpForSaleCount.value);
  } catch (e) {
    if (e.response.data) {
      toast.warning(e.response.data);
    } else {
      toast.warning('Could not update up for sale count.');
    }
  }
};

const unAssignAllSlots = async () => {
  const certain = await assertCertain(
    'Un-assign all slots',
    'Are you sure you want to un-assign all slots on this shift?'
  );
  if (!certain) return;

  await axios.post(`/api/shifts/${props.shift.id}/clear-slots`).catch((error) => {
    console.error(error);
    toast.warning('Something went wrong, please try again later.');
  });
  toast.success('Cleared');
  emit('clearAllSlots', props.shift.id);
};

const getPlaceFromShift = () => {
  if (props.shift.place_id === null) {
    if (props.shift.place_string) {
      return props.shift.place_string;
    }
    return 'N/A';
  }
  if (props.shift.place && props.shift.place.name) {
    return props.shift.place.name;
  }
  const flatMapVenues = props.venues.flatMap((venue) => [venue].concat(venue.children));
  switch (props.shift.place_type) {
    case 'App\\Venue': {
      const index = flatMapVenues.findIndex(
        (p) => Number(p.model_id) === Number(props.shift.place_id) && p.model === 'venue'
      );
      if (index > -1) {
        return flatMapVenues[index].title;
      }
      break;
    }
    case 'App\\Room': {
      const index = flatMapVenues.findIndex(
        (p) => Number(p.model_id) === Number(props.shift.place_id) && p.model === 'room'
      );
      if (index > -1) {
        return flatMapVenues[index].title;
      }
      break;
    }
    default: {
      break;
    }
  }
  return 'N/A';
};

const checkedIn = (data) => {
  let index = getIndexFromArrayBasedOnId(data.shiftCrewSlotId, shiftCrewSlots.value);
  if (index > -1) {
    shiftCrewSlots.value[index].check_in = data.check_in;
  }
  index = getIndexFromArrayBasedOnId(data.shiftCrewSlotId, props.shift.shift_crew_slots);
  if (index > -1) {
    emit('checkedIn', { index, check_in: data.check_in });
  }
};

const checkCleared = (shiftCrewSlotId) => {
  let index = getIndexFromArrayBasedOnId(shiftCrewSlotId, shiftCrewSlots.value);
  if (index > -1) {
    shiftCrewSlots.value[index].check_in = null;
    shiftCrewSlots.value[index].check_out = null;
    shiftCrewSlots.value[index].message = null;
  }
  index = getIndexFromArrayBasedOnId(shiftCrewSlotId, props.shift.shift_crew_slots);
  if (index > -1) {
    emit('clearCheckInAndOut', { index, check_in: null, check_out: null, message: null });
  }
};

const checkedOut = (data) => {
  let index = getIndexFromArrayBasedOnId(data.shiftCrewSlotId, shiftCrewSlots.value);
  if (index > -1) {
    shiftCrewSlots.value[index].check_out = data.check_out;
    shiftCrewSlots.value[index].message = data.message;
  }
  index = getIndexFromArrayBasedOnId(data.shiftCrewSlotId, props.shift.shift_crew_slots);
  if (index > -1) {
    emit('checkedOut', {
      index,
      check_out: data.check_out,
      message: data.message,
    });
  }
};

const slotCountUpdated = (newCount) => {
  newSlotCount.value = newCount;
  clearTimeout(newSlotsCountUpdateFunction.value);
  if (newSlotCount.value > props.shift.shift_crew_slots.length) {
    newSlotsCountUpdateFunction.value = setTimeout(() => {
      clearTimeout(newSlotsCountUpdateFunction.value);
      newSlotsCountUpdateFunction.value = null;
      doTheUpdateShiftSlotCount();
    }, 1000);
  }
};

const forSaleCountUpdated = (newCount) => {
  newUpForSaleCount.value = newCount;
  clearTimeout(newUpForSaleCountUpdatedFunction.value);
  if (newUpForSaleCount.value > upForSaleSlots.value?.length) {
    newUpForSaleCountUpdatedFunction.value = setTimeout(() => {
      clearTimeout(newUpForSaleCountUpdatedFunction.value);
      newUpForSaleCountUpdatedFunction.value = null;
      markAsUpForSale(true);
    }, 1000);
  }
};

if (!props.withButton) {
  toggleModal();
}
watch(shiftCrewSlots, (newVal, oldVal) => {
  if (!modalOpen.value) return;
  if (!newVal || !oldVal) return;
  if (newVal.length === 0 || oldVal.length === 0) {
    modalOpen.value = false;
    nextTick(() => {
      modalOpen.value = true;
    });
  }
});

useEmitStore().$subscribe((mutation, state) => {
  const payload = state.item?.payload;
  switch (state.item?.key) {
    case 'shiftCrewSlotsUpdated': {
      const shiftId = getKey(payload, 'id');
      const newShiftCrewSlots = getKey(payload, 'shift_crew_slots');
      if (props.shift.id === shiftId) {
        shiftCrewSlots.value = newShiftCrewSlots;
      }
      break;
    }
    default:
      break;
  }
});

const checkModalOpen = ref(false);
const isCheckOutModal = ref(false);
const openCheckModal = async (isCheckOut = false) => {
  if (isCheckOut) {
    if (!canCheckOutOfShift(props.shift)) return;
  } else {
    if (!canCheckIntoShift(props.shift)) return;
  }
  checkModalOpen.value = false;
  isCheckOutModal.value = isCheckOut;
  await nextTick();
  checkModalOpen.value = true;
};

const showAddManyModal = ref(false);

const allResponders = ref<PublicFormResponseResource[]>([]);

const selectedResponders = ref<Map<number, PublicFormResponseResource>>(new Map());

type AllPublicFormsColumns = {
  id: number;
  title: string;
  sections: {
    id: number;
    title: string;
    fields: {
      id: number;
      title: string;
    }[];
  }[];
};

const allPublicFormsColumns = computed(() => {
  const columns: AllPublicFormsColumns[] = [];
  props.festivalSection?.public_forms.forEach((form) => {
    const formColumns: AllPublicFormsColumns = {
      id: form.id,
      title: form.name,
      sections: [],
    };
    form.public_form_sections.forEach((section) => {
      const sectionColumns = {
        id: section.id,
        title: section.title,
        fields: section.public_form_fields.map((field) => ({
          id: field.id,
          title: field.title,
        })),
      };
      formColumns.sections.push(sectionColumns);
    });
    columns.push(formColumns);
  });
  return columns;
});

type AllColumns = {
  id: number;
  title: string;
  component: string;
  SectionTitle: string;
};

const allColumns = computed(() => {
  const columns: AllColumns[] = [];
  props.festivalSection?.public_forms.forEach((form) => {
    form.public_form_sections.forEach((section) => {
      section.public_form_fields.forEach((field) => {
        columns.push({
          id: field.id,
          title: field.title,
          component: field.component,
          SectionTitle: section.title,
        });
      });
    });
  });
  return columns;
});

const lastPageMultiAssign = ref(1);
const totalCountMultiAssign = ref(1);
const perPageMultiAssign = ref(15);
const currentPageMultiAssign = ref(1);

const fetchData = async (searchText = '', page = 1) => {
  const publicFormIds = props.festivalSection?.public_forms
    .map((form) => form.public_form_sections)
    .flat()
    .map((section) => section.public_form_fields)
    .flat()
    .map((field) => field.id);

  currentPageMultiAssign.value = page;

  let params = {
    festival_section_id: props.festivalSection.id,
    status: 'accepted',
    order_by: 'assigned_work_minutes',
    page: currentPageMultiAssign.value,
    per_page: perPageMultiAssign.value,
    public_form_fields: publicFormIds,
    search: searchText,
    // start: inTimeFrame.value ? props.shift.start : undefined,
    // end: inTimeFrame.value ? props.shift.end : undefined,
    // preferred_time_slot_ids: inTimeFrame.value ? undefined : [timeSlot.value?.id],
    public_form_fields_filters: [],
  };

  switch (whoToShow.value) {
    case 'prefers_timeslot':
      params = {
        ...params,
        start: props.shift.start,
        end: props.shift.end,
        preferred_time_slot_ids: [timeSlot.value?.id],
      };
      break;
    case 'available':
      params = {
        ...params,
        start: props.shift.start,
        end: props.shift.end,
      };
      break;
    case 'all':
      params = {
        ...params,
        start: props.shift.start,
        end: props.shift.end,
        skip_shift_of_id: props.shift.id,
        filter_out_busy: false,
      };
      break;
  }

  if (activeFieldFilters.value.length > 0) {
    params.public_form_fields_filters = activeFieldFilters.value.map((f) => {
      return {
        public_form_field_id: f.id,
        value: f.value,
        operator: f.operator,
      };
    });
  }
  if (activePreferenceFilters.value.length > 0) {
    for (const filter of activePreferenceFilters.value) {
      switch (filter.id) {
        case 'festival_section': {
          params[`preferred_festival_section_ids`] = filter.selectedIds;
          break;
        }
        case 'time_slots': {
          params[`preferred_time_slot_ids`] = filter.selectedIds;
          break;
        }
        case 'assigned_festival_sections': {
          params[`assigned_festival_section_ids`] = filter.selectedIds;
          break;
        }
        case 'assigned_work_load': {
          if (filter.operator === '>') {
            params[`assigned_more_work_minutes_than`] = filter.value;
          } else if (filter.operator === '<') {
            params[`assigned_less_work_minutes_than`] = filter.value;
          } else {
            params[`assigned_work_minutes_equals`] = filter.value;
          }
          break;
        }
      }
    }
  }

  loading.value = true;
  const { data } = await getPublicFormResponses(params);
  loading.value = false;
  allResponders.value = data.data;
  lastPageMultiAssign.value = data.meta.last_page;
  totalCountMultiAssign.value = data.meta.total;
};

const openAddMultipleModal = async () => {
  if (!props.festivalSection?.id) return;

  await fetchData();

  showAddManyModal.value = true;
};

const unAssignedSlots = computed(() => {
  return shiftCrewSlots.value.filter((slot) => !slot.slottable_id);
});

const addUsersToSlots = async (close: () => void) => {
  for (const slot of unAssignedSlots.value) {
    const currentResponder = selectedResponders.value.values().next().value;
    if (!currentResponder) continue;
    selectedResponders.value.delete(currentResponder.id);
    const response = allResponders.value.find((responder) => responder.id === currentResponder.id);
    await assignTo(response, slot.id);
  }

  close();
};

const toggleResponder = (responder: PublicFormResponseResource) => {
  if (selectedResponders.value.has(responder.id)) {
    selectedResponders.value.delete(responder.id);
  } else {
    selectedResponders.value.set(responder.id, responder);
  }
};

const searchText = ref('');

const timeout = ref(null);

const search = () => {
  clearTimeout(timeout.value);
  timeout.value = setTimeout(async () => {
    await fetchData(searchText.value);
  }, 200);
};

const getPublicFormName = (responder: PublicFormResponseResource) => {
  if (!props.festivalSection) return '';
  const publicForm = props.festivalSection.public_forms.find((form) => form.id === responder.public_form_id);
  if (!publicForm) return 'N/A';
  return publicForm.name;
};

const activeColumnIds = ref<Set<number>>(new Set());

const toggleColumn = (id: number) => {
  if (activeColumnIds.value.has(id)) {
    activeColumnIds.value.delete(id);
  } else {
    activeColumnIds.value.add(id);
  }
};

const activeFieldFilters = ref([]);
const activePreferenceFilters = ref([]);

const activePublicFormFieldsBecauseOfFilters = ref([]);
const activeColumnsOfFilters = ref([]);
watch(
  () => activeFieldFilters,
  () => {
    activeFieldFilters.value.forEach((filter) => {
      if (!activeColumnIds.value.has(filter.id)) {
        activeColumnIds.value.add(filter.id);
        activePublicFormFieldsBecauseOfFilters.value.push(filter.id);
      }
    });
    [...activePublicFormFieldsBecauseOfFilters.value].reverse().forEach((forcedActiveColumnId) => {
      if (!activeFieldFilters.value.map((field) => field.id).includes(forcedActiveColumnId)) {
        activeColumnIds.value.delete(forcedActiveColumnId);
        activePublicFormFieldsBecauseOfFilters.value.splice(
          activePublicFormFieldsBecauseOfFilters.value.indexOf(forcedActiveColumnId),
          1
        );
      }
    });
  },
  { deep: true, immediate: true }
);
watch(
  () => activePreferenceFilters,
  async () => {
    await nextTick();
    const activeColumns = [];
    activePreferenceFilters.value.forEach((f) => {
      switch (f.id) {
        case 'assigned_work_load': {
          activeColumns.push('assigned_work_load');
          if (!selectedPreDefColumns.value.includes('assigned_work_load')) {
            selectedPreDefColumns.value.push('assigned_work_load');
            activeColumnsOfFilters.value.push('assigned_work_load');
          }
          break;
        }
      }
    });
    [...activeColumnsOfFilters.value].reverse().forEach((forcedActiveColumnId) => {
      if (!activeColumns.includes(forcedActiveColumnId)) {
        selectedPreDefColumns.value.splice(selectedPreDefColumns.value.indexOf(forcedActiveColumnId), 1);
        activeColumnsOfFilters.value.splice(activeColumnsOfFilters.value.indexOf(forcedActiveColumnId), 1);
      }
    });
  },
  { deep: true, immediate: true }
);

const allSections = computed(() => {
  return props.festivalSection?.public_forms.map((form) => form.public_form_sections).flat();
});
// const inTimeFrame = ref(!timeSlot.value);
const whoToShow = ref(timeSlot.value === null ? 'available' : 'prefers_timeslot');

const cellsToDisplay = ref(
  [
    { key: 'email', title: 'Email' },
    { key: 'phone', title: 'Phone' },
    { key: 'created_at', title: 'Submitted' },
    !props.festivalSection ? { key: 'status', title: 'Status' } : null,
    { key: 'expected_work_load', title: 'Expected', super: 'Work Load' },
    { key: 'assigned_work_load', title: 'Assigned', super: 'Work Load' },
    { key: 'completed_work_load', title: 'Completed', super: 'Work Load' },
  ].filter((i) => i !== null)
);

watch(whoToShow, () => {
  search();
});

// watch(inTimeFrame, () => {
//   search();
// });

const selectedPreDefColumns = ref([]);

const togglePreDef = (key: string) => {
  if (selectedPreDefColumns.value.includes(key)) {
    selectedPreDefColumns.value = selectedPreDefColumns.value.filter((k) => k !== key);
  } else {
    selectedPreDefColumns.value.push(key);
  }
};

const getDropdown = () => {
  const sections = [];
  sections.push({
    title: 'Static Fields',
    type: 'header',
  });
  cellsToDisplay.value.forEach((item) => {
    sections.push({
      title: item.title,
      selected: selectedPreDefColumns.value.includes(item.key),
      action: (close: () => void) => {
        togglePreDef(item.key);
        close();
      },
    });
  });

  allPublicFormsColumns.value.forEach((publicForm) => {
    publicForm.sections.forEach((section) => {
      sections.push({
        title: section.title,
        type: 'header',
      });
      section.fields.forEach((field) => {
        sections.push({
          title: field.title,
          selected: activeColumnIds.value.has(field.id),
          action: (close: () => void) => {
            toggleColumn(field.id);
            close();
          },
        });
      });
    });
  });

  return sections;
};
const changeUserForShift = async (newUserId: number | null) => {
  if (!props.write) return;
  const shiftInterestPivot = getItemFromArrayBasedOnId(newUserId, props.shift.shift_interest_pivots, null, 'user_id');
  if (shiftInterestPivot) {
    await axios.post(`/api/shift-interest-pivots/${shiftInterestPivot.id}/accept`).catch((error) => {
      toast.warning('Something went wrong, please try again later.');
      console.error(error.response.data);
    });
  } else {
    await patchShift(props.shift.id, {
      user_id: newUserId,
    });
  }

  const user = getItemFromArrayBasedOnId(newUserId, props.crewResources, null, 'model_id');
  emit('updatedShift', {
    id: props.shift.id,
    user_id: newUserId,
    user: user ? { id: user.model_id, name: user.title } : null,
  });
};

const sendReminderSlideOutOpen = ref(false);
const openReminderSlideOut = async () => {
  sendReminderSlideOutOpen.value = false;
  await nextTick();
  sendReminderSlideOutOpen.value = true;
};

const headerActions = computed(() => {
  if (!shiftCrewSlots.value?.length) return [];
  return [
    {
      title: 'Communicate',
      hoverText: 'Communicate with all assignees and assigned crew.',
      icon: 'fa-envelope',
      action: () => {
        openReminderSlideOut();
      },
    },
  ];
});
const crewResourceWithUser = computed(() => {
  let array = [...props.crewResources];
  if (props.shift && props.shift.user_id && props.shift.user) {
    const index = array.findIndex((i) => i.model === 'user' && i.model_id === props.shift.user.id);
    if (index === -1) {
      array.push({
        model: 'user',
        model_id: props.shift.user.id,
        title: props.shift.user.name,
      });
    }
  }
  return array;
});
</script>

<template>
  <div>
    <VButton
      v-if="withButton"
      size="sm"
      @click="toggleModal()">
      {{ buttonText }}
    </VButton>

    <CrudSlideout
      v-if="modalOpen"
      :only-close-button="true"
      :width="shiftCrewSlots && shiftCrewSlots.length > 0 ? '70%' : null"
      :disabled="working"
      :header-actions="headerActions"
      :small="slideOutSmall"
      :medium="!slideOutSmall"
      :base-z-index="1000"
      :loading="working"
      :title="isDisplay ? 'Your shift on ' + formatStampAsHumanReadableDate(shift.start) : 'Assign Shift'"
      :content-classes="
        'assign-festival-shift-slot-modal ' + (shiftCrewSlots && shiftCrewSlots.length > 0 ? 'grid' : '')
      "
      :with-first-input-focus="false"
      create-button-text="Assign Shift"
      @closed="[$emit('closed'), (modalOpen = false)]">
      <div class="h-full space-y-edge overflow-auto bg-content-main p-edge">
        <BoxContainer
          title="General"
          header-size="h3">
          <div class="grid grid-cols-3 gap-edge">
            <div v-if="shift.title || shift.description">
              <InputLabel
                label="Title"
                super-text />
              {{ shift.title }} <br v-if="shift.description && shift.title" />
              <small>
                {{ shift.description }}
              </small>
            </div>
            <div v-if="venues.length > 0 || shift.place_string || shift.place">
              <InputLabel
                label="Where"
                super-text />
              <div class="content">
                {{ getPlaceFromShift() }}
              </div>
            </div>
            <div v-if="isDisplay && shift.events.length > 0">
              <InputLabel
                label="Event(s)"
                super-text />
              <div class="content">
                <InertiaLink
                  v-for="(ev, index) in shift.events"
                  class="cursor-pointer hover:underline"
                  :href="getRoute('events.show', ev.slug)"
                  as="span">
                  {{ ev.name }} {{ index < shift.events.length - 1 ? ', ' : '' }}
                </InertiaLink>
              </div>
            </div>
            <div v-if="!isDisplay && crewResourceWithUser.length > 0">
              <div>
                <VSelect
                  :model-value="shift.user_id"
                  nullable-display-text="Not assigned"
                  nullable
                  :can-edit="write"
                  option-key="model_id"
                  option-value="title"
                  :groups="shift.shift_interest_pivots.length > 0"
                  :options="
                    shift.shift_interest_pivots.length > 0
                      ? [
                          {
                            label: 'Has Requested Shift',
                            options: crewResourceWithUser.filter(
                              (c) =>
                                c.model === 'user' &&
                                shift.shift_interest_pivots.map((p) => p.user_id).includes(c.model_id)
                            ),
                          },
                          {
                            label: 'Others',
                            options: crewResourceWithUser.filter(
                              (c) =>
                                c.model === 'user' &&
                                !shift.shift_interest_pivots.map((p) => p.user_id).includes(c.model_id)
                            ),
                          },
                        ]
                      : crewResourceWithUser
                  "
                  label="Assigned Crew"
                  @update:model-value="changeUserForShift" />
              </div>
            </div>
          </div>
        </BoxContainer>
        <BoxContainer
          v-if="isDisplay"
          title="Check In & Out"
          header-size="h3">
          <div class="grid grid-cols-3 gap-edge-1/2">
            <div v-if="isDisplay">
              <InputLabel
                :label="'Check' + (shift.check_in ? 'ed' : '') + ' In' + (shift.check_in ? ' at' : '')"
                super-text />
              <div
                v-if="shift.check_in"
                :title="shift.check_in">
                {{ formatStampAsHumanReadableDate(shift.check_in) }}
              </div>
              <div
                v-else
                class="content">
                <VButton
                  size="sm"
                  title="Check In"
                  icon="fa-sign-in"
                  :tool-tip-text="canCheckIntoShift(shift) ? 'Check In' : 'You cannot check into shift yet.'"
                  :disabled="!canCheckIntoShift(shift)"
                  @click="openCheckModal(false)" />

                <!--                @click="$emit('showShiftCheckIn')" />-->
              </div>
            </div>
            <div v-if="isDisplay">
              <InputLabel
                :label="'Check' + (shift.check_out ? 'ed' : '') + ' Out' + (shift.check_out ? ' at' : '')"
                super-text />
              <div
                v-if="shift.check_out"
                :title="shift.check_out">
                {{ formatStampAsHumanReadableDate(shift.check_out) }}
                <template v-if="shift.notes">
                  <br />
                  <small class="font-italic">
                    {{ shift.notes }}
                  </small>
                </template>
              </div>
              <div
                v-else
                class="content">
                <VButton
                  size="sm"
                  title="Check Out"
                  icon="fa-sign-out"
                  :tool-tip-text="canCheckOutOfShift(shift) ? 'Check Out' : 'You cannot check out of your shift yet.'"
                  :disabled="!canCheckOutOfShift(shift)"
                  @click="openCheckModal(true)" />
                <!--                @click="$emit('showShiftCheckOut')"-->
              </div>
            </div>
          </div>
        </BoxContainer>
        <BoxContainer
          title="When"
          header-size="h3">
          <div class="grid grid-cols-3 gap-edge-1/2">
            <div v-if="timeSlots.length > 0">
              <InputLabel
                label="Time Slot"
                super-text />
              <div class="content">
                {{ timeSlot ? timeSlot.title : 'N/A' }}
                <i v-if="timeSlot && timeSlot.date">
                  <br />
                  {{ timeSlot.date ? formatStampAsHumanReadableDate(timeSlot.date) : '' }}
                </i>
              </div>
            </div>
            <div>
              <InputLabel
                label="Date"
                super-text />
              <div class="content">
                {{ formatStampAsHumanReadableDate(shift.start) }}
              </div>
            </div>
            <div>
              <InputLabel
                label="When"
                super-text />
              <div class="content">
                {{ getTimeKeyOfShift('start') }} -
                {{ getTimeKeyOfShift('end') }}
              </div>
            </div>
          </div>
        </BoxContainer>

        <BoxContainer
          v-if="!isDisplay"
          title="Responder Slots"
          :actions="
            write && shiftCrewSlots.filter((slot) => slot.slottable_id !== null).length > 0
              ? [
                  {
                    title: 'Clear All Slots',
                    loading: working,
                    icon: 'fa-times',
                    action: () => {
                      unAssignAllSlots();
                    },
                  },
                ]
              : []
          "
          header-size="h3">
          <div class="grid grid-cols-4 gap-[64px]">
            <div>
              <div class="flex justify-between">
                <InputLabel
                  label="Slots"
                  super-text />
                <VButton
                  v-if="newSlotCount < shiftCrewSlots?.length"
                  size="xs"
                  type="warning"
                  :title="'Remove ' + Math.abs(newSlotCount - shiftCrewSlots?.length) + ' slots'"
                  @click="doTheUpdateShiftSlotCount" />
                <div v-if="newSlotsCountUpdateFunction !== null">
                  <i class="fa fa-fw fa-circle-o-notch fa-spin" />
                </div>
              </div>
              <div class="mt-1">
                <NumberInput
                  v-if="write"
                  size="small"
                  :with-controlles="true"
                  :model-value="newSlotCount"
                  @update:model-value="slotCountUpdated" />
                {{ write ? '' : newSlotCount }}
              </div>
            </div>
            <div>
              <div class="flex justify-between">
                <InputLabel
                  label="For Sale"
                  super-text />
                <VButton
                  v-if="newUpForSaleCount < upForSaleSlots?.length"
                  @click="markAsUpForSale">
                  Unmark
                  {{ Math.abs(newUpForSaleCount - upForSaleSlots?.length) }}
                  slots
                </VButton>
                <div v-if="newUpForSaleCountUpdatedFunction !== null">
                  <i class="fa fa-fw fa-circle-o-notch fa-spin" />
                </div>
              </div>
              <div class="mt-1">
                <NumberInput
                  v-if="write"
                  :model-value="newUpForSaleCount"
                  size="small"
                  :with-controlles="true"
                  @update:model-value="forSaleCountUpdated" />
                {{ write ? '' : newUpForSaleCount }}
              </div>
            </div>
            <div>
              <InputLabel
                label="Assigned"
                super-text />
              <div>
                <div class="flex justify-between">
                  {{
                    shiftCrewSlots?.filter(function (s) {
                      return s.slottable_id !== null;
                    }).length
                  }}
                </div>
              </div>
            </div>
            <div>
              <InputLabel
                label="Available"
                super-text />
              <div>
                {{ availableSlots?.length }}
              </div>
            </div>
          </div>
        </BoxContainer>

        <BoxContainer
          v-if="shiftCrewSlots.length > 0"
          title="Assignees"
          :actions="
            [
              unAssignedSlots.length && !isDisplay
                ? {
                    icon: 'fa-plus',
                    title: 'Add Multiple',
                    action: () => {
                      openAddMultipleModal();
                    },
                  }
                : null,
            ].filter((i) => i !== null)
          "
          header-size="h3">
          <template
            v-if="isDisplay"
            #afterTitle>
            <DisplayColoredCount
              :title="
                'Assigned ' +
                shiftCrewSlots.filter(function (s) {
                  return s.slottable_id !== null;
                })
              "
              :second-num="shiftCrewSlots.length"
              :first-num="
                shiftCrewSlots.filter(function (s) {
                  return s.slottable_id !== null;
                }).length
              " />
          </template>
          <div>
            <div
              v-if="shiftCrewSlots?.length > 0"
              class="grid grid-cols-2 gap-edge">
              <ShiftCrewSlot
                v-for="(slot, index) in shiftCrewSlots.map((sh) => ({
                  ...sh,
                  shift,
                }))"
                :key="slot.id"
                :crew-slot="slot"
                :time-slot="timeSlot"
                :festival-section="festivalSection"
                :can-assign="canAssign"
                :can-edit="write"
                :can-check-slots="canCheckSlots"
                @for-sale="updateSlotForSale($event, index)"
                @check-cleared="checkCleared(slot.id)"
                @checked-in="checkedIn"
                @checked-out="checkedOut"
                @updated="updateAssignedCrewSlot"
                @add-user="assignTo($event, slot.id)"
                @un-assigned-slot="unassignSlot($event, slot.id)" />
              <h3
                v-if="!shiftCrewSlots.length"
                key="no-slot-length"
                class="text-center">
                No slots created
              </h3>
            </div>
          </div>
        </BoxContainer>
      </div>
    </CrudSlideout>

    <CrudSlideout
      v-if="showAddManyModal && !isDisplay"
      create-button-text="Assign"
      title="Assign Responders"
      :disabled="!selectedResponders.size"
      :base-z-index="1002"
      @create="addUsersToSlots"
      @closed="showAddManyModal = false">
      <div class="mt-edge flex flex-col gap-edge">
        <div class="border-b px-edge pb-edge">
          <div class="flex justify-between gap-edge-1/2">
            <div class="flex-1 overflow-hidden truncate">
              <InputLabel
                label="Title"
                super-text />
              <TruncateDisplay height="h-[16px]">
                <div
                  class="truncate"
                  :title="shift.title">
                  {{ shift.title }}
                </div>
                <div class="truncate">
                  <small :title="shift.description">
                    {{ shift.description }}
                  </small>
                </div>
              </TruncateDisplay>
            </div>
            <div
              v-if="venues.length > 0 || shift.place_string || shift.place"
              class="flex-1">
              <InputLabel
                label="Where"
                super-text />
              <div class="content">
                {{ getPlaceFromShift() }}
              </div>
            </div>
            <div
              v-if="isDisplay && shift.events.length > 0"
              class="flex-1">
              <InputLabel
                label="Event(s)"
                super-text />
              <div class="content">
                {{
                  arrayToJoinString(
                    shift.events.map(function (e) {
                      return e.name;
                    })
                  )
                }}
              </div>
            </div>
            <div
              v-if="timeSlots.length > 0"
              class="flex-1">
              <InputLabel
                label="Time Slot"
                super-text />
              <div class="content">
                {{ timeSlot ? timeSlot.title : 'N/A' }}
                <i v-if="timeSlot && timeSlot.date">
                  <br />
                  {{ timeSlot.date ? formatStampAsHumanReadableDate(timeSlot.date) : '' }}
                </i>
              </div>
            </div>
            <div class="flex-1">
              <InputLabel
                label="Date"
                super-text />
              <div class="content">
                {{ formatStampAsHumanReadableDate(shift.start) }}
              </div>
            </div>
            <div class="flex-1">
              <InputLabel
                label="When"
                super-text />
              <div class="content">
                {{ getTimeKeyOfShift('start') }} -
                {{ getTimeKeyOfShift('end') }}
              </div>
            </div>
            <div class="flex-1">
              <InputLabel
                label="Assigned"
                super-text />
              <div>
                <div class="flex justify-between">
                  {{
                    shiftCrewSlots?.filter(function (s) {
                      return s.slottable_id !== null;
                    }).length
                  }}
                </div>
              </div>
            </div>
            <div class="flex-1">
              <InputLabel
                label="Available"
                super-text />
              <div>
                {{ availableSlots?.length }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="selectedResponders.size"
          class="border-b">
          <h3 class="px-edge">Selected Responders ({{ selectedResponders.size }} / {{ unAssignedSlots.length }})</h3>
          <VTable edge-to-edge>
            <template #head>
              <VTableRow head>
                <VTableCell />
                <VTableCell main-cell>
                  <div>Name</div>
                </VTableCell>
                <VTableCell
                  v-for="item in cellsToDisplay.filter((c) => selectedPreDefColumns.includes(c.key))"
                  :key="item.key">
                  <div class="flex flex-col">
                    <div
                      v-if="item.super"
                      class="super-header text-xxs text">
                      {{ item.super }}
                    </div>
                    {{ item.title }}
                  </div>
                </VTableCell>
                <VTableCell
                  v-for="column in allColumns.filter((c) => activeColumnIds.has(c.id))"
                  :key="column.id">
                  <div>
                    <div
                      :title="column.SectionTitle"
                      class="max-w-[80px] truncate text-xs">
                      {{ column.SectionTitle }}
                    </div>

                    <div class="max-w-[80px] truncate text-xs">{{ column.title }}</div>
                  </div>
                </VTableCell>
              </VTableRow>
            </template>
            <VTableRow
              v-for="[_, responder] in selectedResponders"
              :key="responder.id">
              <VTableCell classes="w-[22px]">
                <div class="w-[22px]">
                  <i
                    class="fa fa-fw fa-times-circle cursor-pointer"
                    @click="selectedResponders.delete(responder.id)" />
                </div>
              </VTableCell>
              <VTableCell main-cell>
                <div>{{ responder.name }}</div>
              </VTableCell>
              <VTableCell
                v-for="item in cellsToDisplay.filter((c) => selectedPreDefColumns.includes(c.key))"
                :key="item.key">
                <div class="flex flex-col">
                  <div v-if="item.key === 'expected_work_load'">
                    {{ formatMinutesAsHoursAndMinutes(responder.expected_work_minutes) }}
                  </div>
                  <div v-if="item.key === 'assigned_work_load'">
                    {{ formatMinutesAsHoursAndMinutes(responder.assigned_work_minutes) }}
                  </div>
                  <div v-if="item.key === 'completed_work_load'">
                    {{ formatMinutesAsHoursAndMinutes(responder.completed_work_minutes) }}
                  </div>
                  <div v-else>
                    {{ responder[item.key] }}
                  </div>
                </div>
              </VTableCell>
              <VTableCell
                v-for="column in allColumns.filter((c) => activeColumnIds.has(c.id))"
                :key="column.id">
                <div>
                  <VToggle
                    v-if="column.component === 'field-toggle'"
                    :title="
                      responder.public_form_fields.find((field) => field.id === column.id)?.value === '1'
                        ? 'True'
                        : 'False'
                    "
                    :model-value="
                      responder.public_form_fields.find((field) => field.id === column.id)?.value === '1'
                    " />

                  <span
                    v-else-if="column.component === 'field-date'"
                    :title="
                      responder.public_form_fields.find((field) => field.id === column.id)?.value
                        ? formatStampAsDate(responder.public_form_fields.find((field) => field.id === column.id)?.value)
                        : ''
                    ">
                    {{
                      responder.public_form_fields.find((field) => field.id === column.id)?.value
                        ? formatStampAsDate(responder.public_form_fields.find((field) => field.id === column.id)?.value)
                        : ''
                    }}
                  </span>

                  <TruncateDisplay
                    v-else
                    height="h-[40px]">
                    {{ responder.public_form_fields.find((field) => field.id === column.id)?.value }}
                  </TruncateDisplay>
                </div>
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>

        <div class="border-b px-edge [&>.border-b]:border-transparent">
          <TableSearchRow
            v-model:search-term="searchText"
            :columns="getDropdown()"
            :loading="loading"
            @update:search-term="search()">
            <template #afterSearch>
              <TableButtonSelector
                class="w-[150px]"
                :selected-text="whoToShow.replace('_', ' ')"
                :options="
                  [
                    timeSlot !== null
                      ? {
                          id: 'prefers_timeslot',
                          title: 'Prefers Timeslot',
                          hoverTitle: 'Prefers ' + timeSlot.title + ' and does not have any other shifts in time frame',
                          postIcon: whoToShow === 'prefers_timeslot' ? 'fa-check' : '',
                          action: (close) => {
                            whoToShow = 'prefers_timeslot';
                            close();
                          },
                        }
                      : null,
                    {
                      id: 'available',
                      title: 'Available',
                      hoverTitle: 'Available in Time Frame, meaning no other shifts in time frame',
                      postIcon: whoToShow === 'available' ? 'fa-check' : '',
                      action: (close) => {
                        whoToShow = 'available';
                        close();
                      },
                    },
                    {
                      id: 'all',
                      title: 'All',
                      hoverTitle: 'Show All Responders, regardless of availability',
                      postIcon: whoToShow === 'all' ? 'fa-check' : '',
                      action: (close) => {
                        whoToShow = 'all';
                        close();
                      },
                    },
                  ].filter((i) => i !== null)
                " />
              <TableActionFilter
                v-model:field-filters="activeFieldFilters"
                v-model:preference-filters="activePreferenceFilters"
                v-model:loading="loading"
                :preferences="
                  [
                    {
                      id: 'assigned_work_load',
                      name: 'Assigned Work Load',
                    },
                  ].filter((i) => i !== null)
                "
                :sections="
                  allSections.map((publicFormSection) => {
                    return {
                      id: publicFormSection.id,
                      name: publicFormSection.title,
                      type: 'public_form_section',
                      fields: publicFormSection.public_form_fields.map((field) => {
                        return {
                          id: field.id,
                          name: field.title,
                          type: 'public_form_field',
                          component: field.component,
                          options: getKey(field, 'options', []),
                        };
                      }),
                    };
                  })
                "
                @search="search()" />
            </template>
          </TableSearchRow>
        </div>

        <div class="grow-0 overflow-auto">
          <VTable edge-to-edge>
            <template #head>
              <VTableRow head>
                <VTableCell style="width: 30px" />
                <VTableCell
                  v-if="whoToShow === 'all'"
                  style="width: 40px" />
                <VTableCell main-cell>
                  <div>Name</div>
                </VTableCell>
                <VTableCell
                  v-for="item in cellsToDisplay.filter((c) => selectedPreDefColumns.includes(c.key))"
                  :key="item.key">
                  <div class="flex flex-col">
                    <div
                      v-if="item.super"
                      class="super-header text-xxs text">
                      {{ item.super }}
                    </div>
                    {{ item.title }}
                  </div>
                </VTableCell>
                <VTableCell
                  v-for="column in allColumns.filter((c) => activeColumnIds.has(c.id))"
                  :key="column.id">
                  <div>
                    <div
                      :title="column.SectionTitle"
                      class="max-w-[80px] truncate text-xs">
                      {{ column.SectionTitle }}
                    </div>

                    <div class="max-w-[80px] truncate text-xs">{{ column.title }}</div>
                  </div>
                </VTableCell>
              </VTableRow>
            </template>
            <VTableRow
              v-for="responder in allResponders"
              :key="responder.id">
              <VTableCell classes="w-[20px]">
                <div>
                  <SettingCheck
                    :model-value="selectedResponders.has(responder.id)"
                    :disabled="
                      selectedResponders.has(responder.id) || selectedResponders.size >= unAssignedSlots.length
                    "
                    @update:model-value="toggleResponder(responder)" />
                </div>
              </VTableCell>
              <VTableCell
                v-if="whoToShow === 'all'"
                main-cell>
                <div
                  class="text-center [&>div]:m-auto [&>div]:h-[18px] [&>div]:w-[18px] [&>div]:rounded-full [&>div]:text-sm [&>div]:text-[hsl(var(--gray-950))]"
                  :title="getTitleForPublicFormResponseAvailableInSlot(responder, whoToShow)">
                  <div
                    v-if="getKey(responder, 'shift_crew_slots', []).length > 0"
                    class="bg-pending">
                    {{ getKey(responder, 'shift_crew_slots', []).length }}
                  </div>
                  <div
                    v-else
                    class="bg-highlight">
                    {{ getKey(responder, 'shift_crew_slots', []).length }}
                  </div>
                </div>
              </VTableCell>

              <VTableCell main-cell>
                <div v-html="highlightStringBySearch(responder.name, searchText)" />
              </VTableCell>
              <VTableCell
                v-for="item in cellsToDisplay.filter((c) => selectedPreDefColumns.includes(c.key))"
                :key="item.key">
                <div class="flex flex-col">
                  <div v-if="item.key === 'expected_work_load'">
                    {{ formatMinutesAsHoursAndMinutes(responder.expected_work_minutes) }}
                  </div>
                  <div v-if="item.key === 'assigned_work_load'">
                    {{ formatMinutesAsHoursAndMinutes(responder.assigned_work_minutes) }}
                  </div>
                  <div v-if="item.key === 'completed_work_load'">
                    {{ formatMinutesAsHoursAndMinutes(responder.completed_work_minutes) }}
                  </div>
                  <div v-else>
                    {{ responder[item.key] }}
                  </div>
                </div>
              </VTableCell>
              <VTableCell
                v-for="column in allColumns.filter((c) => activeColumnIds.has(c.id))"
                :key="column.id">
                <div>
                  <VToggle
                    v-if="column.component === 'field-toggle'"
                    :title="
                      responder.public_form_fields.find((field) => field.id === column.id)?.value === '1'
                        ? 'True'
                        : 'False'
                    "
                    :model-value="
                      responder.public_form_fields.find((field) => field.id === column.id)?.value === '1'
                    " />

                  <span
                    v-else-if="column.component === 'field-date'"
                    :title="
                      responder.public_form_fields.find((field) => field.id === column.id)?.value
                        ? formatStampAsDate(responder.public_form_fields.find((field) => field.id === column.id)?.value)
                        : ''
                    ">
                    {{
                      responder.public_form_fields.find((field) => field.id === column.id)?.value
                        ? formatStampAsDate(responder.public_form_fields.find((field) => field.id === column.id)?.value)
                        : ''
                    }}
                  </span>

                  <TruncateDisplay
                    v-else
                    height="h-[40px]">
                    {{ responder.public_form_fields.find((field) => field.id === column.id)?.value }}
                  </TruncateDisplay>
                </div>
              </VTableCell>
            </VTableRow>
            <VTableRow v-if="!allResponders.length">
              <VTableCell colspan="100%">
                <div class="px-edge italic">No Responders</div>
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>

        <Paginator
          class="h-context-sidebar-bottom-height border-t bg"
          :loading="loading"
          :per-page="perPageMultiAssign"
          :total-count="totalCountMultiAssign"
          :total-pages="lastPageMultiAssign"
          :current-page="currentPageMultiAssign"
          @change-page="fetchData('', $event)" />
      </div>
    </CrudSlideout>

    <ShiftCheckModal
      v-if="checkModalOpen"
      :allow-updating="true"
      :is-check-out-modal="isCheckOutModal"
      :shift="shift"
      @shift-updated="[
        (shift.check_in = getKey($event, 'check_in', shift.check_in)),
        (shift.check_out = getKey($event, 'check_out', shift.check_out)),
        (shift.notes = getKey($event, 'notes', shift.notes)),
        emit('update-shift', $event),
      ]"
      @checked-in="$emit('checkedIn')"
      @checked-out="$emit('checkedOut')"
      @check-cleared="$emit('checkCleared')"
      @closed="checkModalOpen = false" />

    <ShiftSendReminderSlideOut
      v-if="sendReminderSlideOutOpen"
      :shift="shift"
      :has-s-m-s="hasSMS"
      @closed="sendReminderSlideOutOpen = false" />
  </div>
</template>
