import type { ModelType } from '@/types/globals';
import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { partnerFieldsKey } from '@/provide/keys';
import { formatModel } from '@/util/store-helpers';
import { usePartnerFieldsStore } from '@/store/PartnerFieldsStore';
import { PartnerFieldResource } from '@/types/partners';

export const usePartnerFields = (modelType: ModelType, modelId: number, type: string, shouldProvide = true) => {
  const { list, loading } = storeToRefs(usePartnerFieldsStore());
  const { getForModel, removeById, addOrUpdate } = usePartnerFieldsStore();

  const partnerFields = computed(() => list.value.get(formatModel(modelType, modelId, type)) ?? []);

  const fetchPartnerFields = async (force = false) => {
    await getForModel(modelType, modelId, type, force);
  };

  const addOrUpdateField = async (field: PartnerFieldResource) => {
    await addOrUpdate(modelType, modelId, type, field);
  };

  const removeField = async (fieldId: number) => {
    await removeById(modelType, modelId, type, fieldId);
  };

  if (shouldProvide) {
    provide(partnerFieldsKey, {
      partnerFields: readonly(partnerFields),
      loading: readonly(loading),
      fetchPartnerFields,
      removeField,
      addOrUpdateField,
    });
  }

  return {
    partnerFields,
    fetchPartnerFields,
    loading,
    removeField,
    addOrUpdateField,
  };
};
