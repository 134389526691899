<script lang="ts" setup>
import { twMerge } from 'tailwind-merge';

export type Mode = {
  name: string;
  value: number | string;
  hoverText?: string;
  icon?: string;
};

type Props = {
  greyDefault?: boolean;
  disabled?: boolean;
  modes: Mode[];
  classes?: string;
};

withDefaults(defineProps<Props>(), {
  greyDefault: false,
  disabled: false,
  classes: '',
});

const model = defineModel<string | number | object | null>();
</script>

<template>
  <ul :class="twMerge('flex flex-wrap gap-edge border-b', disabled ? 'opacity-50' : '', classes, $attrs.class)">
    <li
      v-for="mode in modes"
      :key="mode.value"
      :title="mode.hoverText"
      class="flex cursor-pointer items-center text-nowrap border-b-[2px] capitalize"
      :class="[
        disabled || mode.disabled ? '!cursor-not-allowed' : 'cursor-pointer hover:text',
        mode.value === model
          ? 'border-[--border-sub-nav-active] font-semibold text'
          : 'border-transparent font-mediumbold text-soft',
      ]"
      @click.stop="disabled || mode.disabled ? null : (model = mode.value)">
      <i
        v-if="mode.icon"
        class="fa fa-fw mr-1"
        :class="mode.icon" />
      <span class="pb-edge-1/4">
        {{ mode.name }}
      </span>
    </li>
  </ul>
</template>
