<script setup lang="ts">
import VButton from '@/components/Inputs/VButton.vue';
import { uploadFile } from '@/util/file-upload';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
  avatarUrl: {
    type: String,
    default: null,
    required: false,
  },
  maxMBSize: {
    type: Number,
    required: false,
    default: 10,
  },
});
const emits = defineEmits(['update:avatarUrl']);

const loading = ref(false);
const openDialog = async () => {
  loading.value = true;
  try {
    const res = await uploadFile({
      maxUploadSize: props.maxMBSize,
      additionalSettings: {
        crop: '1:1',
        imagesOnly: true,
      },
    });
    if (!res) {
      loading.value = false;
      return;
    }
    await axios.post('/api/profile/avatar', {
      uuid: res.uuid,
      modifier: res.cdnUrlModifiers,
    });
    emits('update:avatarUrl', res.cdnUrl);
    usePage().props.auth.user.avatar_url = res.cdnUrl;
    loading.value = false;
    useToast().success('Uploaded');
  } catch (e) {
    loading.value = false;
  }
};
</script>
<template>
  <div class="flex items-center gap-edge">
    <img
      v-if="!avatarUrl"
      alt="avatar default"
      :src="$page.props.asset_url + 'assets/images/default-avatar.png'"
      class="mx-auto block w-[120px] rounded-full border-2" />
    <img
      v-if="avatarUrl"
      alt="avatar"
      :src="avatarUrl"
      class="mx-auto block aspect-1 w-[120px] rounded-full border-2" />

    <div class="mx-auto space-y-edge-1/2">
      <VButton
        :disabled="loading"
        :loading="loading"
        :emphasized="true"
        title="Upload your photo"
        size="sm"
        @click="openDialog" />
      <p class="text-soft">Profile photos enhance recognition and collaboration within Crescat</p>
    </div>
  </div>
</template>
