<script setup lang="ts">
import { getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId } from '@/util/globals';
import { SharedMetaDataResource } from '@/types/meta-data';
import { computed, ref } from 'vue';
import { ShowTimeResource } from '@/types/show-time';
import FieldsList from '@/components/Fields/FieldsList.vue';
import { useToast } from 'vue-toastification';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { useEmitStore } from '@/store/EmitStore';

type Props = {
  canEdit?: boolean | null;
  inviteId: number;
  metaData: SharedMetaDataResource[];
  showTimes: ShowTimeResource[];
};

const props = withDefaults(defineProps<Props>(), {
  group: null,
  canEdit: true,
});

const loading = ref(false);

const notTooManyFields = computed(() => {
  return (
    props.metaData.flatMap((m) => {
      return m.fields;
    }).length < 15
  );
});

const superHeader = (m) => {
  if (m.show_time_id && props.showTimes.length > 0) {
    return getItemFromArrayBasedOnId(m.show_time_id, props.showTimes, {
      title: 'N/A',
    }).title;
  }
  return null;
};

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'meta-data-field-value-updated': {
      const field = state.item?.payload;
      if (!field) return;
      const metaDataIndex = getIndexFromArrayBasedOnId(field.meta_data_id, props.metaData);
      if (metaDataIndex === -1) return;
      const metaDataFieldIndex = getIndexFromArrayBasedOnId(field.id, props.metaData[metaDataIndex].fields);
      if (metaDataFieldIndex === -1) return;
      props.metaData[metaDataIndex].fields[metaDataFieldIndex].value = field.value;
      break;
    }
    default:
      break;
  }
});

const assignValue = async (value, metaDataField) => {
  if (!metaDataField.write) return;
  if (metaDataField.value === value) return;
  await axios.patch(`/api/meta-data-fields/${metaDataField.id}/input`, {
    value,
  });
  useToast().success('Updated');
  metaDataField.value = value;
  useEmitStore().rootEmit('meta-data-field-value-updated', { ...metaDataField });
};
</script>
<template>
  <div
    v-if="!loading && metaData.length"
    class="flex flex-col gap-edge px-edge">
    <BoxContainer
      v-for="meta in metaData"
      :key="meta.id"
      :openable="!notTooManyFields"
      :loading="loading"
      :default-open="notTooManyFields"
      :super-text="superHeader(meta)"
      :title="meta.title">
      <FieldsList
        :model-value="meta.fields"
        allow-fields-to-override-can-edit
        @assign-value="assignValue" />
    </BoxContainer>
  </div>
</template>
