export const documentIsImage = (extension: string | null = null) => {
  const images = ['png', 'jpeg', 'gif', 'jpg', 'bmp'];
  return !!(extension && images.includes(extension ?? ''));
};
export const getFileTypeIcon = (extension: string | null = null, loading = false) => {
  if (loading) {
    return 'fa-spin fa-circle-o-notch';
  }
  if (documentIsImage(extension)) {
    return 'fa-picture-o';
  }

  return 'fa-file';
};
