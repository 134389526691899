<script lang="ts" setup>
import { inject, reactive, ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { groupCreatedEventsCount } from '@/provide/keys';

const {
  eventStartDate,
  eventId,
  eventName,
  isSimple = false,
  hasPartners = false,
  hasShifts = false,
  hasMetaData = false,
  hasTasks = false,
  hasPerformances = false,
  isVenue = false,
} = defineProps<{
  eventStartDate: string;
  eventId: number;
  eventName: string;
  isSimple?: boolean;
  hasPartners?: boolean;
  hasMetaData?: boolean;
  hasShifts?: boolean;
  hasTasks?: boolean;
  hasPerformances?: boolean;
  isVenue?: boolean;
}>();

const emit = defineEmits<{
  closed: [];
  duplicated: [];
}>();

const elementsToDuplicate = reactive({
  invites: true,
  assignments: true,
  room_bookings: true,
  boards: true,
  forms: true,
  running_orders: true,
  show_times: true,
  with_key_contacts: true,
  shifts: true,
  shiftsAssigned: false,
  metaDataValues: false,
  tasksValue: false,
  withPartners: false,
  hasTasks: true,
  connectPerformances: false,
});

const duplicateDates = ref<string[]>([]);
const createdEventsCount = inject(groupCreatedEventsCount);

const loading = ref(false);

const duplicate = async (close: () => void) => {
  loading.value = true;

  for (let i = 0; i < duplicateDates.value.length; i++) {
    await duplicateDate(duplicateDates.value[i]);
  }
  useToast().success('Duplicated event');
  emit('duplicated');
  loading.value = false;
  close();
};
const duplicateDate = async (date) => {
  await axios.post(`/api/events/${eventId}/duplicate`, {
    date,
    with_invites: !isSimple ? elementsToDuplicate.invites : false,
    with_assignments: !isSimple ? elementsToDuplicate.assignments : false,
    with_room_bookings: !isSimple ? elementsToDuplicate.room_bookings : true,
    with_boards: !isSimple ? elementsToDuplicate.boards : false,
    with_forms: !isSimple ? elementsToDuplicate.forms : false,
    with_runningorders: !isSimple ? elementsToDuplicate.running_orders : false,
    with_show_times: !isSimple ? elementsToDuplicate.show_times : false,
    with_key_contacts: !isSimple ? elementsToDuplicate.with_key_contacts : false,
    with_shifts: !isSimple ? elementsToDuplicate.shifts : false,
    with_shifts_assigned: elementsToDuplicate.shifts && !isSimple ? elementsToDuplicate.shiftsAssigned : false,
    with_meta_data_values: !isSimple ? elementsToDuplicate.metaDataValues : false,
    with_partners: isSimple ? true : elementsToDuplicate.withPartners,
    with_tasks: isSimple || !hasTasks ? false : elementsToDuplicate.tasksValue,
    connect_performances: isSimple || !hasPerformances ? false : elementsToDuplicate.connectPerformances,
  });
  createdEventsCount?.eventCreated();
};
</script>

<template>
  <CrudModal
    :title="`Duplicate ${eventName}`"
    :title-highlight="eventName"
    :create-button-text="'Duplicate (' + duplicateDates.length + ')'"
    :disabled="!duplicateDates.length"
    :loading="loading"
    @create="duplicate"
    @closed="$emit('closed')">
    <div class="grid grid-cols-[1fr_295px] gap-edge-1/4">
      <div
        v-if="!isSimple"
        class="flex flex-col gap-edge">
        <div class="space-y-edge-1/2">
          <h4>Event elements</h4>
          <SettingCheck
            v-model="elementsToDuplicate.assignments"
            label="Assignments" />
          <SettingCheck
            v-model="elementsToDuplicate.boards"
            label="Boards" />
          <SettingCheck
            v-model="elementsToDuplicate.forms"
            label="Forms" />
          <SettingCheck
            v-model="elementsToDuplicate.running_orders"
            label="Running Orders" />
          <SettingCheck
            v-model="elementsToDuplicate.show_times"
            label="Show Times" />
          <SettingCheck
            v-model="elementsToDuplicate.with_key_contacts"
            label="Key Contacts" />
        </div>
        <div class="space-y-edge-1/2">
          <h4>People</h4>
          <SettingCheck
            v-model="elementsToDuplicate.invites"
            label="Invites" />
          <SettingCheck
            v-if="hasShifts"
            v-model="elementsToDuplicate.shifts"
            label="Shifts" />
          <SettingCheck
            v-if="hasShifts"
            v-model="elementsToDuplicate.shiftsAssigned"
            :disabled="!elementsToDuplicate.shifts"
            label="Assign Shift(s)" />
        </div>
        <div
          v-if="hasMetaData || hasPartners || isVenue || hasTasks"
          class="space-y-edge-1/2">
          <h4>Internal</h4>
          <SettingCheck
            v-if="isVenue"
            v-model="elementsToDuplicate.room_bookings"
            label="Room Bookings" />
          <SettingCheck
            v-if="hasMetaData"
            v-model="elementsToDuplicate.metaDataValues"
            label="Meta Data Field Values" />
          <SettingCheck
            v-if="hasTasks"
            v-model="elementsToDuplicate.tasksValue"
            label="In-complete tasks" />
          <SettingCheck
            v-if="hasPartners"
            v-model="elementsToDuplicate.withPartners"
            title="If enabled, contacts and companies added to the event will be included when duplicating."
            label="Contacts & Companies" />
          <SettingCheck
            v-if="hasPerformances"
            v-model="elementsToDuplicate.connectPerformances"
            title="If enabled, performances added to the event will be included when duplicating."
            label="Performances" />
        </div>
      </div>
      <div class="flex flex-col items-center">
        <VDatepicker
          v-model="duplicateDates"
          inline
          :highlight-days="[eventStartDate]"
          :start-date="eventStartDate"
          multi-dates />
        <div class="-mx-edge-2x mt-edge-1/4 h-[250px] w-full overflow-auto">
          <template v-if="duplicateDates.length">
            <p class="py-edge-1/4 text-soft">Selected Dates: {{ duplicateDates.length }}</p>
            <VTable
              table-border="horizontal"
              class="border-x">
              <VTableRow
                v-for="(date, index) in duplicateDates"
                :key="date">
                <VTableCell main-cell>{{ date }}</VTableCell>
                <VTableCell style="width: 50px">
                  <VButton
                    icon="fa-trash"
                    @click="duplicateDates.splice(index, 1)" />
                </VTableCell>
              </VTableRow>
            </VTable>
          </template>
        </div>
      </div>
    </div>
  </CrudModal>
</template>
