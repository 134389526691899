import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import { MetaDataModelType, MetaDataResource } from '@/types/meta-data';
import type { ModelType } from '@/types/global';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';

export const metaDataStore = defineStore('meta-data-store', () => {
  const list = ref<Map<string, MetaDataResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: MetaDataModelType, modelId: number) => {
    Echo.channel(`private-${model}.${modelId}`)
      .listen(`.metaData.created`, async () => {
        await fetchMetaData(model, modelId, true);
      })
      .listen(`.metaData.updated`, async () => {
        await fetchMetaData(model, modelId, true);
      })
      .listen(`.metaData.deleted`, async () => {
        await fetchMetaData(model, modelId, true);
      });
  };

  const fetchMetaData = async (model: MetaDataModelType, modelId: number, force: boolean = false) => {
    if (!force && list.value.has(formatModel(model, modelId))) return list.value.get(formatModel(model, modelId));

    loading.value = true;

    const { data } = await axios.get<MetaDataResource[]>('/api/meta-data', {
      params: {
        model_type: 'App\\' + model,
        model_id: modelId,
      },
    });

    if (!list.value.has(formatModel(model, modelId))) {
      listenForBroadcast(model, modelId);
    }

    list.value.set(formatModel(model, modelId), data);

    loading.value = false;

    return data;
  };

  const addOrUpdateMetaData = async (modelType: ModelType, modelId: number, metaData: MetaDataResource) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await fetchMetaData(modelType, modelId);
      return;
    }
    let data = list.value.get(formatModel(modelType, modelId));
    data = exchangeValuesOfObject(metaData, data);
    list.value.set(formatModel(modelType, modelId), data);
  };

  const removeMetaData = async (modelType: ModelType, modelId: number, metaDataId: number) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await fetchMetaData(modelType, modelId);
      return;
    }
    const data = list.value.get(formatModel(modelType, modelId));
    const index = getIndexFromArrayBasedOnId(metaDataId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(formatModel(modelType, modelId), data);
    } else {
      await fetchMetaData(modelType, modelId);
    }
  };

  return {
    list,
    loading,
    fetchMetaData,
    addOrUpdateMetaData,
    removeMetaData,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(metaDataStore, import.meta.hot));
}
