<script setup lang="ts">
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import { computed } from 'vue';

type Props = {
  modelValue?: string | number | null;
  canEdit?: boolean;
  withButtons?: boolean;
  isHidden?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  canEdit: false,
  withButtons: true,
  isHidden: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | null): void;
  (e: 'blur', value: string | null): void;
}>();

const phone = computed(() => {
  const obj = JSON.parse(props.modelValue);
  if (obj?.phone) return obj.phone;
  return '';
});

const countryCode = computed(() => {
  const obj = JSON.parse(props.modelValue);
  if (obj?.country_code) return obj.country_code;
  return '';
});

const phoneBlur = (e: string) => {
  if (!props.canEdit) return;

  const obj = JSON.parse(props.modelValue);
  if (!obj) {
    emit(
      'blur',
      JSON.stringify({
        phone: e,
        country_code: '',
      })
    );
    return;
  }

  emit('blur', JSON.stringify({ ...obj, phone: e }));
};

const countryCodeBlur = (e: string) => {
  if (!props.canEdit) return;

  const obj = JSON.parse(props.modelValue);
  if (!obj) {
    emit(
      'blur',
      JSON.stringify({
        phone: '',
        country_code: e,
      })
    );
    return;
  }

  emit('blur', JSON.stringify({ ...obj, country_code: e }));
};
</script>

<template>
  <PhoneInput
    :can-edit="canEdit"
    :phone="phone"
    :country-code="countryCode"
    :label="null"
    :size="'block'"
    :is-hidden="isHidden"
    @phone-blur="phoneBlur"
    @update:country-code="countryCodeBlur" />
</template>
