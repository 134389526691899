<script lang="ts" setup>
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import type { EventRequestForm } from '@/types/event-request';
import { arrayToJoinString, getKey } from '@/util/globals';
import { changeAndFormatStamp, formatStampAsDate, getNow, timeStampsAreSame } from '@/util/timeFunctions';
import { useSmallScreen } from '@/composables/use-small-screen';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import EventCreateDateSelector from '@/components/Groups/partials/EventCreateDateSelector.vue';
import { hasAccessToFeatureInTesting } from '@/variables/feature-testing-constants';

type Props = {
  modelValue: EventRequestForm | null;
  editMode: boolean;
  isTemplate: boolean;
  newRequest: boolean;
  sections: [];
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:modelValue', arg: EventRequestForm): void;
}>();

const { isSmallScreen } = useSmallScreen();

const emitValue = async (item: string, value: string) => {
  if (!props.editMode || !props.modelValue) return;

  switch (item) {
    case 'name': {
      emit('update:modelValue', { ...props.modelValue, name: value });
      break;
    }
    case 'description': {
      emit('update:modelValue', { ...props.modelValue, description: value });
      break;
    }
    case 'start': {
      let data = { ...props.modelValue, start: value };
      if (!data.end) {
        data.end = changeAndFormatStamp({
          stamp: value,
          addMinutes: 60,
        });
      }
      emit('update:modelValue', data);
      break;
    }
    case 'end': {
      emit('update:modelValue', { ...props.modelValue, end: value });
      break;
    }
    default: {
      return;
    }
  }
};
const certainToUpdateStart = async (newStart: string) => {
  if (!props.modelValue.start) return true;

  if (timeStampsAreSame(newStart, props.modelValue.start, 'day')) return true;

  const hasItemsThatWillClear = props.sections
    .filter((section) => {
      return ['recurringDates', 'alternativeDates'].includes(getKey(section, 'type', ''));
    })
    .filter((section) => {
      const content = getKey(section, 'content', '');
      return content && content.length > 0;
    });

  return hasItemsThatWillClear.length > 0
    ? await useCertaintyModal().assertCertain(
        'Change Date',
        'This will clear all ' +
          arrayToJoinString(
            hasItemsThatWillClear
              .map((s) => {
                switch (getKey(s, 'type', '')) {
                  case 'recurringDates':
                    return 'recurring';
                  case 'alternativeDates':
                    return 'alternative';
                }
                return null;
              })
              .filter((i) => i !== null)
          ) +
          ' dates. Are you sure?'
      )
    : true;
};
</script>

<template>
  <BoxContainer
    :content-padding="false"
    data-intro="event-request-general-info">
    <div>
      <InputLabel
        v-if="newRequest"
        :label="'Section 1 of ' + (sections.length + 2)"
        super-text />
      <h3 class="mb-edge-1/2">Event Details</h3>
    </div>
    <div
      :class="{ 'grid grid-cols-3 gap-edge': !isSmallScreen, 'flex flex-col gap-edge': isSmallScreen }"
      class="">
      <TextInput
        id="event-request-general-info-event-name"
        :can-edit="editMode && !isTemplate"
        :max-length="130"
        :model-value="modelValue?.name || null"
        label="Name of Event"
        mandatory-text="Required. Name must be at least 2 characters long."
        required
        @blur="emitValue('name', $event)" />

      <div
        v-if="hasAccessToFeatureInTesting()"
        class="col-span-2">
        <EventCreateDateSelector
          :start-date="formatStampAsDate(modelValue?.start)"
          :end-date="formatStampAsDate(modelValue?.end)"
          :start="modelValue?.start"
          :end="modelValue?.end"
          @update:start="emitValue('start', $event)"
          @update:end="emitValue('end', $event)" />
      </div>

      <div
        v-if="!hasAccessToFeatureInTesting()"
        class="col-span-2 grid gap-edge md:grid-cols-[1fr_110px_1fr]">
        <StartEndPicker
          :allow-no-duration="false"
          :allow-update-of-start="certainToUpdateStart"
          :can-edit="editMode && !isTemplate"
          :earliest-end="getNow()"
          :earliest-start="getNow()"
          :end="modelValue?.end"
          :start="modelValue?.start"
          layout-outside
          with-duration
          :duration-options="[60, 2 * 60, 4 * 60, 6 * 60, 8 * 60, 10 * 60, 12 * 60]"
          required
          with-time
          @update:start="emitValue('start', $event)"
          @update:end="emitValue('end', $event)" />
      </div>

      <TextareaInput
        :can-edit="editMode && !isTemplate"
        :min-rows="3"
        :model-value="modelValue?.description"
        class="col-span-3"
        label="Event Description"
        @blur="emitValue('description', $event)" />
    </div>
  </BoxContainer>
</template>
