import { getCurrentInstance } from 'vue';

export const updateQueryString = (queryString: string | null, open = false) => {
  setQueryParameter('open', queryString && open ? queryString : null);
};

export const setQueryParameter = (key: string | null, param: string | null) => {
  if (!key) return;
  const url = new URL(window.location.href);
  if (param) {
    url.searchParams.set(key, param);
  } else {
    url.searchParams.delete(key);
  }
  window.history.replaceState(null, '', url);
};

export const getQueryString = (overrideQueryString = null) => {
  if (overrideQueryString) return overrideQueryString;
  try {
    const instance = getCurrentInstance();
    const props = instance?.parent?.props;
    if (!props) return null;

    const componentFileName = instance?.parent?.type.__name;
    const items = [
      {
        file: 'MetaData',
        model: 'MetaData',
        prop: 'metaData',
      },
      {
        file: 'CheckList',
        model: 'CheckList',
        prop: 'modelValue',
      },
      {
        file: 'InvoiceBase',
        model: 'InvoiceBase',
        prop: 'invoiceBase',
      },
      {
        file: 'RunningOrder',
        model: 'RunningOrder',
        prop: 'runningOrder',
      },
      {
        file: 'Board',
        model: 'Board',
        prop: 'board',
      },
      {
        file: 'TimelineTemplate',
        model: 'TimelineTemplate',
        prop: 'template',
      },
      {
        file: 'CustomForm',
        model: 'Form',
        prop: 'form',
      },
      {
        file: 'EventRequestTemplate',
        model: 'EventRequestTemplate',
        prop: 'eventRequestTemplate',
      },
      {
        file: 'EventTypeCreator',
        model: 'EventType',
        prop: 'eventType',
      },
      {
        file: 'PerformanceSection',
        model: 'PerformanceInfoSection',
        prop: 'section',
      },
      {
        file: 'AdvanceSection',
        model: 'AdvanceSection',
        prop: 'advanceSection',
      },
      {
        file: 'TaskTemplateList',
        model: 'TaskTemplateList',
        prop: 'taskTemplateList',
      },
    ];
    const item = items.find((i) => componentFileName?.includes(i.file));
    if (item) {
      return formatQueryString(item.model, props[item.prop].id);
    }
  } catch (__) {}
  return null;
};

export const formatQueryString = (model: string | null, id: number | null) => {
  if (!model || !id) return null;
  return model + '_' + id;
};

export const shouldBeOpened = (queryString: string | null) => {
  if (queryString) {
    const url = new URL(window.location.href);
    if (url.searchParams.get('open') === queryString) {
      return true;
    }
  }
  return false;
};

export const checkForQueryParameter = (queryParameter: string | null, removeAfter = false) => {
  if (queryParameter) {
    const url = new URL(window.location.href);
    const queryString = url.searchParams.get(queryParameter);
    if (queryString) {
      setTimeout(() => {
        if (removeAfter) {
          url.searchParams.delete(queryParameter);
          window.history.replaceState(null, '', url);
        }
      }, 200);
      return queryString;
    }
  }
  return null;
};
