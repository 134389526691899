<script setup lang="ts">
import { getCssVariable } from '@/util/globals';

type Props = {
  text?: string | null;
  toolTip?: string | null;
  preIcon?: string | null;
  postIcon?: string | null;
  color?: string | null;
  classes?: string | null;
  size?: string | null;
  overrideColor?: string | null;
  borderOnly?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  text: null,
  color: null,
  toolTip: null,
  preIcon: null,
  postIcon: null,
  classes: null,
  size: null,
  overrideColor: null,
  borderOnly: true,
});

const getClasses = () => {
  let classList = '';
  if (props.classes) {
    classList += props.classes;
  }
  const backgroundOpacity = getCssVariable('--badge-background-opacity');
  if (!props.overrideColor) {
    if (props.borderOnly) {
      classList += ' border-[1px] text ';
    }

    switch (props.color) {
      case 'turquoise': {
        classList += ' bg-turquoise text-[hsl(var(--gray-950))] ';
        break;
      }
      case 'gray': {
        if (props.borderOnly) {
          classList += ' border-textColor-soft text-soft  bg-textColor-soft/' + backgroundOpacity;
        } else {
          classList += ' bg-textColor-soft text-[hsl(var(--gray-950))] ';
        }
        break;
      }
      case 'blue':
      case 'bg-blue': {
        if (props.borderOnly) {
          classList += ' border-blue bg-blue/' + backgroundOpacity;
        } else {
          classList += ' bg-blue text-[hsl(var(--gray-950))] ';
        }
        break;
      }
      case 'pending':
      case 'bg-pending':
      case 'bg-yellow':
      case 'yellow': {
        if (props.borderOnly) {
          classList += ' border-pending bg-pending/' + backgroundOpacity;
        } else {
          classList += ' bg-pending !text-[hsl(var(--gray-950))] ';
        }
        break;
      }
      case 'warning':
      case 'bg-warning':
      case 'danger':
      case 'bg-danger':
      case 'red':
      case 'bg-red': {
        if (props.borderOnly) {
          classList += ' border-warning bg-warning/' + backgroundOpacity;
        } else {
          classList += ' bg-warning text-[hsl(var(--gray-950))] ';
        }
        break;
      }
      case 'transparent': {
        classList += ' bg-transparent text border ';
        break;
      }
      case 'black': {
        classList += ' bg-black text-[hsl(var(--blue-100))] border ';
        break;
      }
      case 'white': {
        if (props.borderOnly) {
          classList += ' border-white border';
        } else {
          classList += ' bg-white !text-[hsl(var(--gray-950))] border ';
        }

        break;
      }
      case 'success': {
        if (props.borderOnly) {
          classList += ' border-success bg-success/' + backgroundOpacity;
        } else {
          classList += ' bg-success text-[hsl(var(--gray-950))] ';
        }
        break;
      }

      case 'green':
      case 'highlight':
      case 'bg-highlight':
      case 'bg-green':
      default: {
        if (props.borderOnly) {
          classList += ' border-highlight bg-highlight/' + backgroundOpacity;
        } else {
          classList += ' bg-highlight text-[hsl(var(--gray-50))] ';
        }
        break;
      }
    }
  }

  switch (props.size) {
    case 'large':
      classList += ' text-lg px-edge py-edge-1/4 ';
      break;
    case 'medium':
      classList += ' text-base px-edge py-edge-1/4 ';
      break;
    case 'tiny':
      classList += ' text-xs px-edge-1/2 ';
      break;
    case 'sm':
    case 'small':
    default:
      classList += ' text-xs px-edge-1/2 py-1 ';
      break;
  }

  return classList;
};
</script>

<template>
  <div
    v-if="text"
    :title="toolTip ? toolTip : text"
    class="truncate rounded text-center font-headers"
    :style="overrideColor ? 'background-color: ' + overrideColor + '; color: black;' : ''"
    :class="getClasses()">
    <slot>
      <i
        v-if="preIcon"
        class="fa fa-fw"
        :class="preIcon"></i>
      {{ text }}
      <i
        v-if="postIcon"
        class="fa fa-fw"
        :class="postIcon"></i>
    </slot>
  </div>
</template>
