<script lang="ts" setup>
import { getGroupProjectLeaders } from '@/services/api-group';
import { GroupMinimalResource, ProjectLeader } from '@/types/group';
import { ref } from 'vue';
import SearchSelector from '@/components/Inputs/SearchSelector.vue';
import VSelect from './Inputs/VSelect.vue';

type Props = {
  withProjectLeaders?: boolean;
  initSearchString?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  withProjectLeaders: true,
  initSearchString: '',
});

const emit = defineEmits<{
  (e: 'selectedGroup', group: GroupMinimalResource, projectLeader?: ProjectLeader): void;
}>();

const params = {
  searching: true,
  root_only: true,
};

const showProjectLeaderModal = ref(false);
const projectLeaders = ref<ProjectLeader[]>([]);
const selectedGroup = ref<GroupMinimalResource | null>(null);

const onGroupSelected = async (group: GroupMinimalResource) => {
  if (group.using_project_leaders && props.withProjectLeaders) {
    const { data: ProjectLeaders } = await getGroupProjectLeaders(group.id);
    if (!ProjectLeaders.length || !group.force_project_leader_on_invite) {
      emit('selectedGroup', group);
    } else {
      projectLeaders.value = ProjectLeaders;
      selectedGroup.value = group;
      showProjectLeaderModal.value = true;
    }
  } else {
    emit('selectedGroup', group);
  }
};

const selectProjectLeader = (close: () => void, user: ProjectLeader | number) => {
  if (!selectedGroup.value) return;

  let userObject = user;
  if (!userObject?.id) {
    userObject = projectLeaders.value.find((pl) => pl.id === user);
  }
  emit('selectedGroup', selectedGroup.value, userObject as ProjectLeader);
  close();
};

const close = () => {
  showProjectLeaderModal.value = false;
};
</script>

<template>
  <div>
    <SearchSelector
      url="/api/groups"
      placeholder="Search for Groups"
      :params="params"
      :init-search-string="initSearchString"
      @selected="onGroupSelected" />
    <div
      v-if="showProjectLeaderModal && selectedGroup"
      class="mt-edge">
      <div class="sub-title mb-edge font-bold">
        {{ selectedGroup?.name }} uses project leaders to organise their work. Who have you spoken to?
      </div>
      <template v-if="projectLeaders.length">
        <VSelect
          :model-value="null"
          :options="projectLeaders"
          :required="true"
          :is-hidden="false"
          @update:model-value="selectProjectLeader(close, $event)" />
        <div class="sub-title mt-edge font-bold">
          If you can not find your contact in the group, please contact the group again.
        </div>
      </template>
    </div>
  </div>
</template>
