import { InviteResource } from '@/types/invite';
import { isAdminOfGroup, isReadOfGroup } from '@/util/group-helpers';
import { getKey } from '@/util/globals';

type IntermediateStepTab = {
  id: string;
  title: string;
  icon: string;
  description: string | null;
};

export const createIntermediateStepTabs = (currentInvite: InviteResource, overrideAdmin: false) => {
  if (!currentInvite.invitable) return [];

  const isAdmin = overrideAdmin ? true : isAdminOfGroup(currentInvite.invitable.id, currentInvite.invitable.parent_id);
  const isRead = overrideAdmin ? true : isReadOfGroup(currentInvite.invitable.id, currentInvite.invitable.parent_id);

  const tabs: IntermediateStepTab[] = [
    {
      id: 'general-info',
      title: 'General Info',
      icon: 'fa-info',
      description: null,
    },
  ];
  if (currentInvite && currentInvite.festival) {
    tabs.push({
      id: 'festival',
      icon: 'fa-tent fa-regular',
      title: 'Shifts',
      description: 'All info regarding your festival.',
    });
  }

  const isFestivalWithHideMembers = getKey(currentInvite.festival, 'hide_members', false);
  if ((isAdmin || isRead) && !isFestivalWithHideMembers) {
    tabs.push({
      id: 'members',
      icon: 'fa-user-group fa-regular',
      title: 'Members',
      description: 'Members of your group.',
    });
  }

  const isVenue = currentInvite && currentInvite.invitable && currentInvite.invitable.venue_id !== null;

  if ((!!currentInvite?.festival || isVenue) && isAdmin) {
    tabs.push({
      id: 'rooms',
      icon: 'fa-cube',
      title: 'Rooms',
      description: 'Rooms of your Venue.',
    });
  }

  const accessLevels = currentInvite?.access_levels;
  const isFestivalWithHideMetaData = getKey(currentInvite.festival, 'hide_meta_data', false);
  if (accessLevels && accessLevels.has_meta_data && !isFestivalWithHideMetaData) {
    tabs.push({
      id: 'meta-data',
      icon: 'fa-table',
      title: 'Meta Data',
      description: 'Create, edit, and download reports for various sets of metadata.',
    });
  }

  const isFestivalWithHideCheckLists = getKey(currentInvite.festival, 'hide_check_lists', false);
  if (accessLevels && accessLevels.has_check_lists && !isFestivalWithHideCheckLists) {
    tabs.push({
      id: 'check-lists',
      icon: 'fa-list-check',
      title: 'Check Lists',
      description: 'Create and edit reusable checklists that can be added to any event.',
    });
  }
  if (accessLevels && accessLevels.has_performances && !currentInvite.festival) {
    tabs.push({
      id: 'performances',
      icon: 'fa-music fa-regular',
      title: 'Performances',
      description: '.',
    });
  }

  const isFestivalWithHideInvoices = getKey(currentInvite.festival, 'hide_invoices', false);
  if (
    isAdmin &&
    accessLevels &&
    accessLevels.has_invoice_bases &&
    currentInvite.parent_id === null &&
    !isFestivalWithHideInvoices
  ) {
    tabs.push({
      id: 'invoices',
      icon: 'fa-money',
      title: 'Invoices',
      description:
        'Add reusable products with quantities and prices per unit. The purchase of these units can be tracked in an event.',
    });
  }
  if (isAdmin && accessLevels.has_performances && currentInvite.parent_id === null && !currentInvite.festival) {
    tabs.push({
      id: 'guest-lists',
      icon: 'fa-ticket fa-regular',
      title: 'Guest Lists',
      description: 'Guest Lists.',
    });
  } else if (accessLevels.use_guest_lists && currentInvite.parent_id !== null && !currentInvite.festival) {
    tabs.push({
      id: 'guest-lists',
      icon: 'fa-ticket fa-regular',
      title: 'Guest Lists',
      description: 'Guest Lists.',
    });
  }

  const isFestivalWithHideDocuments = getKey(currentInvite.festival, 'hide_documents', false);
  if (!isFestivalWithHideDocuments) {
    tabs.push({
      id: 'documents',
      icon: 'fa-file-o',
      title: 'Documents',
      description: 'Documents.',
    });
  }

  const isFestivalWithTasks = getKey(currentInvite.festival, 'has_tasks', false);
  if (accessLevels && accessLevels.has_tasks && (!currentInvite.festival || isFestivalWithTasks)) {
    tabs.push({
      id: 'tasks',
      icon: 'fa-tasks fa-regular',
      title: 'Tasks',
      description: 'Tasks.',
    });
  }

  return tabs;
};
