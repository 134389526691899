import axios from 'axios';
import NProgress from 'nprogress';
import { router } from '@inertiajs/vue3';
import _ from 'lodash';

if (import.meta.env.MODE !== 'development') {
  window._ = _;
}

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const nProgressUsedSomewhereElseSessionStorage = 'nProgressUsedSomewhereElse';
const nProgressCurrentlyRunSomewhereElse = () => {
  const nProgressUsedSomewhereElse = sessionStorage.getItem(nProgressUsedSomewhereElseSessionStorage);
  return !!(nProgressUsedSomewhereElse && nProgressUsedSomewhereElse === 'true');
};

// intercept all axios post request
axios.interceptors.request.use((config) => {
  if (config.method && ['post', 'put', 'patch', 'delete'].includes(config.method)) {
    if (!config.url?.includes('session-ended') && !config.url?.includes('/broadcasting/auth')) {
      document.documentElement.style.setProperty('--loading-background', 'hsl(var(--color-success))');
      if (!NProgress.isStarted() && !nProgressCurrentlyRunSomewhereElse()) {
        NProgress.start();
      }
    }
  }
  return config;
});

// intercept all axios response
axios.interceptors.response.use(
  (response) => {
    if (response.config.method && ['post', 'put', 'patch', 'delete'].includes(response.config.method)) {
      if (!nProgressCurrentlyRunSomewhereElse()) {
        NProgress.done();
      }
    }
    return response;
  },
  (error) => {
    if (error.config.method && ['post', 'put', 'patch', 'delete'].includes(error.config.method)) {
      NProgress.done();
    }
    return Promise.reject(error);
  }
);

let timeout = null;

router.on('start', () => {
  document.documentElement.style.setProperty('--loading-background', '#29d');
  timeout = setTimeout(() => NProgress.start(), 1);
});

router.on('progress', (event) => {
  if (NProgress.isStarted() && event.detail.progress.percentage) {
    NProgress.set((event.detail.progress.percentage / 100) * 0.9);
  }
});

router.on('finish', (event) => {
  clearTimeout(timeout);
  if (!NProgress.isStarted()) {
    return;
  } else if (event.detail.visit.completed) {
    NProgress.done();
  } else if (event.detail.visit.interrupted) {
    NProgress.set(0);
  } else if (event.detail.visit.cancelled) {
    NProgress.done();
    NProgress.remove();
  }
});
