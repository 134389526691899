<script setup lang="ts">
import { computed, onMounted, provide, ref, watch } from 'vue';
import Sortable from 'sortablejs';
import { useToast } from 'vue-toastification';
import VButton from '@/components/Inputs/VButton.vue';
import ContentHeader from '@/components/Content/ContentHeader.vue';
import { safeHtmlStringify } from '@/util/safe-html-stringify';
import { inputInTable } from '@/provide/keys';

export type TableHeader = {
  key: string;
  label: string;
  sortable?: boolean;
  html?: string;
  labelPosition?: 'left' | 'right' | 'center';
  class?: string;
};

export type TableCell = {
  id: string | number;
  value: string | number | null;
  component?: any;
  componentProps?: {
    [key: string]: any;
  };
  canSlot?: boolean;
  class?: string;
};

export type TableRow = {
  id: string | number;
  canDrag?: boolean;
  canEdit?: boolean;
  columns: TableCell[];
  canToggle?: boolean;
  orderValue?: number;
  extraData?: {
    [key: string]: any;
  };
};

export type BlurData = {
  value: any;
  columnId: string | number;
  rowId: string | number;
};

export type SortEmit = {
  selectedItem: string;
  newOrder: string[];
  newOrderValue: number | undefined;
};

type Props = {
  headers?: TableHeader[];
  rows: TableRow[];
  canEdit?: boolean;
  withEdit?: boolean;
  withDelete?: boolean;
  minimalDelete?: boolean;
  draggable?: boolean;
  tinyRows?: boolean;
  toggleable?: boolean;
  pageHeaderTitle?: string;
  stickyPageHeader?: boolean;
  stickyTableHeader?: boolean;
  dragHandleClass?: string;
  headerClass?: string;
  headerSize?: 'md' | 'sm';
  clickEditIcon?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  headers: () => [],
  rows: () => [],
  withEdit: false,
  canEdit: false,
  withDelete: false,
  minimalDelete: true,
  draggable: false,
  toggleable: false,
  tinyRows: false,
  stickyPageHeader: false,
  stickyTableHeader: true,
  pageHeaderTitle: undefined,
  dragHandleClass: '.fa-up-down',
  headerClass: '',
  headerSize: 'md',
  clickEditIcon: false,
});

const emit = defineEmits<{
  (e: 'blur', data: BlurData): void;
  (e: 'edit', row: TableRow): void;
  (e: 'delete', row: TableRow): void;
  (e: 'sorted', data: SortEmit): void;
  (e: 'rowClicked', row: TableRow): void;
}>();

defineSlots<{
  [key: string]: (props: { data: TableHeader | TableCell; save?: () => {}; row?: TableRow }) => any;
  last_row: (props: any) => any;
  after_rows: (props: any) => any;
  extra_row: (props: { data: TableRow }) => any;
}>();

const body = ref<HTMLElement | null>(null);

let sortableInstance: Sortable | null = null;

onMounted(() => {
  if (body.value) {
    sortableInstance = new Sortable(body.value, {
      animation: 150,
      handle: props.dragHandleClass,
      disabled: props.rows?.length <= 1,
      onEnd(event) {
        const rowId = event.item.dataset.id;

        if (!rowId) {
          useToast().error('Something went wrong, please try again.');
          return;
        }

        const item = props.rows.find((row) => row.id == rowId);

        if (!item) throw new Error('Item not found');

        let newOrderValue = item.orderValue;

        if (item?.orderValue) {
          const itemIdBefore = event.to.children.item((event.newIndex ?? 0) - 1)?.getAttribute('data-id');
          const itemBefore = props.rows.find((row) => row.id == itemIdBefore);
          const itemIdAfter = event.to.children.item((event.newIndex ?? 0) + 1)?.getAttribute('data-id');
          const itemAfter = props.rows.find((row) => row.id == itemIdAfter);

          if (itemBefore && itemAfter) {
            newOrderValue = Math.round((itemBefore.orderValue + itemAfter.orderValue) / 2);
          } else if (itemAfter && itemAfter.orderValue) {
            newOrderValue = itemAfter.orderValue - 1;
          } else if (itemBefore) {
            newOrderValue = itemBefore.orderValue + 1000;
          } else {
            throw new Error('something went wrong, please try again.');
          }
        }

        const newOrder = sortableInstance?.toArray() || [];

        emit('sorted', { selectedItem: rowId, newOrder: newOrder, newOrderValue: newOrderValue });
      },
    });
  }
});

watch(
  () => props.rows.length,
  (newValue) => {
    if (sortableInstance) {
      sortableInstance.option('disabled', newValue <= 1);
    }
  }
);

const labelPositionClass = (header: TableHeader) => {
  if (!header?.labelPosition) return 'text-left';

  switch (header.labelPosition) {
    case 'left':
      return 'text-left';
    case 'right':
      return 'text-right';
    case 'center':
      return 'text-center';
    default:
      return 'text-left';
  }
};

const headerById = (id: string | number) => {
  return props.headers.find((header) => header.key === id);
};

const onInputBlur = (data: any, columnId: string | number, rowId: string | number) => {
  emit('blur', { value: data, columnId: columnId, rowId: rowId });
};

const onClickEdit = (row: TableRow, clickRow = true) => {
  if (!props.canEdit || !row?.canEdit) return;

  if (!props.clickEditIcon) {
    emit('edit', row);
    return;
  }

  if (clickRow) {
    emit('rowClicked', row);
  } else {
    emit('edit', row);
  }
};

const onClickDelete = (row: TableRow) => {
  if (!props.canEdit || !row?.canEdit || !props.withDelete) return;
  emit('delete', row);
};

const openRowIds = ref(new Set());

provide(inputInTable, true);

const columnCount = computed(() => {
  let total = 0;

  if (props.withEdit) {
    total += 1;
  }

  if (props.withDelete) {
    total += 1;
  }

  if (props.toggleable) {
    total += 1;
  }

  if (props.draggable) {
    total += 1;
  }

  if (props.rows && props.rows.length) {
    const c = props.rows[0].columns;
    total += c.length - 1;
  }

  return total;
});
</script>

<template>
  <div class="relative">
    <div
      v-if="pageHeaderTitle"
      class="z-200 top-0 bg"
      :class="{ 'sticky': stickyPageHeader }">
      <ContentHeader
        :loading="false"
        :title="'title'"
        :is-page-header="false"
        :actions="[]"
        :actions-as-buttons="true"
        :with-back-button="false">
        <template #afterTitle>
          <slot name="afterTitle" />
        </template>
        <template #underTitle>
          <slot name="underTitle" />
        </template>
      </ContentHeader>
    </div>

    <div class="relative overflow-x-auto">
      <table class="relative h-1 w-full text-left text-sm">
        <thead
          v-if="headers && headers.length > 0"
          :class="headerClass"
          class="border-b text">
          <tr class="sticky top-0">
            <th
              v-if="toggleable"
              :class="headerSize === 'md' ? 'py-edge' : 'py-edge-1/2'" />
            <th
              v-if="draggable"
              :class="headerSize === 'md' ? 'py-edge' : 'py-edge-1/2'">
              <i class="fa fa-fw fa-arrows-alt invisible" />
            </th>
            <th
              v-for="header in headers"
              :key="header.key"
              scope="col"
              :class="[labelPositionClass(header), header.class, headerSize === 'md' ? 'py-edge' : 'py-edge-1/2']"
              class="whitespace-nowrap px-edge text-sm">
              <slot
                :name="`header_${header.key}`"
                :data="header">
                <div
                  v-if="header.html"
                  class="[&>div]:text-xs"
                  v-html="safeHtmlStringify(header.html)" />
                <span v-else>
                  {{ header.label }}
                </span>
              </slot>
            </th>
            <th
              v-if="withEdit"
              :class="headerSize === 'md' ? 'py-edge' : 'py-edge-1/2'">
              <i class="fa fa-fw fa-edit invisible" />
            </th>
          </tr>
        </thead>
        <tbody ref="body">
          <template
            v-for="row in rows"
            :key="row.id">
            <tr
              :data-id="row.id"
              class="group border-b bg-row odd:bg-row-alternate hover:bg-row-hover">
              <td
                v-if="toggleable && row.canToggle"
                class="w-[30px]"
                @click="openRowIds.has(row.id) ? openRowIds.delete(row.id) : openRowIds.add(row.id)">
                <div class="flex justify-center">
                  <i
                    class="fa fa-fw fa-chevron-up transform cursor-pointer transition"
                    :class="{ 'rotate-180': openRowIds.has(row.id) }" />
                </div>
              </td>
              <td
                v-if="draggable && row.canDrag"
                class="w-[30px]">
                <div class="flex justify-center">
                  <i
                    class="fa fa-fw fa-up-down invisible cursor-grab"
                    :class="{ 'group-hover:visible': canEdit && row.canDrag && rows.length > 1 }" />
                </div>
              </td>
              <td
                v-for="column in row.columns"
                :key="column.id"
                :class="[labelPositionClass(headerById(column.id)), column.class]"
                class="h-[40px] whitespace-nowrap p-0 text-base"
                @click="column.component ? undefined : onClickEdit(row)">
                <slot
                  :name="column.canSlot ? `cell_${column.id}` : 'not_in_use'"
                  :save="onInputBlur"
                  :row="row"
                  :data="column">
                  <div
                    v-if="column.component"
                    :class="column.class">
                    <Component
                      :is="column.component"
                      v-bind="column.componentProps"
                      v-model="column.value"
                      :can-edit="canEdit"
                      @blur="onInputBlur($event, column.id, row.id)" />
                  </div>
                  <span
                    v-else
                    class="px-edge">
                    {{ column.value }}
                  </span>
                </slot>
              </td>
              <td
                v-if="withEdit"
                :class="{ 'group-hover:[&_i]:visible': canEdit && row.canEdit }"
                class="w-[35px]">
                <div class="flex justify-center">
                  <VButton
                    size="sm"
                    icon="fa-pencil invisible group-hover:visible"
                    @click="onClickEdit(row, false)" />
                </div>
              </td>
              <td
                v-if="withDelete"
                :class="{ 'w-[35px] group-hover:[&_i]:visible': minimalDelete }"
                class="">
                <div
                  v-if="minimalDelete"
                  class="flex justify-center">
                  <VButton
                    size="sm"
                    icon="fa-trash  invisible group-hover:visible"
                    @click="onClickDelete(row)"></VButton>
                </div>
                <div
                  v-else
                  class="flex justify-center">
                  <VButton
                    size="sm"
                    title="Delete"
                    type="warning"
                    icon="fa-trash"
                    @click="onClickDelete(row)"></VButton>
                </div>
              </td>
            </tr>
            <tr
              v-if="row.canToggle && openRowIds.has(row.id)"
              class="h-full">
              <slot
                :name="`extra_row`"
                :data="row">
                <td :colspan="columnCount">Hallo World</td>
              </slot>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <slot name="after_rows">
            <tr
              v-if="$slots.last_row"
              class="border-b bg-row odd:bg-row-alternate hover:bg-row-hover">
              <td :colspan="columnCount">
                <slot name="last_row" />
              </td>
            </tr>
          </slot>
        </tfoot>
      </table>
    </div>
  </div>
</template>
