<script setup lang="ts">
import { inject, ref } from 'vue';
import {
  type GroupResourceComposable,
  groupResourceKey,
  MetaDataAccessComposable,
  metaDataAccessesKey,
  MetaDataComposable,
  metaDataKey,
} from '@/provide/keys';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import VTable from '@/components/Tables/VTable.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { useToast } from 'vue-toastification';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import { getKey } from '@/util/globals';
import ContentList from '@/components/Content/ContentList.vue';
import {
  fieldEditable,
  fieldVisible,
  metaDataEditable,
  metaDataVisible,
} from '@/components/Models/MetaData/meta-data-access-functions';
import { MetaDataAccessResource } from '@/types/meta-data';

type Props = {
  groupId: number;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'closed'): void;
}>();

const loading = ref(false);

const { metaData, fetchMetaData } = inject(metaDataKey) as MetaDataComposable;
const { group, fetch: fetchGroup } = inject(groupResourceKey) as GroupResourceComposable;
const { metaDataAccesses, fetchMetaDataAccesses, removeMetaDataAccess, addOrUpdateMetaDataAccess } = inject(
  metaDataAccessesKey
) as MetaDataAccessComposable;

const fetchItAll = async () => {
  loading.value = true;
  await fetchMetaDataAccesses();
  await fetchMetaData();
  await fetchGroup();
  loading.value = false;
};
fetchItAll();

const toggleViewAccessForGroup = async (child, metaDatum, metaDataField = null) => {
  if (metaDataField && metaDataVisible(metaDataAccesses.value, metaDatum.id, child.id)) {
    return;
  }
  const index = metaDataAccesses.value.findIndex(
    (i: MetaDataAccessResource) =>
      i.meta_data_id === metaDatum.id &&
      i.shared_to_group_id === child.id &&
      i.meta_data_field_id === (metaDataField ? metaDataField.id : null)
  );
  if (index === -1) {
    const { data } = await axios.post(`/api/meta-data-accesses/`, {
      model_type: 'App\\Group',
      model_id: props.groupId,
      meta_data_field_id: metaDataField ? metaDataField.id : null,
      meta_data_id: metaDatum.id,
      shared_to_group_id: child.id,
    });
    addOrUpdateMetaDataAccess(data);
    useToast().info('Added');
  } else {
    await axios.delete(`/api/meta-data-accesses/${metaDataAccesses.value[index].id}`);
    removeMetaDataAccess(metaDataAccesses.value[index].id);
    useToast().info('Removed');
  }
};

const toggleEditAccessForGroup = async (child, metaDatum, metaDataField = null) => {
  if (metaDataField && metaDataEditable(metaDataAccesses.value, metaDatum.id, child.id)) {
    return;
  }

  const index = metaDataAccesses.value.findIndex(
    (i: MetaDataAccessResource) =>
      i.meta_data_id === metaDatum.id &&
      i.shared_to_group_id === child.id &&
      i.meta_data_field_id === (metaDataField ? metaDataField.id : null)
  );
  if (index > -1) {
    const item = { ...metaDataAccesses.value[index] };
    item.editable = !item.editable;
    await axios.patch(`/api/meta-data-accesses/${item.id}`, {
      editable: item.editable,
    });
    addOrUpdateMetaDataAccess(item);

    useToast().info('Updated ');
  } else {
    const { data } = await axios.post(`/api/meta-data-accesses/`, {
      model_type: 'App\\Group',
      model_id: props.groupId,
      meta_data_field_id: metaDataField ? metaDataField.id : null,
      meta_data_id: metaDatum.id,
      shared_to_group_id: child.id,
      editable: true,
    });
    addOrUpdateMetaDataAccess(data);
    useToast().info('Added');
  }
};
</script>

<template>
  <CrudSlideout
    title="Manage Meta Data Access"
    only-close-button
    medium
    @closed="emit('closed')">
    <div
      v-if="loading"
      class="mt-[64px] text-center text-5xl text-soft">
      <i class="fa fa-fw fa-circle-o-notch fa-spin" /> <br />
    </div>

    <div v-if="!loading">
      <ContentList
        :loading="loading"
        :with-under-title="false"
        :height-of-container-changer="-50"
        content-slot-classes="pt-edge">
        <div v-for="child in [{ name: 'All Event Members', id: null }].concat(getKey(group, 'children', []))">
          <ContentContainer
            :loading="loading"
            :with-back-button="getKey(group, 'children', []).length > 0"
            :start-open="getKey(group, 'children', []).length === 0"
            :title="child.name">
            <template #content>
              <div>
                <BoxContainer :content-padding="false">
                  <div
                    class="overflow-auto"
                    style="height: calc(100vh - 182px)">
                    <VTable
                      edge-to-edge
                      sticky-header>
                      <template #head>
                        <VTableRow head>
                          <VTableCell>
                            <i class="fa fa-fw fa-briefcase mr-edge-1/4 text-highlight"></i>
                            Permissions
                          </VTableCell>
                          <VTableCell style="width: 40px">View</VTableCell>
                          <VTableCell style="width: 40px">Edit</VTableCell>
                        </VTableRow>
                      </template>
                      <template v-for="metaDatum in metaData">
                        <VTableRow main-row>
                          <VTableCell>
                            <h3>
                              {{ metaDatum.title }}
                              <span
                                v-if="
                                  metaDataAccesses.filter(
                                    (f) =>
                                      f.meta_data_id === metaDatum.id &&
                                      f.meta_data_field_id === null &&
                                      child.id === f.shared_to_group_id
                                  ).length > 0
                                "
                                class="text-sm text-soft">
                                - All Fields Shared</span
                              >
                              <span
                                v-else
                                class="text-sm text-soft"
                                >{{
                                  metaDatum.fields.filter((field) => {
                                    return (
                                      metaDataAccesses.filter(
                                        (f) =>
                                          f.meta_data_id === metaDatum.id &&
                                          f.meta_data_field_id === field.id &&
                                          child.id === f.shared_to_group_id
                                      ).length > 0
                                    );
                                  }).length
                                }}/{{ metaDatum.fields.length }}</span
                              >
                            </h3>
                          </VTableCell>
                          <VTableCell>
                            <SettingCheck
                              :model-value="metaDataVisible(metaDataAccesses, metaDatum.id, child.id)"
                              @click="toggleViewAccessForGroup(child, metaDatum, null)" />
                          </VTableCell>
                          <VTableCell>
                            <SettingCheck
                              :model-value="metaDataEditable(metaDataAccesses, metaDatum.id, child.id)"
                              @click="toggleEditAccessForGroup(child, metaDatum, null)" />
                          </VTableCell>
                        </VTableRow>

                        <template v-for="field in metaDatum.fields">
                          <VTableRow>
                            <VTableCell>
                              <div class="pl-edge">
                                {{ field.title }}
                              </div>
                            </VTableCell>
                            <VTableCell>
                              <SettingCheck
                                v-if="metaDataVisible(metaDataAccesses, metaDatum.id, child.id)"
                                disabled
                                :model-value="true" />
                              <SettingCheck
                                v-else
                                :model-value="fieldVisible(metaDataAccesses, metaDatum.id, field.id, child.id)"
                                @click="toggleViewAccessForGroup(child, metaDatum, field)" />
                            </VTableCell>

                            <VTableCell>
                              <SettingCheck
                                v-if="metaDataEditable(metaDataAccesses, metaDatum.id, child.id)"
                                disabled
                                :model-value="true" />

                              <SettingCheck
                                v-else
                                :model-value="fieldEditable(metaDataAccesses, metaDatum.id, field.id, child.id)"
                                @click="toggleEditAccessForGroup(child, metaDatum, field)" />
                            </VTableCell>
                          </VTableRow>
                        </template>
                      </template>
                    </VTable>
                  </div>
                </BoxContainer>
              </div>
            </template>
          </ContentContainer>
          <div v-if="child.id === null && getKey(group, 'children', []).length > 0">
            <hr class="mt-edge" />
            <h2 class="mt-edge pl-edge-2x">Teams</h2>
          </div>
        </div>
      </ContentList>
    </div>
  </CrudSlideout>
</template>
