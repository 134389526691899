import type { PageTab } from '@/types/page-tab';
import {
  canEditAccommodationsOnFestivalString,
  canEditAccreditationsOnFestivalString,
  canEditContactsOnFestivalString,
  canEditFinancesOnFestivalString,
  canEditGuestListsOnFestivalString,
  canEditPerformanceDocumentsOnFestivalString,
  canEditTravelsOnFestivalString,
  canUpdateFestivalString,
  canViewAccommodationsOnFestivalString,
  canViewAccreditationsOnFestivalString,
  canViewContactsOnFestivalString,
  canViewFinancesOnFestivalString,
  canViewGuestListsOnFestivalString,
  canViewPerformanceDocumentsOnFestivalString,
  canViewTravelsOnFestivalString,
} from '@/variables/permissions';

type Params = {
  hasAdvances?: boolean;
  hasAccreditations?: boolean;
  hasGuestLists?: boolean;
  hasFinances?: boolean;
  admin?: boolean;
  permissions: string[];
  hasTasks?: boolean;
};

export const performanceTabs = ({
  hasAdvances = false,
  hasAccreditations = false,
  hasGuestLists = false,
  hasFinances = false,
  admin = false,
  permissions = [],
  hasTasks = false,
}: Params): PageTab[] => {
  const tabs: PageTab[] = [];

  const addItem = (canView: string, canEdit: string, item: PageTab) => {
    if (
      admin ||
      permissions.includes('update') ||
      (canView !== null ? permissions.includes(canView) : false) ||
      (canEdit !== null ? permissions.includes(canEdit) : false)
    ) {
      tabs.push(item);
    }
  };
  tabs.push({ key: '', title: 'Dashboard' });

  addItem(canViewContactsOnFestivalString, canEditContactsOnFestivalString, {
    key: 'contacts',
    title: 'Contacts',
  });
  if (hasTasks) {
    tabs.push({ key: 'tasks', title: 'Tasks' });
  }

  tabs.push({ key: 'info_sections', title: 'Info Sections' });

  addItem(canViewTravelsOnFestivalString, canEditTravelsOnFestivalString, {
    key: 'travels',
    title: 'Travels',
  });

  addItem(canViewAccommodationsOnFestivalString, canEditAccommodationsOnFestivalString, {
    key: 'accommodations',
    title: 'Accommodations',
  });

  if (hasAccreditations) {
    addItem(canViewAccreditationsOnFestivalString, canEditAccreditationsOnFestivalString, {
      key: 'accreditations',
      title: 'Accreditations',
    });
  }

  if (hasGuestLists) {
    addItem(canViewGuestListsOnFestivalString, canEditGuestListsOnFestivalString, {
      key: 'guest_lists',
      title: 'Guest Lists',
    });
  }

  if (hasFinances) {
    addItem(canViewFinancesOnFestivalString, canEditFinancesOnFestivalString, {
      key: 'finances',
      title: 'Finances',
    });
  }

  addItem(canViewPerformanceDocumentsOnFestivalString, canEditPerformanceDocumentsOnFestivalString, {
    key: 'documents',
    title: 'Documents',
  });

  if (hasAdvances) {
    addItem(canUpdateFestivalString, '', {
      key: 'advances',
      title: 'Advances',
    });
  }

  return tabs;
};
