<script lang="ts" setup>
import ActiveUsers from '@/components/Navbar/ActiveUsers.vue';
import NotificationListModal from '@/components/Navbar/NotificationListModal.vue';
import { usePageTabsStore } from '@/store/PageTabsStore';
import { openExternalPage } from '@/util/route';
import { usePage } from '@inertiajs/vue3';
import { computed, onMounted, ref, useTemplateRef } from 'vue';
import TheSubNav from '@/components/Navbar/TheNavbar.vue';
import { useNavBarTitleStore } from '@/composables/use-nav-bar-title';
import UserMenu from '@/components/Navbar/UserMenu.vue';
import TableButtonSelector from '@/components/Tables/Headers/TableButtonSelector.vue';
import { safeHtmlStringify } from '@/util/safe-html-stringify';
import { useUserStore } from '@/store/UserStore';
import EmailVerificationBanner from '@/components/Navbar/EmailVerificationBanner.vue';
import VButton from '../Inputs/VButton.vue';
import UnlockPaidGroupFeaturePill from '../UnlockFeatures/UnlockPaidGroupFeaturePill.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';

const pageTabsStore = usePageTabsStore();

const userStore = useUserStore();

const navbarTitleStore = useNavBarTitleStore();

const notificationSlideOutOpen = ref(false);
const items = computed(() => {
  const array = [
    {
      title: 'Knowledge Base ',
      preIcon: 'fa-book fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://help.crescat.io', null, true);
        close();
      },
    },
  ];

  if (userStore.intro) {
    array.push({
      title: `Show Tutorial`,
      preIcon: 'fa-play fa-regular',
      action: (closeIt: () => void) => {
        if (!userStore) return;
        userStore.intro();
        closeIt();
      },
    });
  }
  const array2 = [
    {
      type: 'divider',
    },
    {
      title: 'Feedback & Suggestions',
      preIcon: 'fa-envelope-open-text fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_suggestion', null, true);
        close();
      },
    },
    {
      title: 'Ask a question',
      preIcon: 'fa-message-question fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_question', null, true);
        close();
      },
    },
    {
      title: 'Report an Issue',
      preIcon: 'fa-flag fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_issue', null, true);
        close();
      },
    },
    {
      type: 'divider',
    },
    {
      title: "What's new in Crescat?",
      preIcon: 'fa-bullhorn fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://crescat.io/release-notes/', null, true);
        close();
      },
    },
  ];

  return array.concat(array2);
});

const header = useTemplateRef('header');

const resizeObserver = new ResizeObserver((entries) => {
  const root = document.querySelector(':root');
  if (root) {
    root.style?.setProperty('--navbar-current-height', `${entries[0].target.clientHeight}px`);
  }
});

onMounted(() => {
  resizeObserver.observe(header.value);
});
</script>

<template>
  <header
    ref="header"
    class="main-nav flex flex-col justify-between overflow-hidden">
    <EmailVerificationBanner v-if="$page.props?.auth?.user?.verified_at === null" />

    <div
      :data-theme="userStore.themeTopLeft"
      class="relative flex h-navbar flex-nowrap items-center justify-between gap-edge border-b bg-sidebar pr-edge text">
      <div class="flex flex-1 items-center gap-edge">
        <div
          id="breadcrumb"
          class="flex-1" />

        <div id="after-breadcrumb" />
      </div>

      <div class="right-0 flex min-w-fit items-center justify-end gap-edge-1/2">
        <div id="before-navbar" />
        <UnlockPaidGroupFeaturePill />

        <div
          v-if="usePage().props.authenticated"
          id="custom_notification_area" />

        <div
          v-if="usePage().props.authenticated"
          id="share_or_invite_button_area" />

        <ActiveUsers v-if="$page.props.authenticated" />

        <NotificationListModal
          v-if="usePage().props.authenticated"
          v-model:is-open="notificationSlideOutOpen" />

        <TableButtonSelector
          v-if="usePage().props.authenticated"
          dusk="navbar-help-button"
          icon="fa-question"
          tool-tip-text="Need Help?"
          :selected-text="''"
          button-text="Help"
          :options="items" />

        <UserMenu
          v-if="usePage().props.authenticated"
          dusk="navbar-user-menu" />
      </div>
    </div>

    <div
      v-show="pageTabsStore.inNavbar && navbarTitleStore.allowItemsInSubNav"
      id="header_in_page"
      class="flex flex-col gap-edge-1/2 pt-edge"
      :class="{ 'border-b pb-edge': !pageTabsStore.tabs.length }">
      <div class="flex items-center justify-between gap-edge-1/4">
        <div
          v-if="navbarTitleStore.navBarBackButton || navbarTitleStore.navBarTitle"
          :class="navbarTitleStore.navBarTitleLarge ? 'text-4xl' : 'text-3xl'"
          class="flex flex-1 items-center gap-edge-1/2 truncate px-edge font-semibold">
          <span
            v-if="!navbarTitleStore.navBarBackButton"
            class="flex aspect-1 h-[28px] w-[28px] items-center justify-center rounded border bg-[hsl(var(--color-event-type-blue))]">
            <i
              class="fa fa-fw fa-regular text-base text-[hsl(var(--blue-1100))]"
              :class="navbarTitleStore.navBarTitleIcon" />
          </span>
          <VButton
            v-if="navbarTitleStore.navBarBackButton"
            icon="fa-arrow-left fa-xl"
            @click="navbarTitleStore.goBackFn()" />

          <span
            class="truncate"
            v-html="safeHtmlStringify(navbarTitleStore.navBarTitle)" />

          <IconWithLoading
            v-if="navbarTitleStore.navBarTitleLoading"
            loading />
        </div>
        <div>
          <div id="after-navbar-title"></div>
        </div>
      </div>
      <TheSubNav
        v-if="pageTabsStore.tabs.length > 1"
        :is-primary="!navbarTitleStore.navBarBackButton && !navbarTitleStore.navBarTitle" />
    </div>

    <div id="header_bottom"></div>
  </header>
</template>
