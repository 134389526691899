<script lang="ts" setup>
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { computed, nextTick, ref } from 'vue';
import {
  changeAndFormatStamp,
  formatStampAsDate,
  formatStampAsTime,
  getDiffInInterval,
  stampIsAfter,
  stampIsBefore,
  timeStampsAreSame,
} from '@/util/timeFunctions';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import { blurActiveElement } from '@/util/globals';
import VTimePicker from '@/components/Inputs/Date/VTimePicker.vue';
import { dateFormat, dateTimeFormat } from '@/variables/date-format';
import DateHourSelector from '@/components/Inputs/Date/DateHourSelector.vue';
import VButton from '@/components/Inputs/VButton.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import DurationSelector from '@/components/Inputs/Date/DurationSelector.vue';

type Props = {
  startDate: string;
  endDate: string;
  start: string;
  end: string;
  vertical?: boolean;
  twoByTwo?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  twoByTwo: false,
  vertical: false,
});

const emit = defineEmits<{
  (event: 'update:start', value: string | null): void;
  (event: 'update:end', value: string | null): void;
  (event: 'update:startDate', value: string | null): void;
  (event: 'update:endDate', value: string | null): void;
}>();

const oneDay = computed(() => {
  if (!props.startDate || !props.endDate) {
    return true;
  }
  return timeStampsAreSame(props.startDate, props.endDate, 'day');
});

const toggleOneDay = async () => {
  if (oneDay.value) {
    emit(
      'update:endDate',
      changeAndFormatStamp({
        stamp: props.startDate,
        addDays: 1,
        format: dateFormat,
      })
    );
    emit(
      'update:end',
      changeAndFormatStamp({
        stamp: props.startDate,
        startOf: 'day',
        addDays: 1,
        addMinutes: 13 * 60,
        format: dateTimeFormat,
      })
    );
  } else {
    emit(
      'update:endDate',
      changeAndFormatStamp({
        stamp: props.startDate,
        format: dateFormat,
      })
    );
    emit(
      'update:end',
      changeAndFormatStamp({
        stamp: props.start,
        addMinutes: 60,
        format: dateTimeFormat,
      })
    );
  }
};

const setNewOneDay = async (newDate: string) => {
  if (!oneDay.value) return;
  const diffOldStart = getDiffInInterval(props.startDate, props.start, 'minutes', true);
  const diffOldEnd = getDiffInInterval(props.startDate, props.end, 'minutes', true);

  emit('update:startDate', newDate);
  await nextTick();
  emit('update:endDate', newDate);
  await nextTick();
  emit(
    'update:start',
    changeAndFormatStamp({
      stamp: newDate,
      addMinutes: diffOldStart,
      format: dateTimeFormat,
    })
  );
  await nextTick();
  emit(
    'update:end',
    changeAndFormatStamp({
      stamp: newDate,
      addMinutes: diffOldEnd,
      format: dateTimeFormat,
    })
  );
};
const oneDayStartUpdated = (newStart: string) => {
  if (!oneDay.value) return;
  let newStartDateTime = formatStampAsDate(props.start) + ' ' + newStart + ':00';
  if (stampIsAfter(newStartDateTime, props.end, true)) {
    emit(
      'update:end',
      changeAndFormatStamp({
        stamp: newStartDateTime,
        addMinutes: 60,
        format: dateTimeFormat,
      })
    );
  }
  if (getDiffInInterval(newStartDateTime, props.end, 'hours') >= 24) {
    emit('update:end', formatStampAsDate(props.startDate) + ' ' + formatStampAsTime(props.end) + ':00');
  }

  emit('update:start', newStartDateTime);
};
const oneDayDurationUpdated = async (newDuration: number) => {
  if (!oneDay.value) return;
  const newEnd = changeAndFormatStamp({
    stamp: props.start,
    addMinutes: newDuration,
    format: dateTimeFormat,
  });
  emit('update:end', newEnd);
  await nextTick();
  if (timeStampsAreSame(props.start, newEnd, 'day')) {
    return;
  }
  const hoursAfterMidnight = getDiffInInterval(
    newEnd,
    changeAndFormatStamp({
      stamp: newEnd,
      startOf: 'day',
    }),
    'hours'
  );
  if (hoursAfterMidnight < 6) return;

  emit(
    'update:endDate',
    changeAndFormatStamp({
      stamp: newEnd,
      format: dateFormat,
    })
  );
};
const oneDayEndUpdated = (newEnd: string) => {
  if (!oneDay.value) return;
  let newEndDateTime = formatStampAsDate(props.end) + ' ' + newEnd + ':00';
  if (timeStampsAreSame(newEndDateTime, props.start, 'minute')) {
    emit(
      'update:start',
      changeAndFormatStamp({
        stamp: newEndDateTime,
        subMinutes: 60,
        format: dateTimeFormat,
      })
    );
  }
  if (stampIsBefore(newEndDateTime, props.start)) {
    newEndDateTime = changeAndFormatStamp({
      stamp: newEndDateTime,
      addMinutes: 24 * 60,
      format: dateTimeFormat,
    });
  }
  if (getDiffInInterval(props.start, newEndDateTime, 'hours') >= 24) {
    newEndDateTime = changeAndFormatStamp({
      stamp: newEndDateTime,
      subMinutes: 24 * 60,
      format: dateTimeFormat,
    });
  }
  emit('update:end', newEndDateTime);
};
const multiDayUpdateStart = (newStart: string) => {
  if (oneDay.value) return;
  emit('update:startDate', formatStampAsDate(newStart));
  emit('update:start', newStart);

  if (stampIsAfter(newStart, props.end, true)) {
    emit(
      'update:endDate',
      changeAndFormatStamp({
        stamp: newStart,
        addMinutes: 60 * 24,
        format: dateFormat,
      })
    );
    emit(
      'update:end',
      changeAndFormatStamp({
        stamp: newStart,
        addMinutes: 60 * 24,
        format: dateTimeFormat,
      })
    );
  }
};
const multiDayUpdateEnd = (newEnd: string) => {
  if (oneDay.value) return;
  emit('update:endDate', formatStampAsDate(newEnd));
  emit('update:end', newEnd);
  if (stampIsBefore(newEnd, props.start, true)) {
    emit(
      'update:startDate',
      changeAndFormatStamp({
        stamp: newEnd,
        subMinutes: 60 * 24,
        format: dateFormat,
      })
    );
    emit(
      'update:start',
      changeAndFormatStamp({
        stamp: newEnd,
        subMinutes: 60 * 24,
        format: dateTimeFormat,
      })
    );
  }
};
const afterInitialLoad = ref(false);
setTimeout(() => {
  afterInitialLoad.value = true;
}, 100);
const duration = () => {
  return getDiffInInterval(props.start, props.end, 'minutes');
};
</script>

<template>
  <div :class="twoByTwo ? '' : 'col-span-2'">
    <div
      v-if="oneDay"
      class="relative"
      :class="
        twoByTwo
          ? 'grid grid-cols-2 gap-edge'
          : { 'grid grid-cols-4 gap-edge': !vertical, 'flex flex-col gap-edge': vertical }
      ">
      <VDropdown :items="[]">
        <template #click-area>
          <BaseInput
            label="Date"
            left-icon="fa-calendar fa-regular"
            :model-value="formatStampAsDate(startDate)"
            @update:focus="blurActiveElement()" />
        </template>

        <template #dropdown="{ close }">
          <div class="border-b p-edge-1/2">
            <div class="mb-edge-1/2 flex items-center justify-between pl-edge-1/2">
              <!--              <h4>Date</h4>-->
              <InputLabel
                label="Date"
                class="" />
              <VButton
                size="sm"
                icon="fa-plus"
                tool-tip-text="Click to enable multi-day event"
                title="Set End Date"
                @click="toggleOneDay"></VButton>
            </div>
            <VDatepicker
              inline
              :label="null"
              :model-value="startDate"
              required
              :range="!oneDay"
              class="flex-1"
              @update:model-value="[setNewOneDay($event)]" />
          </div>
        </template>
      </VDropdown>
      <VTimePicker
        :model-value="formatStampAsTime(start)"
        label="Start"
        required
        @blur="oneDayStartUpdated" />
      <DurationSelector
        :model-value="duration()"
        is-minutes
        :durations="[60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900]"
        @update:model-value="oneDayDurationUpdated"></DurationSelector>
      <VTimePicker
        :model-value="formatStampAsTime(end)"
        label="End"
        required
        @blur="oneDayEndUpdated" />

      <div class="absolute right-0 top-[-8px]">
        <VButton
          size="xs"
          :emphasized="true"
          title="Set End Date"
          @click="toggleOneDay" />
      </div>
    </div>

    <div
      v-else
      :class="{ 'grid grid-cols-2 gap-edge': !vertical && !twoByTwo, 'flex flex-col gap-edge': vertical || twoByTwo }"
      class="relative">
      <div class="absolute right-0 top-[-8px] z-[1]">
        <VButton
          size="xs"
          title="One day"
          :emphasized="true"
          @click="toggleOneDay" />
      </div>

      <DateHourSelector
        label="Start"
        required
        :highlight-days="[end]"
        :date-time="start"
        @update:date-time="multiDayUpdateStart" />
      <DateHourSelector
        label="End"
        :highlight-days="[start]"
        :set-focus-date="afterInitialLoad"
        required
        :date-time="end"
        @update:date-time="multiDayUpdateEnd" />
    </div>
  </div>
</template>
