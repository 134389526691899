import type { FestivalSectionResource } from '@/types/festival-section';
import { FestivalSettingsResource } from '@/types/festival';

export const festivalSectionPageTabs = (
  festivalSection: FestivalSectionResource,
  festivalSettings: FestivalSettingsResource | null
) => {
  const tabs = [];

  tabs.push({ key: null, title: 'dashboard' });
  tabs.push({ key: 'shifts', title: 'Shifts' });
  tabs.push({ key: 'scheduler', title: 'Scheduler' });
  tabs.push({ key: 'crew', title: 'Crew' });

  festivalSection.public_forms.forEach((form) => {
    tabs.push({
      key: `public-form-${form.id}`,
      title: form.display_name,
      description: `All public form responses from ${form.name} to ${festivalSection.name}`,
    });
  });

  if (festivalSection.public_forms.length > 0) {
    tabs.push({ key: 'announcements', title: 'Announcements' });
  }

  if (festivalSettings && festivalSettings.has_tasks) {
    tabs.push({ key: 'tasks', title: 'Tasks' });
  }

  if (festivalSettings && festivalSettings.has_accreditations) {
    tabs.push({ key: 'accreditation', title: 'Accreditation' });
  }

  return tabs;
};
