<script setup lang="ts">
import { nextTick, ref } from 'vue';
import { InviteResource } from '@/types/invite';
import ContentList from '@/components/Content/ContentList.vue';
import GuestListContainer from '@/components/Models/guestLists/GuestListContainer.vue';
import {
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getKey,
  removeItemFromArrayBasedOnId,
} from '@/util/globals';
import GuestListReportModal from '@/components/Models/guestLists/GuestListReportModal.vue';
import GuestListCollectionMode from '@/components/Models/guestLists/GuestListCollectionMode.vue';
import { useToast } from 'vue-toastification';
import PerformanceGuestListModal from '@/components/performance/PerformanceGuestListModal.vue';

type Props = {
  invite: InviteResource;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const fetchingData = ref(true);
const initialFetchDone = ref(false);
const loading = ref(false);
const collectionMode = ref(false);
const guestLists = ref([]);
const selectedGuestList = ref(null);

const guestListSettings = ref(null);
const canAdmin = ref(false);
const canEditGuests = ref(false);

const fetchGuestListSettingAccesses = async () => {
  loading.value = true;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/guest-list-setting-accesses`);
  loading.value = false;
  initialFetchDone.value = true;
  if (data.length > 0) {
    guestLists.value = getKey(data[0], 'guest_lists', []);
    canAdmin.value = getKey(data[0], 'can_admin', false);
    canEditGuests.value = getKey(data[0], 'can_edit_guests', false);
    guestListSettings.value = getKey(data[0], 'guest_list_setting');
  }
};

const openEditModal = async (guestList = null) => {
  if (!guestListSettings.value) return;
  selectedGuestList.value = null;
  await nextTick();
  selectedGuestList.value = { ...guestList, id: getKey(guestList, 'id', null) };
};
const fetchItAll = async () => {
  fetchingData.value = true;
  await fetchGuestListSettingAccesses();
  fetchingData.value = false;
};
fetchItAll();

const listenToBroadcasts = () => {
  console.log(`private-Invite.${props.invite.parent_id}`);
  Echo.channel(`private-Invite.${props.invite.parent_id}`)
    .listen('.guests.created', (guest) => {
      if (getKey(guest, 'changed_by_user_name')) {
        useToast().success(`Guest created by ${guest.changed_by_user_name}`);
      }
      const index = getIndexFromArrayBasedOnId(guest.guest_list_id, guestLists.value);
      if (index === -1) return;
      guestLists.value[index].guests = exchangeValuesOfObject(guest, guestLists.value[index].guests);
    })
    .listen('.guests.updated', (guest) => {
      if (getKey(guest, 'changed_by_user_name')) {
        useToast().success(`Guest updated by ${guest.changed_by_user_name}`);
      }
      const index = getIndexFromArrayBasedOnId(guest.guest_list_id, guestLists.value);
      if (index === -1) return;
      guestLists.value[index].guests = exchangeValuesOfObject(guest, guestLists.value[index].guests);
    })
    .listen('.guests.deleted', (guest) => {
      if (getKey(guest, 'changed_by_user_name')) {
        useToast().success(`Guest removed by ${guest.changed_by_user_name}`);
      }
      const index = getIndexFromArrayBasedOnId(guest.guest_list_id, guestLists.value);
      if (index === -1) return;
      removeItemFromArrayBasedOnId(guest.id, guestLists.value[index].guests);
    });
};

listenToBroadcasts();
const downloadingReport = ref(false);
const downloadModalOpen = ref(false);
const openDownloadModal = async () => {
  downloadModalOpen.value = false;
  await nextTick();
  downloadModalOpen.value = true;
};
</script>
<template>
  <ContentList
    :loading="loading || fetchingData || !initialFetchDone"
    title="Guest Lists"
    :with-back-button="false"
    empty-icon="fa-ticket fa-regular"
    content-slot-classes="mt-edge"
    :empty-message="guestLists.length === 0 && !loading ? 'No Guest Lists Have Been Added' : null"
    :empty-action="
      canEdit && guestListSettings && canAdmin
        ? () => {
            openEditModal(null);
          }
        : null
    "
    :actions="
      guestListSettings
        ? [
            {
              title: 'Collection Mode',
              icon: 'fa-id-badge fa-regular',
              emphasized: true,
              type: collectionMode ? 'primary' : 'default',
              action: () => {
                collectionMode = !collectionMode;
              },
            },
            {
              title: 'Download',
              icon: 'fa-download fa-regular',
              emphasized: true,
              loading: downloadingReport,
              action: () => {
                openDownloadModal();
              },
            },
            guestListSettings && canAdmin
              ? {
                  title: 'Create',
                  icon: 'fa-plus',
                  type: 'primary',
                  disabled: collectionMode,
                  action: () => {
                    openEditModal(null);
                  },
                }
              : null,
          ]
        : []
    ">
    <template v-if="collectionMode">
      <GuestListCollectionMode
        :guest-lists="guestLists"
        :can-edit-guests="canEditGuests"
        :guest-list-setting="guestListSettings"
        @add-or-update-guest-list="exchangeValuesOfObject($event, guestLists)" />
    </template>
    <template v-else>
      <GuestListContainer
        v-for="(guestList, index) in guestLists"
        :key="guestList.id"
        :guest-list="guestList"
        :can-edit="canAdmin"
        :can-edit-guests="canEditGuests"
        :guest-list-settings="guestListSettings"
        @update:guest-list="guestLists[index] = $event"
        @edit="openEditModal(guestList)" />
    </template>
  </ContentList>

  <PerformanceGuestListModal
    v-if="selectedGuestList"
    :guest-list-settings="guestListSettings"
    :initial-guest-list="selectedGuestList"
    model-type="Invite"
    :model-id="invite.parent_id"
    :accreditation-levels="[]"
    :default-start="invite.start"
    :default-end="invite.end"
    @fetch="fetchGuestListSettingAccesses()"
    @created="fetchGuestListSettingAccesses()"
    @updated="exchangeValuesOfObject($event, guestLists)"
    @closed="selectedGuestList = null" />

  <GuestListReportModal
    v-if="downloadModalOpen"
    v-model:downloading="downloadingReport"
    :guest-lists="guestLists"
    :guest-list-settings="guestListSettings" />
</template>
