<script lang="ts" setup>
import VButton from '@/components/Inputs/VButton.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import moment from 'moment/moment';
import { dateTimeFormat } from '@/variables/date-format';
import { getGroupAssignments } from '@/services/api-group';
import { computed, nextTick, ref } from 'vue';
import { useEmitStore } from '@/store/EmitStore';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { formatStampAsHumanReadableDate } from '@/util/timeFunctions';
import CheckBox from '@/components/Icons/CheckBox.vue';
import { getItemFromArrayBasedOnId } from '@/util/globals';
import { ShiftResource } from '@/types/event';
import { shiftEvent } from '@/types/shifts';
import { useSmallScreen } from '@/composables/use-small-screen';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  shift: ShiftResource;
  initialShift: object;
  eventIds?: number[];
  events?: object[];
  modelId: number;
  model: string;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  eventIds: () => [],
  events: () => [],
  canEdit: false,
});
const emit = defineEmits<{
  (e: 'update:eventIds', value: number[]): void;
  (e: 'addEvents', value: object[]): void;
}>();

const searchOpen = ref<boolean>(false);
const selectedDate = ref<string | null>(null);
const selectedDateEvents = ref<object[]>([]);
const allEvents = ref<object[]>(props.events);
const fetchEventsForGroup = async () => {
  if (!props.model === 'Group') return;
  const start = moment(selectedDate.value).startOf('day').format(dateTimeFormat);
  const end = moment(selectedDate.value).endOf('day').format(dateTimeFormat);

  const params = {
    with_resource_ids: false,
    start,
    end,
    with_cancelled: false,
    with_simple: false,
    with_shifts: false,
    with_festivals: true,
  };

  const { data } = await getGroupAssignments(props.modelId, params);

  selectedDateEvents.value = data
    .filter((e) => !e.shift_id && e.event_id && e.title)
    .map((e) => ({
      id: e.event_id,
      name: e.title,
      venue_id: e.venue_id,
      start: e.start,
      end: e.end,
    }));
  allEvents.value = allEvents.value.concat(selectedDateEvents.value);

  // emit('addEvents', selectedDateEvents.value);
};
const { rootEmit } = useEmitStore();
const pageX = ref(0);
const pageY = ref(0);

const openDropdown = (event) => {
  if (useSmallScreen().isSmallScreen.value) {
    pageX.value = 1;
    pageY.value = event.target.getBoundingClientRect().y - 100;
  } else {
    pageX.value = event.target.getBoundingClientRect().x - 595;
    pageY.value = event.target.getBoundingClientRect().y + 30;
  }

  searchOpen.value = false;
  selectedDate.value = props.shift.start;
  fetchEventsForGroup();
  rootEmit('close-all-drop-downs');
  nextTick(() => {
    searchOpen.value = true;
  });
};
const toggleEventById = (eventId) => {
  if (props.eventIds.includes(eventId)) {
    emit(
      'update:eventIds',
      props.eventIds.filter((id) => id !== eventId)
    );
  } else {
    emit('update:eventIds', props.eventIds.concat([eventId]));
  }
};

const uniqueEvents = computed((): shiftEvent[] => {
  const unique = [];
  allEvents.value.forEach((e) => {
    if (!unique.find((u) => u.id === e.id)) unique.push(e);
  });
  props.initialShift?.initial_events?.forEach((e) => {
    if (!unique.find((u) => u.id === e.id)) unique.push(e);
  });
  return unique;
});
</script>

<template>
  <BoxContainer
    :actions="[
      {
        title: 'Connect Events',
        icon: 'fa-plus',
        disabled: !canEdit,
        action: ($event) => {
          openDropdown($event);
        },
      },
    ]"
    header-size="h3"
    :content-padding="false"
    :title="'Connected Events (' + eventIds.length + ')'">
    <div class="grid grid-cols-2">
      <VTable
        edge-to-edge
        row-size="small">
        <VTableRow v-for="eventId in eventIds">
          <VTableCell main-cell>
            {{ getItemFromArrayBasedOnId(eventId, uniqueEvents, { name: '' }).name }}
          </VTableCell>
          <VTableCell
            v-if="canEdit"
            style="width: 50px">
            <VButton
              size="sm"
              icon="fa-trash"
              @click="
                () => {
                  emit(
                    'update:eventIds',
                    eventIds.filter((id) => id !== eventId)
                  );
                }
              "></VButton>
          </VTableCell>
        </VTableRow>
      </VTable>
    </div>

    <FloatingWindowContainer
      v-if="pageX && pageY && searchOpen"
      :page-x="pageX"
      :page-y="pageY"
      class="bg"
      @closed="pageX = null">
      <div class="grid w-11/12 gap-edge-1/4 p-edge md:w-[700px] md:grid-cols-2">
        <div class="space-y-edge-1/2">
          <h3>Search by date</h3>
          <VDatepicker
            v-model="selectedDate"
            inline
            :start-date="shift.start"
            @update:model-value="fetchEventsForGroup" />
        </div>
        <div class="space-y-edge-1/2">
          <h3>Events on {{ formatStampAsHumanReadableDate(selectedDate) }}</h3>
          <div class="max-h-[300px] overflow-auto border-x">
            <VTable table-border="horizontal">
              <VTableRow
                v-for="event in selectedDateEvents"
                :key="event.id"
                :main-row="eventIds.includes(event.id)"
                clickable
                @click="toggleEventById(event.id)">
                <VTableCell style="width: 40px">
                  <CheckBox :model-value="eventIds.includes(event.id)"></CheckBox>
                </VTableCell>
                <VTableCell main-cell>
                  {{ event.name }}
                </VTableCell>
              </VTableRow>
            </VTable>
          </div>
        </div>
      </div>
    </FloatingWindowContainer>
  </BoxContainer>
</template>
