<script setup lang="ts">
import { destroyMetaData, patchMetaData, postMetaData } from '@/services/api-meta-data';
import type { MetaDataAccessResource, MetaDataModelType, MetaDataResource } from '@/types/meta-data';
import { AxiosResponse } from 'axios';
import { computed, inject, nextTick, ref } from 'vue';
import { z } from 'zod';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import CheckBox from '@/components/Icons/CheckBox.vue';
import DisplayModal from '@/components/Modals/DisplayModal.vue';
import MetaData from '@/components/Models/MetaData/MetaData.vue';
import { formatQueryString, updateQueryString } from '@/util/query-helpers';
import { ShowTimeResource } from '@/types/show-time';
import { metaDataStore } from '@/store/meta-data-store';
import VButton from '@/components/Inputs/VButton.vue';
import { getRoute, openRoute } from '@/util/route';
import { getGroupById } from '@/util/group-helpers';
import { metaDataEditable, metaDataVisible } from '@/components/Models/MetaData/meta-data-access-functions';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import {
  type GroupResourceComposable,
  groupResourceKey,
  MetaDataAccessComposable,
  metaDataAccessesKey,
} from '@/provide/keys';
import { getKey } from '@/util/globals';
import { hasAccessToFeatureInTesting } from '@/variables/feature-testing-constants';

type Props = {
  model: MetaDataModelType;
  modelId: number;
  parentModel: MetaDataModelType | null;
  metaDataParentIds: number[];
  showTimes?: ShowTimeResource[];
  parentModelId: number | null;
  isTemplate: boolean;
  isRecurring: boolean;
  initMetaData: MetaDataResource | null;
};

const props = withDefaults(defineProps<Props>(), {
  showTimes: () => [],
});

const emit = defineEmits<{
  'created': [metaData: MetaDataResource];
  'updated': [metaData: MetaDataResource];
  'deleted': [metaDataId: number];
  'closed': [];
}>();

const toast = useToast();
const { assertReadyToDeleteModal } = useDeleteObjectModal();
const { recurringModal } = useRecurringModal();
const { fetchMetaData } = metaDataStore();

const metaDataSchema = z.object({
  title: z.string().min(2).max(190),
  show_time_id: z.number().nullable(),
});
type MetaDataType = z.infer<typeof metaDataSchema>;

const newMetaData = ref<MetaDataType>({
  title: props.initMetaData?.title ?? '',
  show_time_id: props.initMetaData?.show_time_id ?? null,
});
const selectedGroupIds = ref<Map<number | null, boolean>>(new Map());
const canSave = computed(() => metaDataSchema.safeParse(newMetaData.value).success || !props.isTemplate);

const loading = ref(false);

const metaDataTemplates = ref<MetaDataResource[]>([]);

const create = async () => {
  if (props.isTemplate) {
    loading.value = true;
    const { data } = await postMetaData(props.model, props.modelId, newMetaData.value.title);

    for (const [shareToGroupId, editable] of selectedGroupIds.value.entries()) {
      const { data: newMetaDataAccess } = await axios.post(`/api/meta-data-accesses/`, {
        model_type: 'App\\Group',
        model_id: props.modelId,
        meta_data_id: data.id,
        shared_to_group_id: shareToGroupId,
        editable: editable,
      });
      await addOrUpdateMetaDataAccess(newMetaDataAccess);
    }

    updateQueryString(formatQueryString('MetaData', data.id), true);
    await nextTick();
    toast.success('Meta Data Created');
    emit('created', data);
    loading.value = false;
    emit('closed');
    return;
  }
  if (!selectedTemplateIds.value.size) return;
  const addToAll = props.isRecurring ? await recurringModal('Meta Data', '', '', 'Import') : false;
  if (addToAll === 'cancel') return;

  const promise: Promise<AxiosResponse>[] = [];
  selectedTemplateIds.value.forEach((templateId) => {
    const title = metaDataTemplates.value.find((template) => template.id === templateId)?.title;
    promise.push(
      axios.post('/api/meta-data', {
        model_type: `App\\${props.model}`,
        model_id: props.modelId,
        title: title ?? '',
        parent_id: templateId,
        is_global: addToAll === 'all',
      })
    );
  });
  const reponses = await Promise.all(promise);
  reponses.forEach(({ data }: { data: MetaDataResource }) => {
    toast.success(`${data.title} added.`);
    emit('created', data);
  });
  emit('closed');
};
const update = async () => {
  if (!props.initMetaData?.id) return;

  loading.value = true;
  await patchMetaData(props.initMetaData.id, newMetaData.value.title, newMetaData.value.show_time_id);
  toast.success('Meta Data Updated');
  emit('updated', {
    ...props.initMetaData,
    ...newMetaData.value,
  });
  loading.value = false;
  emit('closed');
};
const destroy = async () => {
  if (!props.initMetaData?.id) return;
  const yes = await assertReadyToDeleteModal(
    'Delete Meta Data',
    `Are you sure that you want to delete ${props.initMetaData.title}?`
  );
  if (!yes) return;
  loading.value = true;
  await destroyMetaData(props.initMetaData.id);
  toast.success('Meta Data Deleted');
  emit('deleted', props.initMetaData.id);
  loading.value = false;
  emit('closed');
};

const selectedTemplateIds = ref<Set<number>>(new Set());

const toggleMetaData = (metaDataId: number) => {
  if (selectedTemplateIds.value.has(metaDataId)) {
    selectedTemplateIds.value.delete(metaDataId);
  } else {
    selectedTemplateIds.value.add(metaDataId);
  }
};

const fetchTemplates = async () => {
  if (props.parentModel !== 'Group') return;
  metaDataTemplates.value = await fetchMetaData('Group', props.parentModelId);
};
if (!props.isTemplate) {
  fetchTemplates();
}
const { group, fetch: fetchGroup } = inject(groupResourceKey, {
  group: null,
  fetch: () => {},
}) as GroupResourceComposable;

const { metaDataAccesses, fetchMetaDataAccesses, addOrUpdateMetaDataAccess, removeMetaDataAccess } = inject(
  metaDataAccessesKey,
  {
    metaDataAccesses: () => [],
    fetchMetaDataAccesses: () => {},
    addOrUpdateMetaDataAccess: () => {},
    removeMetaDataAccess: () => {},
  }
) as MetaDataAccessComposable;

if (props.isTemplate) {
  fetchGroup();
  fetchMetaDataAccesses();
}
const allShareToAbleItems = computed(() => {
  return [
    hasAccessToFeatureInTesting()
      ? {
          id: null,
          name: 'All Event Members',
        }
      : null,
  ]
    .concat(
      group.value
        ? getKey(group.value, 'children', []).map((c) => {
            return {
              id: c.id,
              name: c.name,
            };
          })
        : []
    )
    .filter((i) => i !== null);
});

const toggleViewAccessForGroup = async (child) => {
  if (props.model !== 'Group') return;
  if (!props.isTemplate) return;

  if (!props.initMetaData?.id) {
    if (selectedGroupIds.value.has(child.id)) {
      selectedGroupIds.value.delete(child.id);
    } else {
      selectedGroupIds.value.set(child.id, false);
    }
    return;
  }

  const index = metaDataAccesses.value.findIndex(
    (i: MetaDataAccessResource) =>
      i.meta_data_id === props.initMetaData?.id && i.shared_to_group_id === child.id && i.meta_data_field_id === null
  );
  if (index === -1) {
    const { data } = await axios.post(`/api/meta-data-accesses/`, {
      model_type: 'App\\Group',
      model_id: props.modelId,
      meta_data_id: props.initMetaData.id,
      shared_to_group_id: child.id,
    });
    addOrUpdateMetaDataAccess(data);
    useToast().info('Added');
  } else {
    await axios.delete(`/api/meta-data-accesses/${metaDataAccesses.value[index].id}`);
    removeMetaDataAccess(metaDataAccesses.value[index].id);
    useToast().info('Removed');
  }
};

const toggleEditAccessForGroup = async (child) => {
  if (props.model !== 'Group') return;
  if (!props.isTemplate) return;

  if (!props.initMetaData?.id) {
    selectedGroupIds.value.set(
      child.id,
      selectedGroupIds.value.has(child.id) ? !selectedGroupIds.value.get(child.id) : true
    );
    return;
  }

  const index = metaDataAccesses.value.findIndex(
    (i: MetaDataAccessResource) =>
      i.meta_data_id === props.initMetaData?.id && i.shared_to_group_id === child.id && i.meta_data_field_id === null
  );
  if (index > -1) {
    const item = { ...metaDataAccesses.value[index] };
    item.editable = !item.editable;
    await axios.patch(`/api/meta-data-accesses/${item.id}`, {
      editable: item.editable,
    });
    addOrUpdateMetaDataAccess(item);
    useToast().info('Updated ');
  } else {
    const { data } = await axios.post(`/api/meta-data-accesses/`, {
      model_type: 'App\\Group',
      model_id: props.modelId,
      meta_data_id: props.initMetaData.id,
      shared_to_group_id: child.id,
      editable: true,
    });
    addOrUpdateMetaDataAccess(data);
    useToast().info('Added');
  }
};

const getCountOfParentUse = (parent: MetaDataResource) =>
  props.metaDataParentIds.filter((id) => id === parent.id).length;

const goToTemplate = async () => {
  if (props.parentModel !== 'Group' || !props.parentModelId) return;
  const group = await getGroupById(props.parentModelId);
  if (props.initMetaData && props.initMetaData.parent_id) {
    openRoute(
      getRoute('groups.administrator', group.slug) +
        '?open=' +
        formatQueryString('MetaData', props.initMetaData.parent_id) +
        '#meta-data'
    );
  } else {
    openRoute(getRoute('groups.administrator', group.slug) + '#meta-data');
  }
};
</script>

<template>
  <CrudModal
    :title="
      isTemplate || initMetaData?.id
        ? (initMetaData?.id > 0 ? 'Update ' : 'Create new') + ' Meta Data'
        : 'Import Meta Data'
    "
    small
    :update="initMetaData?.id > 0"
    :create-button-text="!isTemplate ? 'Import ' + selectedTemplateIds.size + ' Templates' : null"
    :disabled="isTemplate ? !canSave : selectedTemplateIds.size === 0 && !initMetaData?.id"
    @closed="$emit('closed')"
    @create="create"
    @update="update"
    @delete="destroy">
    <div
      v-if="isTemplate || initMetaData?.id"
      class="form-layout">
      <TextInput
        v-model="newMetaData.title"
        label="Title"
        :can-edit="isTemplate"
        placeholder="Title of Meta Data"
        @keydown.enter="initMetaData?.id > 0 ? update() : create()" />

      <VSelect
        v-if="!isRecurring && !isTemplate"
        v-model="newMetaData.show_time_id"
        :disabled="showTimes.length === 0"
        nullable-display-text="No Show"
        nullable
        :options="
          showTimes.map((s, index) => {
            return { name: s.title ? s.title : 'Show ' + (index + 1), id: s.id };
          })
        "
        label="Show" />

      <div
        v-if="isTemplate && allShareToAbleItems.length > 0"
        class="mt-edge">
        <h3
          class="pointer"
          title="These group will then see, and if write-access is granted, edit fields in your meta data.">
          <i class="fa fa-cog fa-regular fa-fw" />
          Share With Others
        </h3>
        <VTable
          edge-to-edge
          row-size="small"
          rounded-pill-rows>
          <template #head>
            <VTableRow head>
              <VTableCell>Who</VTableCell>
              <VTableCell style="width: 40px">View</VTableCell>
              <VTableCell style="width: 40px">Edit</VTableCell>
            </VTableRow>
          </template>
          <VTableRow
            v-for="item in allShareToAbleItems"
            :key="item.id">
            <VTableCell
              :main-cell="
                initMetaData?.id
                  ? metaDataVisible(metaDataAccesses, initMetaData?.id, item.id)
                  : selectedGroupIds.has(item.id)
              ">
              {{ item.name }}
            </VTableCell>
            <VTableCell>
              <template v-if="initMetaData?.id">
                <SettingCheck
                  :model-value="metaDataVisible(metaDataAccesses, initMetaData?.id, item.id)"
                  @click="toggleViewAccessForGroup(item)" />
              </template>
              <template v-else>
                <SettingCheck
                  :model-value="selectedGroupIds.has(item.id)"
                  @click="toggleViewAccessForGroup(item)" />
              </template>
            </VTableCell>
            <VTableCell main-cell>
              <template v-if="initMetaData?.id">
                <SettingCheck
                  :model-value="metaDataEditable(metaDataAccesses, initMetaData?.id, item.id)"
                  @click="toggleEditAccessForGroup(item)" />
              </template>
              <template v-else>
                <SettingCheck
                  :model-value="selectedGroupIds.get(item.id)"
                  @click="toggleEditAccessForGroup(item)" />
              </template>
            </VTableCell>
          </VTableRow>
        </VTable>
      </div>
    </div>

    <div
      v-if="!isTemplate && !initMetaData?.id"
      class="-mx-edge mb-edge overflow-auto"
      style="max-height: 30vh; overflow: auto">
      <VTable
        v-if="metaDataTemplates.length"
        row-size="small"
        sticky-header
        :edge-to-edge="true"
        :bordered-table="true">
        <template #head>
          <VTableRow head>
            <VTableCell> Select templates to import</VTableCell>
            <VTableCell style="width: 50px"></VTableCell>
            <VTableCell style="width: 50px"></VTableCell>
          </VTableRow>
        </template>
        <VTableRow
          v-for="parent in metaDataTemplates"
          :key="parent.id"
          clickable
          main-row
          @click="toggleMetaData(parent.id)">
          <VTableCell>
            <div class="grid grid-cols-[30px_auto] gap-edge-1/4">
              <CheckBox
                :model-value="selectedTemplateIds.has(parent.id)"
                size="sm" />
              <div class="font-headers">{{ parent.title }}</div>
            </div>
          </VTableCell>
          <VTableCell @click.stop>
            <DisplayModal
              :title="parent.title"
              without-button-text
              @selected="toggleMetaData(parent.id)">
              <MetaData
                :key="parent.id + '_display'"
                :meta-data="parent"
                :is-template="false"
                :can-edit-content="false"
                :model="model"
                :model-id="modelId"
                :show-times="[]"
                :documents="[]"
                :can-edit-form="false"
                is-display />
            </DisplayModal>
          </VTableCell>
          <VTableCell :title="'Already added ' + getCountOfParentUse(parent) + ' times.'">
            <div>#{{ getCountOfParentUse(parent) }}</div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </div>
    <template
      v-if="initMetaData?.id !== null && !isTemplate"
      #area-in-footer-for-buttons>
      <div>
        <VButton
          :title="'Edit Template' + (initMetaData === null ? 's' : '')"
          :emphasized="true"
          size="lg"
          icon="fa-cog"
          @click="goToTemplate()" />
      </div>
    </template>
  </CrudModal>
</template>
