<script lang="ts" setup>
import VTableRow from '@/components/Tables/VTableRow.vue';
import { DocumentFolderResource } from '@/types/document';
import { ref } from 'vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  documentFolder: DocumentFolderResource;
  includedIds: Set<number>;
  parentIncluded: boolean;
  number: number;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'folderName', args: { id: number; name: string }): void;
  (e: 'removeFolder', args: DocumentFolderResource): void;
  (e: 'addFolder', args: DocumentFolderResource): void;
}>();

const isOpen = ref(false);

const emitFolderName = (folder: DocumentFolderResource) => {
  emit('folderName', {
    id: folder.id,
    name: folder.name,
  });
};

const emitRemoveFolder = (folder: DocumentFolderResource) => {
  emit('removeFolder', folder);
};

const emitAddFolder = (folder: DocumentFolderResource) => {
  emit('addFolder', folder);
};

emitFolderName(props.documentFolder);

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <VTableRow v-bind="$attrs">
    <VTableCell
      main-cell
      @click="isOpen = !isOpen">
      <div
        class="pull-left"
        :style="'text-indent: ' + number + 'px;'">
        <i
          v-if="isOpen || documentFolder.children.length === 0"
          class="fa fa-folder-open fa-regular mr-edge-1/2" />
        <i
          v-else
          class="fa fa-folder fa-regular mr-edge-1/2" />
        {{ documentFolder.name }}
      </div>
    </VTableCell>
    <VTableCell main-cell>
      <div class="flex justify-end">
        <VButton
          v-if="includedIds.has(documentFolder.id)"
          title="Remove"
          icon="fa-trash"
          size="sm"
          type="warning"
          :stop-click="true"
          @click="emitRemoveFolder(documentFolder)" />
        <VButton
          v-else-if="parentIncluded"
          disabled
          class="float-right"
          size="sm"
          title="Remove"
          icon="fa-trash"
          disabled-tool-tip-text="Parent folder already included." />
        <VButton
          v-else
          size="sm"
          type="primary"
          class="float-right"
          :tool-tip-text="'Add ' + documentFolder.name + ' to all existing and future events of this type.'"
          title="Add"
          icon="fa-plus"
          :stop-click="true"
          @click="emitAddFolder(documentFolder)" />
      </div>
    </VTableCell>
  </VTableRow>
  <template
    v-for="docFolder in isOpen ? documentFolder.children : []"
    :key="docFolder.id">
    <EventTypeFolderSectionFolder
      v-show="isOpen"
      :number="number + 10"
      :included-ids="includedIds"
      :parent-included="includedIds.has(documentFolder.id) || parentIncluded"
      :document-folder="docFolder"
      @remove-folder="emitRemoveFolder"
      @add-folder="emitAddFolder"
      @folder-name="emitFolderName" />
  </template>
</template>
